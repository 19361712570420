import NotificationClass from '../service/notification'
import CommonClass from '../service/common'
import InviteService from '../service/inviteService'
import axios from "axios";

let channelMixin = {
  methods: {
    offlineUserFcmSend: function (channelId,message){
      let _this = this
      let users = this.$store.getters.getChannelUsers
      let onlineUsers = this.$store.getters.getOnlineUsers
      onlineUsers = JSON.parse(JSON.stringify(onlineUsers))
      let onlineUserArray = []
      for (const [key, value] of Object.entries(onlineUsers)){
        onlineUserArray.push(value)
      }
      let offlineUsers = users.filter(user => !onlineUserArray.includes(user.email))
      offlineUsers.forEach(sendUser =>{
        let topic = channelId+sendUser.email
        topic = topic.replace(/@.*/g,"")
        this.sendFcmMsgByTopic(topic,_this.$store.getters.getCurrentUser.name,message)
      })

    },
    sendFcmMsgByTopic: function (topic,sender,content){
      axios.post('/api/fcm/topic/msg',
        {
          topic: topic,
          sender: sender,
          content: content
        }).then(res=>{
        console.log(res)
        }
      )
    },
    _makeChannelFunction: function (channel) {
      if (channel !== undefined && channel.id !== undefined) {
        let _this = this
        let _url = "/sub/chat/room/"
        let result = null

        if (channel.send === undefined) {
          channel.send = function (message) {
            _this.send(_url + this.id, message)
          }
        }
        if (channel.access === undefined) {
          channel.access = function () {
            _this.post('/api/channel/update/lastaccessdate', {
              currentChannelId: this.id,
              userEmail: _this.currentUser.email
            })
          }
        }
        if (channel.subscribe === undefined) {
          channel.subscribe = function () {
            result = _this.subscribe(_url + this.id, _this.channelSubscribeCallBack, {id: this.id})
            // 웹 뷰에서 토픽 구독 설정하는 로직
            // 모바일 브라우저에서 접근 시 에러 메시지 출력 됨
            // add 20210830
            // android 구독 처리하는 로직 현재는 채널 id+이메일 id로 토픽 구독하지만
            // 추후 pk를 생성하여하는것이 좋아 보임
            if(navigator.userAgent.indexOf("Android") > -1){
                let currentUserEmail=_this.$store.getters.getCurrentUser.email
                try{
                  let topic = this.id+currentUserEmail
                  topic = topic.replace(/@.*/g,"")
                  window.Android.subscribeTopicChannel(topic)
                }catch (e){

                }

            }
          }
        }
        if (channel.unsubscribe === undefined) {
          channel.unsubscribe = function () {
            _this.unsubscribe(this.id)
          }
        }
      }
      return channel
    },
    channelSubscribeCallBack(e) {
      let data = JSON.parse(e.body)
      let _this = this
      if (data.message === undefined) {
        // 210127 초대 거절시 에러
        // NotificationClass.sendNotification(this.$store.state.isfocus, data)
        if (data.channel_id == this.$store.state.currentChannel.id && this.enableComponent) {
          this.$store.commit('pushMsg', data)
          // offline user 에게만 send 하는 로직 추가 20210830
          if(data.sender == this.$store.getters.getCurrentUser.email){
            this.offlineUserFcmSend(data.channel_id,data.content)
          }
          if(data.message_type=='file'){
            data.files.forEach(function(file){
              _this.$store.commit('addChannelFile',file)
            })
          }
          if (!this.$store.state.isfocus && data.message_type!='date') {

            this.msgCountUpdate(data.channel_id, true)

          } else {
            this.currentChannel.access()
          }
        } else {
          if(data.message_type!='date'){
            // add 20210901
            // 현재 앱에 online user 이지만 해당 채널에 없는 유저에서 notify하는 로직
            try{
              if(navigator.userAgent.indexOf("Android") > -1){
              window.Android.showNotification(data.user.name,data.content)
              }
            }catch (e){

            }
            this.msgCountUpdate(data.channel_id, true)
          }
        }
      } else {
        //메시지가 함수명일때 함수를 call하는 구문
        try {
          let msg = data.message
          if (msg.includes('|')) {
            let splitArr = msg.split('|')
            this[splitArr[0]](splitArr[1]);

          // 210408 신규 함수
          } else if(msg.includes('[F]')) {
            msg = msg.replace('[F]', '')
            let exec = 'this.' + msg
            eval(exec)
          } else {
            this[msg]();
          }
        } catch (e) {
          console.error(e);
        }
      }
      if (e.headers.noticeMsg != null) {
        this.noticeMsg = res.headers.noticeMsg
        this.noticeMsgToggle = true
      }
    },
    enableComponent: function () {
      // sokect 통신을 위한 컴포넌트 체크
      switch (this.$store.state.selectComponent) {
        case "main":
          // case "videoChat":
          return true
        default:
          return false
      }
    },
    //채널 공통 확인
    confirmChannel: function (event, mode, channel) {
      event.stopPropagation()
      this.$store.commit('setModalTitle', this.getChannelModeKorStr(mode))
      this.$store.commit('setChannelMode',mode)
      try {
        this.$store.commit('setChannelTitle',(channel === undefined) ? this.currentChannel.name : channel.name)
      } catch (e) {
        this.$store.commit('setChannelTitle','')
      }
      if (mode === "create" || mode === "update") {
        if (mode === "create") this.$store.commit('setChannelTitle','')
        this.$store.commit('setModalTrigger',true)
      } else if (mode === "delete") {
        this.$_confirm("<code>[" + channel.name + "]</code>채널을 삭제하시겠습니까?", this.deleteChannel, channel);
      }
    },
    //채널 생성
    createChannel: function (channelTitle, email) {
      let _this = this
      this.post('/api/channel/create', {
        name: channelTitle,
        member_email: email
      }, function (res) {
        let channel = _this.getChannel(res.data)
        channel.subscribe()
        _this.selectChannelList(channel)
      })
    },
    //채널 수정
    updateChannel: function (channel) {
      if(channel) {
        let newChannel = Object.assign({}, channel)
        delete newChannel.channelFiles
        delete newChannel.channelUsers
        this.post('/api/channel/update', newChannel, function () {
          // 210408 채널명 변경 시 해당 채널로 강제로 입장되는 문제 해결
          // channel.send("selectChannelList|" + newChannel.id)
          channel.send("selectChannelList")
          // channel.send("[F]$store.commit('setCurrentChannel'," + JSON.stringify(newChannel) + ")")
        })
      }
    },
    //채널 삭제
    deleteChannel: function (channel) {
      let _this = this;
      let newChannel = channel
      delete newChannel.channelFiles
      delete newChannel.channelUsers
      this.post('/api/channel/delete', newChannel, function () {
        // 210405 채널 삭제 시 대시보드로 이동
        // channel.send("selectChannelList|" + _this.currentChannel.id)
        channel.send("selectChannelList|dash")
      })
    },
    //채널 삭제 아이콘 표시
    visibilityChannelDelete: function (channelId) {
      this.hiddenChannelDelete()
      $("#channelDel" + channelId).css("visibility", "visible")
    },
    //채널 삭제 아이콘 미표시
    hiddenChannelDelete: function () {
      $(".channelDel").css("visibility", "hidden")
    },

    //채널 목록 조회
    selectChannelList: function (channel, isJoin = true) {
      this.$http.get('/api/channel/list')
        .then(res => {
          let channelList = res.data
          this.commit('setChannelList', channelList)
          channelList.forEach(thisChannel => {
            this.getChannel(thisChannel)
          })
          if(!channel) {
            isJoin = false
          } else if(channel == 'dash') {
            this.commit('getSelectComponent', 'dashboard')
          }
          if (isJoin) {
            channel = this.getChannel(channel)
            if (channelList.length === 0) channel = null
            // 210405 채널 삭제 시 대시보드로 이동
            // else if (channel === undefined) channel = channelList[0]
            if (this.currentChannel == null) this.commit('setCurrentChannel', {id: -1})//채널 진입
            if(channel) this.joinChannel(channel)
            else {
              this.commit('getSelectComponent', 'dashboard')
              this.selectChannelList(null, false)
              this.initChannelUserList()
            }
          }
        }).catch(error => {
        console.error(error)
      })
    },
    //채널 진입
    joinChannel: function (channel) {
      // 210123 채널 변경시 화상 회의 종료(Main.vue 문제로 인한 추가)
      this.$store.commit('setIsVideoMode', false)
      let chYn = this.$store.getters.getChannelList.find(ch => ch.id == channel.id)
      if (chYn) {
        this.commit('getSelectComponent', 'main')
        if (channel.id != this.currentChannel.id) {
          this.$store.commit('setChannelFiles',[])
          this.$store.commit('setChannelLinks',[])
          this.$store.commit('initFileCursorPoint')
          this.commit('setCurrentChannel', channel)//채널 진입
          this.initChannelUserList()
          this.selectChannelUserList(channel.id)//채널 사용자 조회
          this.selectMessageList(channel, true)//채널 메시지 조회
          this.hiddenChannelDelete()
          if (window.innerWidth < 600) $(".app-sidebar").addClass("hide-sidebar")
          this.currentChannel.count = 0
          this.$store.state.isSearchMode = false
          if (channel != null) {
            channel.access()
          }
          this.getRSidebarPreviewFiles()
        } else {
          this.selectChannelUserList(channel.id)//채널 사용자 조회
        }
      } else {
        this.commit('setCurrentChannel', '')
        this.initChannelUserList()
        location.reload()
      }
    },
    // 오른쪽사이드바 파일미리보기 가져오기
    getRSidebarPreviewFiles: function(){
      this.fileCursorPoint.channel_id = this.currentChannel.id
      this.$store.dispatch('loadChannelFiles', {
        fileCursorPoint:this.fileCursorPoint,
        isFileDrawer:false
      })
      this.$store.dispatch('loadChannelLinks', {
        fileCursorPoint:this.fileCursorPoint
      })
    },
    //채널 사용자 조회
    selectChannelUserList: function (channel = this.$store.state.currentChannel.id) {
      let list = []
      let _this = this
      if (channel != null && channel != '') {
        this.$http.get('/api/user/channel/' + channel, {
          currentChannelId: channel,
          userEmail: this.currentUser.email
        }).then(res => {
            list = res.data
            this.setChannelUserList(res.data).then(() => {
              if(!this.$store.getters.getChannelUsers.find(user => {
                return user.email == this.$store.getters.getCurrentUser.email
              })) {
                  this.commit('getSelectComponent', 'dashboard')
                  this.selectChannelList(null, false)
                  this.initChannelUserList()
              }
            })
          })
      } else {
        this.initChannelUserList()
      }
      return list
    },
    //채널 사용자 초기화
    initChannelUserList: function () {
      this.setChannelUserList([])
    },
    //채널 사용자 적용
    setChannelUserList: function (channelUserList) {
      return this.$store.dispatch('setChannelUsers', channelUserList)
    },
    //채널 사용자 삭제 아이콘 표시
    visibilityChannelUserDelete: function (index) {
      this.hiddenChannelUserDelete()
      $("#channelUserDel" + index).css("visibility", "visible")
    },
    //채널 사용자 삭제 아이콘 미표시
    hiddenChannelUserDelete: function () {
      $(".channelUserDel").css("visibility", "hidden")
    },
    //채널 강퇴 및 나가기 Confirm
    confirmChannelForceLeave: function (user) {
      var content = this.isMine(user) ? "<code>[" + this.currentChannel.name + "]</code> 채널을 나가시겠습니까?" : "<code>[" + user.name + "]</code>님을 추방하시겠습니까?"
      this.$_confirm(content, this.leaveChannel, user);
    },
    //채널 초대
    inviteChannel: async function (event) {
      let el = document.querySelector(".menuable__content__active.inviteClass")
      if (el == null) {
        if (this.friends.length != 0) {
          await InviteService.invite(this.currentUser.email, this.currentChannel.id, this.friends, this.currentChannel.name)
            .then(res => {
              for (let i = 0; i < this.friends.length; i++) {
                const user = this.inviteUserList.find(el => el.email == this.friends[i])

                if (user != null) {
                  this.message.content += user.name + '님'
                }
                if(this.friends.length > (i + 1)) {
                  this.message.content += ', '
                }
              }
              // 임시 주석처리
              // this.$http.post('/api/invite/mail', {
              //   channel_id: this.$store.state.currentChannel.id,
              //   sender: this.$store.state.currentUser.email,
              //   recipients: this.friends
              // })
              //   .then(res => {
              //     console.warn(res.data)
              //   })
              this.message.content += '을 초대했습니다.'
              this.$emit('sendMessage', null, true)
              this.friends = []
              this.message.content = ''
              this.$store.state.isInviteMode = !this.$store.state.isInviteMode
            }).catch(error => {
              let alertmsg = ''
              if (error.response.data.list != null) {
                const alertList = error.response.data.list
                for (let i = 0; i < alertList.length; i++) {
                  const user = this.userList.find(el => el.email == alertList[i])
                  alertmsg += user.name + '님'
                }
                alertmsg += '은 이미 이 채널에 초대 받았습니다. 확인해주세요.'
                this.$_error(alertmsg)
              } else {
                this.$_error(error.response.data.message)
              }
              console.error(error.response)
              this.message.content = ''
            })
        } else {
          this.$_alert('초대할 사용자를 선택해주세요')
        }
      }
    },
    inviteAccept: function (alarm, index) {
      const message = {
        channel_id: alarm.channel_id,
        sender: null,
        content: this.$store.state.currentUser.name + '님이 채널에 초대되었습니다.',
        message_type: 'action'
        // user: this.$store.state.currentUser
      }
      this.$http.post('/api/invite/accept', alarm)
        .then(async (res) => {
          //현재 채널을 변경하는 로직을 구현해야할듯
          this.$store.state.stompClient.send('/pub/chat/message', JSON.stringify(message))
          this.alarmList.splice(index, 1);
          this.$store.state.stompClient.send('/pub/chat/room/' + alarm.channel_id, JSON.stringify({
            "message": "updateChannel",
            "error": "null"
          }))
          await this.selectChannelList(alarm.channel_id) // 채널 id 값이 아니라 channel 객체를 줘야함
          await this.subscribe("/sub/chat/room/" + alarm.channel_id, this.channelSubscribeCallBack)
          this.send("/sub/chat/room/" + alarm.channel_id, 'selectChannelUserList')
        })
        .catch(error => {
          console.error(error)
        })
    },
    inviteRefuse: function (alarm, index) {
      // 초대가 거절됐다는 메시지를 채널에 보내는 로직을 구현해야함
      this.$http.post('/api/invite/refuse', alarm)
        .then(res => {
          const message = {
            channel_id: alarm.channel_id,
            sender: null,
            content: this.$store.state.currentUser.name + '님이 채널 초대를 거부하셨습니다.',
            message_type: 'action'
          }
          this.alarmList.splice(index, 1);
          this.$store.state.stompClient.send('/pub/chat/message', JSON.stringify(message))
        })
        .catch(error => {
          console.error(error)
        })
    },
    //채널 강퇴 및 나가기
    leaveChannel: function (user) {
      this.$http.post('/api/channel/leave', {
        email: user.email,
        channel_id: this.currentChannel.id
      }).then(res => {
        this.$eventBus.$emit('leaveChannelMsg', user)
        // 나가기 누를 경우
        if(this.isMine(user)) {
          this.commit('getSelectComponent', 'dashboard')
          this.selectChannelList(null, false)
        // 추방했을 경우(추방자 리스트에서 제거)
        } else {
          this.selectChannelList(this.currentChannel)

          // 210406 추방 메시지 전송
          this.$store.getters.getStompClient.send("/sub/alarm/" + user.email, JSON.stringify({
            channel_id: this.currentChannel.id,
            sender: this.$store.getters.getCurrentUser.name,
            recipients: user,
            channel_name: this.currentChannel.name,
            type: 'D'
          }))
        }
        this.send("/sub/chat/room/" + this.currentChannel.id, 'selectChannelUserList')
        this.send("/sub/chat/room/" + this.currentChannel.id, 'selectChannelList')
        this.$_alert("<code>[" + this.currentChannel.name + ']</code> 채널에서 ' + (this.isMine(user) ? "나갔습니다." : user.name + "를 추방했습니다."))
      }).catch(error => {
        this.$_error((this.isMine(user) ? "나가기" : "추방") + '에 실패했습니다.')
      })
    },
    //채널 조회
    getChannel: function (paramChannel) {
      let thisChannel
      if (paramChannel === undefined) {
        thisChannel = paramChannel
      } else {
        let _this = this
        if (typeof paramChannel == 'string' || typeof paramChannel == 'number') {
          this.channelList.forEach(channel => {
            if ((paramChannel * 1) === channel.id) {
              thisChannel = _this._makeChannelFunction(channel)
              return false
            }
          })
        } else {
          thisChannel = this._makeChannelFunction(paramChannel)
        }
      }
      return thisChannel
    },
    //채널 모드 조회
    getChannelModeKorStr: function (mode) {
      if (mode == "create") return this.currLng.channel.txt.createTitle
      if (mode == "update") return this.currLng.channel.txt.updateTitle
      if (mode == "delete") return this.currLng.channel.txt.deleteTitle
    },
    isMine: function (user) {
      var loginUserEmail = this.currentUser.email
      var clicktUserEmail = user.email
      return loginUserEmail == clicktUserEmail
    },
    isActiveForceLeave: function (user) {
      return this.isAdmin() || this.isMine(user)
    },
    loadChannelFiles: function (channel_id) {
      // ?
      this.$store.dispatch('loadChannelFiles', channel_id)
    },
    loadChannelLinks: function (channel_id) {
      // ?
      this.$store.dispatch('loadChannelLinks', channel_id)
    },
    onlineUsers: function() {
      this.$store.state.stompClient.subscribe('/sub/onlineUsers', (e) => {
        this.$store.commit('setOnlineUsers', JSON.parse(e.body))
      })
      this.$store.state.stompClient.send('/pub/api/dashboard/initOnlineUsers')
    },
  }
};
export default channelMixin;
