export default {
  channelUsers: [],
  taskBoard: [],
  syncSignal: {
    syncChannel: false,
    syncChannelUser: false,
  },
  currentChannel: {},
  channelList: [],
  subscribeList: [],
  stompClient: null,
  selectComponent: 'dashboard',
  oldComponent: 'dashboard',
  currentUser: {},
  userList: [],
  currentChannelUser: [],
  isLActive: false,
  isRActive: false,
  isfocus: true,
  isLogout: false,
  isSearchMode: false,
  isInviteMode: false,
  searchText: '',
  isSmallWidth:false,
  isCreateListActive : false,
  msgArray: [],
  isVideoMode: false,
  channelModal:false,
  inviteUserList:[],
  lang:'ko',
  channelFiles: [],
  channelLinks: [],
  fileCursorPoint:{
    channel_id: 0,
    first: true,
    cursorId: 0,
    empty: false
  },
  linkCursorPoint:{
    channel_id: 0,
    first: true,
    cursorId: 0,
    empty: false
  },
  permission: {
    canaudio: false,
    canvideo: false
  },
  showBool:true,
  changeCss:true,

  alarmList: [],

  isFileUpload: false,
  progressValue: 0,
  sendMail: false,
  tempImg: '',
  show:true,
  stringByteLength: 0,
  previewObj: {
    content: '',
    username: ''
  },
  channelArr: [],
  message: {
    channel_id: 0,
    content: '',
    sender: '',
    user: {}
  },
  cursorPoint: {
    channel_id: 0,
    first: true,
    cursorId: 0,
    empty: false
  },
  oldScrollHeight: 0,
  wrapperEl: null,
  isGetMsgForPreview: false,
  isGetMsgForImgLoad: false,
  selectedUserEmail: '',
  firstLoad:true,
  scrollPosition:0,
  isUpScroll:false,
  onlineUsers: 0,
  alarmCount: 0,
  threadMsg: [],

  ////////bvModal 관련 변수 ////////////////////////////
  modalTitle:'',
  channelTitle: '',
  channelMode: '',
  modalTrigger: false,
}
