<template>
    <div id="dashboard" class="container-fluid" style="width: 100%; flex-direction: row; display: flex; flex-wrap: wrap; cursor: default;">
        <!-- 공지사항 상세보기 -->
        <v-dialog v-model="dialog" width="70vw" height="70vh">
            <v-card>
                <v-card-title class="headline">{{ title }}</v-card-title>
                <v-divider></v-divider>
                <v-card-subtitle style="text-align: right; padding: 0 1vw 1vh 0;">{{ editDate | dateFormat }} {{ editor.name }}</v-card-subtitle>
                <v-card-text style="height:50vh; position: relative; overflow: auto;">
                    <pre v-html="contents"></pre>
                </v-card-text>
                <v-card-subtitle v-show="files.length > 0" style="display: grid; grid-template-columns: 10vw 55vw; padding: 1vh 0px 0px 1vw; min-height: 5vh; max-height: 10vh; overflow: auto;">
                    <div style="display: flex; align-items: center; justify-content: center; grid-row: span 99; background-color: #AB47BC; color: white;">{{ currLng.dashboard.attach }}</div>
                    <div>
                        <v-btn v-for="item in files" :key="item.id" text plain x-small @click="fileDownload(item, true)" style="background: #e0e0e0; margin: 0.25vh 0 0.25vh 0.5vw; border-radius: 8px; font-size: 0.1rem;">{{ item.original_name }}</v-btn>
                    </div>
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn @click="dialog = false" class="ma-1" color="success" plain>{{ currLng.common.btn.close }}</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- 참여중인 채널 정보 -->
        <div class="col-12" style="display: inline-block;">
            <div class="page-header">
                <div class="row align-items-end">
                    <div class="page-header-title col-12">
                        <i class="im im-speech-bubble-comments bg-green"></i>
                        <div class="d-inline">
                            <h5>{{ currLng.dashboard.channelInfo }}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div elevation="2" style="padding: 0.5rem;" :loading="loadingChannel">
                    <v-carousel v-show="dashboardChannelList.length > 0" height="31vh" hide-delimiters show-arrows-on-hover>
                        <v-carousel-item v-for="idx in Math.ceil(dashboardChannelList.length / numPerSlide)" :key="idx" reverse-transition="fade-transition" transition="fade-transition">
                            <v-card color="white" style="height: 100%; margin-top: 1.5vh">
                                <v-row style="padding: 0 0.5rem;">
                                    <v-col :cols="12 / numPerSlide" v-for="item in dashboardChannelList.slice((idx - 1) * numPerSlide, idx * numPerSlide)" :key="item.id">
                                        <v-card elevation="2" color="white" style="color: black; height: 27vh; overflow: hidden auto;">
                                            <v-row dense>
                                                <v-col cols="12">
                                                    <v-card-title style="padding: 0.5rem !important;">
                                                        <span @click="joinChannel(item)" style="cursor: pointer; font: 900 1rem bolder">{{ item.name }}</span>
                                                        <i class="im im-radio-button-circle-o blink" style="color: red; margin-left: 0.5rem; cursor: pointer;" v-show="checkRtcList(item)" @click="goVideoChat(item)"></i>
                                                    </v-card-title>
                                                </v-col>
                                                <v-col :cols="!!item.channelFiles && item.channelFiles.length > 0 ? 6 : 12">
                                                    <v-card-title style="font-size: 0.8rem; padding: 0 0.5rem !important;">{{ currLng.dashboard.user }}</v-card-title>
                                                    <v-card-text style="padding: 0 0.5rem !important;">
                                                        <div class="my-4 subtitle-1" v-for="user in item.channelUsers" :key="user.id" style="margin: 0 !important;font-size: 0.9rem !important;">
                                                            <v-badge :value="checkOnline(user)" bottom color="cyan lighten-1" dot offset-x="10" offset-y="10"><img onError="this.src='/img/default-user-picture.png'" class="avatar" style="width: 1.5rem; height: auto;" :src="user.picture"></v-badge><span style="max-width: calc(99% - 24px - 15px); display: inline-block; margin-left:15px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; vertical-align: sub; cursor: default;" :title="user.name">{{ user.name }}</span>
                                                        </div>
                                                    </v-card-text>
                                                </v-col>
                                                <v-col v-show="!!item.channelFiles && item.channelFiles.length > 0" cols="6">
                                                    <v-card-title style="font-size: 0.8rem; padding: 0 0.5rem !important;">{{ currLng.dashboard.attach }}</v-card-title>
                                                    <v-card-text style="padding: 0 0.5rem !important;">
                                                        <div class="my-4 subtitle-1" v-for="file in item.channelFiles" :key="file.id" @click="fileDownload(file)" style="margin: 0 !important; cursor: pointer; overflow: hidden; text-overflow: ellipsis; font-size: 0.5rem !important; white-space: nowrap;">
                                                            <img :src="checkFileType(file)" style="width: 0.7rem; margin-right: 0.3rem;" />{{ file.original_name }}
                                                        </div>
                                                    </v-card-text>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-carousel-item>
                    </v-carousel>
                    <div v-show="dashboardChannelList.length <= 0" style="text-align: center; padding: 3vh 0; font-size: 1rem;">
                        <i aria-hidden="true" class="v-icon notranslate material-icons theme--light dash-icon">{{ waitChannel }}</i>
                    </div>
            </div>
        </div>

        <!-- 공지사항 -->
        <div class="col-lg-3 col-sm-12" style="display: inline-block; float: left;">
            <div class="page-header">
                <div class="row align-items-end">
                    <div class="page-header-title col-12">
                        <i class="im im-paperplane bg-orange"></i>
                        <div class="d-inline">
                            <h5>{{ currLng.dashboard.notice }}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <v-card elevation="2" style="padding: 0.8em;" :loading="loadingNotice">
                <div class="dash-default-bottom">
                    <v-simple-table :[screenHeightCheck]="true">
                        <template v-slot:default>
                            <tbody>
                                <tr v-for="(item, index) in noticeList" :key="item.id" @click="detail(item.id, index)" style="cursor: pointer;">
                                    <td><i class="im im-megaphone" style="font-size: 1em; color: red; margin-right: 0.5vw;" v-show="item.notice_type == 'E'"></i><span v-html="item.title"></span> <i class="im im-lock" style="font-size: 1em;" v-show="item.notice_flag == 'M'"></i> <span :inner-html.prop="item.edit_date | newMark"></span></td>
                                </tr>
                                <tr v-show="noticeList.length <= 0">
                                    <td style="text-align: center; padding: 6vh 0; font-size: 1rem;">
                                        <i aria-hidden="true" class="v-icon notranslate material-icons theme--light dash-icon">{{ waitNotice }}</i>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <div style="position: absolute; bottom: 0.5rem; right: 0.5rem;">
                        <v-btn text plain x-small @click="callComponent('notice')">{{ currLng.dashboard.more }}</v-btn>
                    </div>
                </div>
            </v-card>
        </div>

        <!-- 알림 -->
        <div class="col-lg-3 col-sm-12" style="display: inline-block; float: right;">
            <div class="page-header">
                <div class="row align-items-end">
                    <div class="page-header-title col-12">
                        <i class="im im-bell bg-yellow"></i>
                        <div class="d-inline">
                            <h5>{{ currLng.dashboard.notifications }}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <v-card elevation="2" style="padding: 0.8em;" :loading="loadingNotification">
                <div class="dash-default-bottom">
                    <v-simple-table :[screenHeightCheck]="true">
                        <template v-slot:default>
                            <tbody>
                                <tr v-for="alarm in subAlarmList" :key="alarm.id"> <!--  :style="{'backgroundColor': task.color}" -->
                                    <td style="font-size: 0.7rem;">
                                        {{ getNameByEmail(alarm.sender) + '님이 ' + alarm.channel_name + ' 채널로 초대했습니다.' }}
                                        <b v-show="alarm.send_date ? (alarm.invite_state != 'STAND_BY' ? false : true) : true" style="padding:0 0.3vw 0.2vh; background-color: orange; border-radius: 10px; color: white; font-size: 0.5rem;">{{ alarm.send_date ? (alarm.invite_state != 'STAND_BY' ? '' : 'New') : 'New' }}</b>
                                    </td>
                                </tr>
                                <tr v-show="subAlarmList.length <= 0">
                                    <td style="text-align: center; padding: 3vh 0; font-size: 1rem;">
                                        <i aria-hidden="true" class="v-icon notranslate material-icons theme--light dash-icon">{{ waitNotifications }}</i>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </div>
            </v-card>
        </div>

        <!-- 캘린더 -->
        <div class="col-lg-3 col-sm-12" style="display: inline-block; float: right;">
            <div class="page-header">
                <div class="row align-items-end">
                    <div class="page-header-title col-12">
                        <i class="im im-calendar bg-purple"></i>
                        <div class="d-inline">
                            <h5>{{ currLng.dashboard.calendar }}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <v-card elevation="2" style="padding: 0.8em;" :loading="loadingCalendar">
                 <div class="dash-default-bottom">
                    <full-calendar ref="calendar" :config="config" :events="events" @event-selected="selectEvent"></full-calendar>
                 </div>
            </v-card>
        </div>

        <!-- 태스크 -->
        <div class="col-lg-3 col-sm-12" style="display: inline-block; float: right;">
            <div class="page-header">
                <div class="row align-items-end">
                    <div class="page-header-title col-12">
                        <i class="im im-task-o bg-pink"></i>
                        <div class="d-inline">
                            <h5>{{ currLng.dashboard.todolist }}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <v-card elevation="2" style="padding: 0.8em;" :loading="loadingTodo">
                <div class="dash-default-bottom">
                    <div style="height: 18vh;">
                        <v-banner elevation="1" single-line>{{ currLng.dashboard.todolistRecent }}</v-banner>
                        <div style="overflow: auto; height: calc(100% - 5vh);">
                            <v-simple-table :[screenHeightCheck]="true">
                                <template v-slot:default>
                                    <tbody>
                                        <tr v-for="task in sttTask" :key="task.id" @click="selectEvent(task)" style="cursor: pointer;"> <!--  :style="{'backgroundColor': task.color}" -->
                                            <td style="width: 60%"><v-badge :color="task.color" dot left inline>{{ task.title || task.content }}</v-badge></td>
                                            <td class="text-center" style="width: 40%" v-if="!task.start_date">-</td>
                                            <td class="text-center" style="width: 40%" v-else>{{ task.start_date | dateFormat }} ~ {{ task.end_date | dateFormat }}</td>
                                        </tr>
                                        <tr v-show="sttTask.length <= 0">
                                            <td colspan="2" style="text-align: center; padding: 3vh 0; font-size: 1rem;">
                                                <i aria-hidden="true" class="v-icon notranslate material-icons theme--light dash-icon">{{ waitTodo }}</i>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                    </div>
                    <v-divider style="margin: 1vh 0;"></v-divider>
                    <div style="height: 18vh;">
                        <v-banner elevation="1" single-line>{{ currLng.dashboard.todolistExpire }}</v-banner>
                        <div style="overflow: auto; height: calc(100% - 5vh);">
                            <v-simple-table :[screenHeightCheck]="true">
                                <template v-slot:default>
                                    <tbody>
                                        <tr v-for="task in endTask" :key="task.id" @click="selectEvent(task)" style="cursor: pointer;">
                                            <td style="width: 60%"><v-badge :color="task.color" dot left inline>{{ task.title || task.content }}</v-badge></td>
                                            <td class="text-center" style="width: 40%" v-if="!task.start_date">-</td>
                                            <td class="text-center" style="width: 40%" v-else>{{ task.start_date | dateFormat }} ~ <b style="color: red;">{{ task.end_date | dateFormat }}</b></td>
                                        </tr>
                                        <tr v-show="endTask.length <= 0">
                                            <td colspan="2" style="text-align: center; padding: 3vh 0; font-size: 1rem;">
                                                <i aria-hidden="true" class="v-icon notranslate material-icons theme--light dash-icon">{{ waitTodo }}</i>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                    </div>
                    <!-- <v-progress-linear color="deep-purple" rounded height="100" :active="loadingTest" :indeterminate="loadingTest" striped></v-progress-linear> -->
                </div>
            </v-card>
        </div>

    </div>
</template>

<script>
import {FullCalendar} from 'vue-full-calendar'
import VSwatches from 'vue-swatches'
import Common from '../../service/common'

import RTCMultiConnection from 'rtcmulticonnection'
import * as io from 'socket.io-client'

export default {
    name: 'Dashboard',
    components: {
      FullCalendar,
      VSwatches,
    },
    data() {
        return {
            // loading
            loadingNotice: false,
            loadingCalendar: false,
            loadingTodo: false,
            loadingNotification: false,
            loadingChannel: false,

            waitChannel: 'pending', // this.currLng.dashboard.waiting,
            waitNotice: 'pending', // this.currLng.dashboard.waiting,
            waitNotifications: 'pending', // this.currLng.dashboard.waiting,
            waitTodo: 'pending', // this.currLng.dashboard.waiting,

            // channel
            dashboardChannelList: [],
            rtcmList: [],
            numPerSlide: 4,

            // notice
            noticeList: [],
            screenWidth: 0,
            screenHeight: 0,

            // notice page
            page: 1,
            perLength: 10,
            perPage: 7,
            totalPage: 1,
            total: 0,

            // notice detail
            dialog: false,
            noticeType: '',
            noticeFlag: '',
            title: '',
            contents: '',
            editDate: '',
            editor: '',
            editorEmail: '',
            id: '',
            index: '',
            files: '',

            // calendar
            config: {
                customButtons: {
                    cstPrev: {
                        icon: 'left-single-arrow',
                        click: function() {
                            let calendar = jQuery(document.getElementById('calendar'))
                            calendar.fullCalendar('prev')
                            let loadDate = new Date(calendar.fullCalendar('getDate')._d)
                            document.querySelector('#dashboard').__vue__.getFirstLast(loadDate)
                        }
                    },
                    cstNext: {
                        icon: 'right-single-arrow',
                        click: function() {
                            let calendar = jQuery(document.getElementById('calendar'))
                            calendar.fullCalendar('next')
                            let loadDate = new Date(calendar.fullCalendar('getDate')._d)
                            document.querySelector('#dashboard').__vue__.getFirstLast(loadDate)
                        }
                    },
                    cstToday: {
                        text: 'Today',
                        click: function() {
                            let calendar = jQuery(document.getElementById('calendar'))
                            calendar.fullCalendar('today')
                            let loadDate = new Date(calendar.fullCalendar('getDate')._d)
                            document.querySelector('#dashboard').__vue__.getFirstLast(loadDate)
                        }
                    },
                },
                header:{
                    left: 'cstPrev, cstNext',
                    center: 'title',
                    right: 'cstToday'
                },
                editable: false,
                defaultView: 'month',
                selectHelper: false,
                locale: 'ko',
                height: 'parent'
            },
            events: [],
            calStt: '',
            calEnd: '',
            taskState: '',

            // task
            sttTask: [],
            endTask: [],
            taskDay: 7,

            // alarm
            alarmLimit: 30,
            alarmList: [],
        }
    },
    activated() {
        let _this = this
        window.io = io
        let connection = this.rtcmConnection = new RTCMultiConnection()
        connection.socketURL = 'https://91cm.nineonesoft.com:9002/'
        connection.connectSocket(function(socket) {
            socket.emit('get-public-rooms', 'nineone', function(listOfRooms) {
                _this.rtcmList = listOfRooms
            })

          socket.on('disconnect', function() {
              location.reload();
          });
      });
    },
    mounted() {
        window.addEventListener('resize', this.handleResize)
        this.commit('setCurrentChannel', '')
        this.getNoticeList(1)
        this.handleResize()
        this.getFirstLast()
        this.getTaskInfo('S')
        this.getTaskInfo('E')
        this.getRecentAlarm()
        this.onlineUsers()
        this.getChannelList()
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize)
    },
    computed: {
        editable: function() {
            return this.currentUser.email == this.editor.email || this.currentUser.roles.includes('ROLE_ADMIN') || this.currentUser.roles.includes('ROLE_ROOT')
        },
        screenHeightCheck: function() {
            return this.screenHeight < 1000 ? 'dense' : ''
        },
        subAlarmList: function() {
            if(this.$store.getters.getAlarmList.length) {
                return [...this.$store.getters.getAlarmList, ...this.alarmList]
            } else {
                return this.alarmList
            }
        },
    },
    watch: {
        page: function(newPage, oldPage) {
            this.getNoticeList(newPage)
        },
        dashboardChannelList: function(newVal, oldVal) {
            this.dashboardChannelList = newVal
        }
    },
    filters: {
        dateFormat(date) {
            if(date) {
                let dt = new Date(date)
                let year = dt.getFullYear()
                let month = dt.getMonth() + 1
                month = month > 9 ? month : ('0' + month)
                let day = dt.getDate()
                day = day > 9 ? day : ('0' + day)
                return year + '-' + month + '-' + day
            } else {
                return '-'
            }
        },
        // 7일 이내 최신 글
        newMark: function(date) {
            if(((new Date().getTime() - date) / (1000 * 60 * 60 * 24) ) < 7) {
                return '<b style="padding:0 0.3vw 0.2vh; background-color: orange; border-radius: 10px; color: white; font-size: 0.5rem;">New</b>'
            } else {
                return ''
            }
        },
    },
    methods: {
        getNoticeList(page, flag) {
            this.loadingNotice = true
            this.waitNotice = 'pending', // this.currLng.dashboard.waiting
            this.$http.get('/api/dashboard/noticeList?length=' + this.perLength + '&start=' + page).then(res=>{
                let data = res.data
                if(!flag) {
                    this.noticeList = data
                    if(res.data.length > 0) {
                        this.total = res.data[0].total
                        this.totalPage = Math.ceil(this.total / this.perLength)
                    }
                } else {
                    this.noticeList.push(...data)
                }
                this.loadingNotice = false
                this.waitNotice = 'sentiment_very_dissatisfied' // this.currLng.dashboard.noticeEmpty
            })
        },
        detail: function(id, index) {
            this.$http.get('/api/dashboard/noticeDetail?id=' + id).then(res=>{
                let data = res.data
                this.id = id
                this.index = index
                this.noticeType = data.notice_type
                this.noticeFlag = data.notice_flag
                this.title = data.title
                this.contents = data.contents
                this.editDate = data.edit_date
                this.editor = data.editor
                this.editorEmail = data.editor.email
                this.files = data.files
                this.dialog = true
            })
        },
        handleResize: function() {
            this.screenWidth = document.documentElement.clientWidth
            this.screenHeight = document.documentElement.clientHeight
            if(this.screenWidth < 540) {
                this.numPerSlide = 1
            } else if(this.screenWidth < 960) {
                this.numPerSlide = 2
            } else if(this.screenWidth < 1140) {
                this.numPerSlide = 3
            } else {
                this.numPerSlide = 4
            }
        },
        fileDownload: function (file, flag = false) {
            var query = '/api/file/download/'
            if(flag) {
                query += file.board_name + "/" + file.server_name
            } else {
                query += file.server_name
            }
            this.$http.get(query, {
                responseType: 'blob'
            })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', file.original_name)
                document.body.appendChild(link)
                link.click()
                link.remove()
                window.URL.revokeObjectURL(url)
            })
        },
        getFirstLast: function(date) {
            this.taskState = 1
            let now = new Date()
            if(date) {
                now = new Date(date)
            }
            this.calStt = this.$options.filters.dateFormat(new Date(now.getFullYear(), now.getMonth(), 1))
            this.calEnd = this.$options.filters.dateFormat(new Date(now.getFullYear(), now.getMonth() + 1, 0))
            this.getTaskInfo('M')
        },
        getTaskInfo: function(flag) {
            let query = 'flag=' + flag
            switch(flag) {
                case 'M':
                    this.loadingCalendar = true
                    this.events = []
                    query += '&startDate=' + this.calStt + '&endDate=' + this.calEnd + '&state=' + this.taskState
                    break;
                case 'S':
                case 'E':
                    this.loadingTodo = true
                    this.waitTodo = 'pending' // this.currLng.dashboard.waiting
                    query += '&day=' + this.taskDay + '&state=' + this.taskState
                    break;
                default:
                    break;
            }
            this.$http.get('/api/dashboard/getTaskInfo?' + query).then(res=>{
                let task = res.data
                if(task.length > 0) {
                    switch(flag) {
                        case 'M':
                            task.forEach(task => {
                                this.events.push({
                                    title: task.title || task.content,
                                    start: new Date(task.start_date),
                                    end: new Date(task.end_date).addDays(1),
                                    color: task.color,
                                    task: task
                                })
                            })
                            break;
                        case 'S':
                            this.sttTask = task
                            break;
                        case 'E':
                            this.endTask = task
                            break;
                        default:
                            break;
                    }
                }
                this.loadingCalendar = false
                this.loadingTodo = false
                this.waitTodo = 'sentiment_very_dissatisfied' // this.currLng.dashboard.todolistEmpty
            })
        },
        selectEvent: function(event) {
            if(event['task']) {
                event = event['task']
            }
            let channelInfo = this.$store.getters.getChannelList.filter(ch => { return ch.id == event.channel_id })
            this.joinChannel(channelInfo[0])
            this.$nextTick(() => {
                this.callComponent('todoList')
            })
        },
        getRecentAlarm: async function() {
            this.loadingNotification = true
            this.waitNotifications = 'pending' // this.currLng.dashboard.waiting
            let query = 'alarmLimit=' + this.alarmLimit
            await this.$http.get('/api/dashboard/getRecentAlarm?' + query).then(res=>{
                this.alarmList = res.data
            })
            this.loadingNotification = false
            this.waitNotifications = 'sentiment_very_dissatisfied' // this.currLng.dashboard.notificationsEmpty
        },
        getChannelList: function() {
            this.loadingChannel = true
            this.waitChannel = 'pending' // this.currLng.dashboard.waiting
            let channelList = []
            let cnt = 3
            let reload = setInterval(() => {
                if(channelList.length <= 0) {
                    cnt--
                    channelList = this.$store.getters.getChannelList
                } else {
                    this.dashboardChannelList = channelList
                    this.getChannelInfo()
                    cnt = 0
                }
                if(cnt <= 0) {
                    clearInterval(reload)
                    if(channelList.length <= 0) {
                        this.loadingChannel = false
                        this.waitChannel = 'sentiment_very_dissatisfied' // this.currLng.dashboard.channelInfoEmpty
                    }
                }
            }, 500)
        },
        getChannelInfo: function() {
            this.dashboardChannelList.forEach((channel, idx) => {
                this.loadingChannel = true
                this.$http.get('/api/user/channel/' + channel.id).then((res) => {
                    this.$set(channel, 'channelUsers', res.data)
                    return channel
                })
                .then((newChannel) => {
                    this.$http.post('/api/file/get/files', {channel_id: newChannel.id, first: true}).then((res) => {
                        this.$set(newChannel, 'channelFiles', res.data)
                        this.loadingChannel = false
                        this.waitChannel = 'sentiment_very_dissatisfied' // this.currLng.dashboard.channelInfoEmpty
                    })
                })
            })
        },
        checkFileType: function(file) {
            return Common.checkFileType(file)
        },
        checkRtcList: function(channel) {
            let result = false
            this.rtcmList.forEach((ch) => {
                if(ch.sessionid == channel.id) {
                    result = true
                    return
                }
            });
            return result
        },
        goVideoChat: function(channel) {
            let channelInfo = this.$store.getters.getChannelList.filter(ch => { return ch.id == channel.id })
            this.joinChannel(channelInfo[0])
            this.$nextTick(() => {
                this.$store.commit('setIsVideoMode', true)
            })
        },
        getNameByEmail(email) {
            if(this.$store.getters.getUserList.length > 0) {
                return this.$store.getters.getUserList.find(user => user.email == email).name
            }
        }
    },
}
</script>

<style scope>
.v-dialog {
    -webkit-box-shadow: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
    -moz-box-shadow: 0 0 0 0 !important;
    -o-box-shadow: 0 0 0 0 !important;
    padding: 30px 0;
    padding-right: 15px;
    padding-left: 15px;
    margin: 0 0 0 255px !important;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    overflow: hidden !important;
}
@media only screen and (max-width: 1023px) {
    .v-dialog {
        padding-left: 0 !important;
        padding-right: 0;
        margin-left: 0 !important;
    }
}
.dash-default-bottom {
    height: 40vh;
    overflow: hidden auto;
}
.v-divider {
    margin: 0.5em;
}
.avatar {
    color: #4c5667;
    font-weight: 600;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    display: inline-block;
    background: #ced4da no-repeat center/cover;
    position: relative;
    vertical-align: bottom;
    font-size: .875rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.blink {
	-webkit-animation: blink 0.5s ease-in-out infinite alternate;
    -moz-animation: blink 0.5s ease-in-out infinite alternate;
    animation: blink 0.5s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
    0% {opacity:0;}
    100% {opacity:1;}
}
@-moz-keyframes blink {
    0% {opacity:0;}
    100% {opacity:1;}
}
@keyframes blink {
    0% {opacity:0;}
    100% {opacity:1;}
}
.page-header {
    margin-bottom: 0 !important;
}
.dash-icon {
    font-size: 3em !important;
    color: rgba(0, 0, 0, 0.25) !important;
}
</style>
