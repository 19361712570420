<template>
  <main>
    <div>
      <div style="padding: 15px; display: flex; flex-direction: column; justify-content: center; position: relative;">
        <v-tabs v-model="tab" color="#BA68C8" background-color="white" class="elevation-2" centered grow style="padding: 0.3em; background-color: white">
          <v-tab v-for="tabName in tabs" :key="tabName">
            {{ tabName }}
          </v-tab>
          <v-tabs-items v-model="tab" class="settings" ref="tabItem">
            <v-tab-item class="settings-tab">

              <!-- 앱 추가하기 -->
              <div v-show="addingApp">
                <div><b>{{ isModify ? 'Modify' : 'Register' }} App</b></div>
                <div class="below-title add-app">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar tile>
                        <input ref="logoFile" type="file" id="appImg" style="display: none;" @change="setAppImg($event)" accept="image/png, image/gif, image/jpg, image/jpeg, image/svg, image/bmp">
                        <label for="appImg">
                          <img v-if="appImg" :src="appImg" alt="Upload" style="width: 50px;" v-on="on" v-bind="attrs" @mouseover="appImgRemoveMark = true" @mouseout="appImgRemoveMark = false" >
                          <img v-else-if="appInfo.logo_path" :src="require('@/assets/images/' + appInfo.logo_path)" alt="Upload" style="width: 50px;" v-on="on" v-bind="attrs">
                          <v-icon tile v-else v-on="on" v-bind="attrs">settings</v-icon>
                        </label>
                      </v-avatar>
                      <span v-show="appImg && appImgRemoveMark" @mouseover="appImgRemoveMark = true" @mouseout="appImgRemoveMark = false" @click="appImgRemove" aria-atomic="true" aria-label="Badge" aria-live="polite" role="status" class="v-badge__badge secondary" style="inset: auto auto calc(23em - 26px) calc(5em - 11px);">
                        <v-icon>remove</v-icon>
                      </span>
                    </template>
                    <span>{{ currLng.channel.txt.uploadImamge }}</span>
                  </v-tooltip>
                  <v-text-field v-model="appName" :label="currLng.channel.txt.appName" color="black" clearable :error-messages="appErrorMsg" counter="30" style="width: calc(99% - 48px - 35px - 2em); display: inline-block; margin: 1em;"></v-text-field>
                  <v-btn color="green" x-small dark @click="saveApp" style="height: 35px; width: 35px;">
                    <v-icon>create</v-icon>
                  </v-btn>
                  <span v-show="isModify" @click="deleteAppConfirm()" aria-atomic="true" aria-label="Badge" aria-live="polite" role="status" class="v-badge__badge" style="inset: auto auto 58% calc(99% - 53px); cursor: pointer; background-color: #E53935;">
                    {{ currLng.common.btn.delete }}
                  </span>
                  <v-btn color="error" x-small tile @click="closeApp" style="position: absolute; top: 0.7em; right: 0.6em; height: auto;">
                    <v-icon>close</v-icon>
                  </v-btn>
                </div>
              </div>

              <!-- 추가된 앱 -->
              <div v-show="registeredAppList.length > 0">
                <b>{{ registeredAppList.length }} App{{ registeredAppList.length > 1 ? 's' : ''}} in {{ info.name }}</b>
                <div class="below-title">
                  <v-list two-line>
                    <template v-for="(app, index) in registeredAppList">
                      <div :key="index" style="position: relative;" @mouseover="app.visible = true && !addingApp" @mouseout="app.visible = false">
                        <v-list-item style="padding-bottom: 0.5em;">
                          <v-list-item-avatar tile>
                            <img v-if="app.boardFile" style="max-width: 50px; max-height: 50px;" :src="defaultImgPath + app.boardFile.board_name + '/' + app.boardFile.server_name">
                            <img v-else-if="!!app.app && !!app.app.logo_path" :src="require('@/assets/images/' + app.app.logo_path)">
                            <v-icon v-else>settings</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content style="align-self: baseline;">
                            <v-list-item-title>
                              {{ app.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <v-text-field class="registered-app" color="#f76397" :value="makeApiUrl(app.address)" readonly dense @click="copyApiUrl($event)"></v-text-field>
                              <!-- <div class="copy-btn" v-show="app.visible" @click="copyApiUrl($event)">Copy</div> -->
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <span v-show="app.visible" @click="regenerateKey($event, app, index)" aria-atomic="true" aria-label="Badge" aria-live="polite" role="status" class="v-badge__badge" style="inset: auto auto 65% calc((94% - 120px) - 1em); cursor: pointer; background-color: #00897b;">
                          {{ currLng.common.btn.regenerate }}
                        </span>
                        <span v-show="app.visible" @click="modifyApp(app, index)" aria-atomic="true" aria-label="Badge" aria-live="polite" role="status" class="v-badge__badge" style="inset: auto auto 65% calc((94% - 40px) - 1em); cursor: pointer; background-color: #1976d2;">
                          {{ currLng.common.btn.modify }}
                        </span>
                      </div>
                    </template>
                  </v-list>
                </div>
              </div>

              <!-- 추가 가능한 앱 목록 -->
              <div><b>{{ currLng.channel.txt.recommendedApps }}</b></div>
              <v-row class="below-title">
                <v-col cols="12" sm="12" lg="6" v-for="app in appList" :key="app.id">
                  <v-card class="mx-auto">
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-title class="headline mb-1">
                          {{ app.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 1em;">{{ app.description }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-avatar tile size="50" style="border: solid 1px rgba(214, 214, 214, 0.5);">
                        <!-- 210413 전부 DB에서 가져오면 적용이 되지 않으므로 부분적으로 DB에서 가져오면 동적으로 이미지 호출 가능 -->
                        <img v-if="app.logo_path" :src="require('@/assets/images/' + app.logo_path)">
                        <v-icon v-else>settings</v-icon>
                      </v-list-item-avatar>
                    </v-list-item>
                    <v-card-actions>
                      <v-btn outlined text block :disabled="addingApp" @click="addApp(app)">{{ currLng.common.btn.add }}</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>

            <!-- rename -->
            <v-tab-item class="settings-tab">
              <v-alert dense outlined type="info" class="margin-bot" v-html="currLng.channel.msg.updateMsg.replaceAll('{txt1}', info.name)"></v-alert>
              <v-text-field v-model="modChannelName" :label="currLng.channel.txt.channelNameLabel" color="black" clearable :error-messages="errorMsg" counter="20"></v-text-field>
              <div class="buttons">
                <v-btn class="button" color="gray" outlined small @click="cancelRename()">{{ currLng.common.btn.cancel }}</v-btn>
                <v-btn class="button" color="info" small :disabled="!canMod" @click="rename()">{{ currLng.common.btn.modify }}</v-btn>
              </div>
            </v-tab-item>

            <!-- delete -->
            <v-tab-item class="settings-tab">
              <v-alert dense outlined type="error" class="margin-bot" v-html="currLng.channel.msg.deleteMsg.replaceAll('{txt1}', info.name)"></v-alert>
              <v-text-field v-model="delChannelName" :label="currLng.channel.txt.channelNameLabel" color="black" clearable></v-text-field>
              <div class="buttons">
                <v-btn class="button" color="gray" outlined small @click="cancelRename()">{{ currLng.common.btn.cancel }}</v-btn>
                <v-btn class="button" color="error" small :disabled="!canDel" @click="del()">{{ currLng.common.btn.delete }}</v-btn>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>

        <v-snackbar v-model="snackbar" :timeout="snackbarTime" color="success" absolute style="bottom: 10%;">
          {{ snackbarText }}
          <v-btn color="white" v-show="snackbarTime < 1" text @click="[deleteApp(), snackbar = false]">{{ currLng.common.btn.delete }}</v-btn>
          <v-btn color="white" text @click="[snackbar = false, snackbarTime = 2000]">{{ currLng.common.btn.close }}</v-btn>
        </v-snackbar>
      </div>
    </div>
  </main>
</template>
<script>
  export default {
    name: 'ChannelSettings',
    components: {
    },
    props: {
      info: '',
    },
    data() {
      return {
        tab: null,
        tabs: [this.$root.currentLang.channel.txt.apps, this.$root.currentLang.channel.txt.rename, this.$root.currentLang.channel.txt.delete],
        isLActive: false,
        isRActive: false,
        canMod: false,
        canDel: false,
        modChannelName: '',
        delChannelName: '',
        errorMsg: '',
        appList: [],
        registeredAppList: [],
        addingApp: false,
        appInfo: '',
        appOrgn: '',
        appImg: '',
        appOrgnImg: '',
        appOrgnImgId: '',
        appImgRemoveMark: false,
        appName: '',
        appOrgnName: '',
        appKey: '',
        appId: '',
        appIndex: -1,
        appErrorMsg: '',
        isModify: false,
        defaultPath: location.origin + '/api/channel/services/',
        defaultImgPath: '/api/file/download/',
        snackbar: false,
        snackbarTime: 2000,
        snackbarText: '',
      }
    },
    watch: {
      modChannelName: function(val) {
        if(val != this.info.name) {
          this.canMod = true 
        } else {
          this.canMod = false
        }
      },
      delChannelName: function(val) {
        if(val != this.info.name) {
          this.canDel = false
        } else {
          this.canDel = true
        }
      }
    },
    created() {
      this.modChannelName = this.info.name
      this.$http.get('/api/channel/appList').then(res => {
        this.appList = res.data
      })
      this.$http.get('/api/channel/registeredAppList?channelId=' + this.info.id).then(res => {
        let list = res.data
        list.forEach(data => {
          data.visible = false
        })
        this.registeredAppList = list
      })
    },
    computed: {
    },
    methods: {
      cancelRename: function() {
        this.$emit('closeDialog', true)
        this.modChannelName = this.info.name
      },
      rename: function() {
        if (this.modChannelName == '' || this.modChannelName == null) {
          this.errorMsg = "채널 이름을 입력해주세요."
        } else if (this.modChannelName.length > 20) {
          this.errorMsg = "채널 이름의 최대 글자 수는 20자 입니다."
        } else {
          let newChannel = Object.assign({}, this.info)
          newChannel.name = this.modChannelName
          this.updateChannel(newChannel)
          this.cancelRename()
        }
      },
      del: function() {
        this.deleteChannel(this.info)
        this.cancelRename()
      },
      addApp: function(app) {
        this.appInfo = app
        this.appName = app.name
        this.addingApp = true
        this.$refs.tabItem.$el.scrollTop = 0
      },
      setAppImg: function(event) {
        this.appImg = URL.createObjectURL(event.target.files[0])
      },
      appImgRemove: function() {
        this.appImg = ''
        this.$refs.logoFile.value = ''
      },
      saveApp: function() {
        if (this.appName == '' || this.appName == null) {
          this.appErrorMsg = "앱 이름을 입력해주세요."
        } else if (this.appName.length > 30) {
          this.appErrorMsg = "앱 이름의 최대 글자 수는 30자 입니다."

        // pass validation
        } else {
          
          // create
          if(!this.isModify) {
            let formData = new FormData()
            formData.append('channel_id', this.info.id)
            formData.append('app_id', this.appInfo.id)
            formData.append('name', this.appName)
            if(this.$refs.logoFile.files.length > 0) {
              formData.append('uploadFile', this.$refs.logoFile.files[0])
            }
            this.$http.post('/api/channel/registerApp', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
              let appInfo = this.appList.find(app => app.id == res.data.app_id)
              res.data.app = appInfo
              res.data.visible = false
              this.registeredAppList.push(res.data)
              this.closeApp()
            })
            this.snackbarText = 'Created!'
            this.snackbar = true

          // modify
          } else {
            if(this.appOrgnName != this.appName || this.appOrgnImg != this.appImg) {
              let formData = new FormData()
              formData.append('channel_id', this.info.id)
              formData.append('name', this.appName)
              formData.append('id', this.appId)
              let chkImg = false
              if(this.appOrgnImg != this.appImg && !!this.appOrgnImgId) {
                formData.append('boardFile.id', this.appOrgnImgId)
                formData.append('boardFile.board_name', 'app')
                chkImg = true
              }
              if(this.$refs.logoFile.files.length > 0) {
                formData.append('uploadFile', this.$refs.logoFile.files[0])
                chkImg = true
              }
              this.$http.post('/api/channel/modifyApp', formData, {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
              }).then(res => {
                this.$set(this.registeredAppList[this.appIndex], 'name', res.data.name)
                if(chkImg) {
                  if(res.data.board_file_id > 0) {
                    this.$set(this.registeredAppList[this.appIndex], 'boardFile', res.data.boardFile)
                  } else {
                    this.$set(this.registeredAppList[this.appIndex], 'boardFile', null)
                  }
                }
                this.closeApp()
              })
              this.snackbarText = 'Modified!'
            } else {
              this.snackbarText = 'Unchanged!'
            }
            this.closeApp()
            this.snackbar = true
          }
        }
      },
      closeApp: function() {
        this.appErrorMsg = ""
        this.appImg = ''
        this.$refs.logoFile.value = ''
        this.appName = ''
        this.appInfo = ''
        this.appKey = ''
        this.addingApp = false
        this.isModify = false
        this.snackbar = false
      },
      makeApiUrl: function(address) {
        return this.defaultPath + address
      },
      copyApiUrl: function(event) {
        let btnEl = event.target
        btnEl.textContent = 'Copied!'
        btnEl.style.fontWeight = 'bolder'
        btnEl.style.color = '#f76397'
        setTimeout(() => {
          btnEl.textContent = 'Copy'
          btnEl.style.fontWeight = ''
          btnEl.style.color = 'rgba(0,0,0,.6)'
        }, 2000)
        let apiUrlEl = btnEl.parentNode.querySelectorAll('[type=text]')[0]
        apiUrlEl.select()
        document.execCommand('copy')
        apiUrlEl.setSelectionRange(0, 0)

        this.snackbarText = 'Copied!'
        this.snackbar = true
      },
      regenerateKey: function(event, app, index) {
        delete app.visible
        this.$http.post('/api/channel/regenerateKey', app).then(res => {
          if(res.status == 200) {
            this.$set(this.registeredAppList[index], 'address', res.data)
            delete this.registeredAppList[index].visible
            this.$set(this.registeredAppList[index], 'visible', true)

            let btnEl = event.target
            let apiUrlEl = btnEl.parentNode.querySelectorAll('[type=text]')[0]
            apiUrlEl.select()
            apiUrlEl.setSelectionRange(0, 0)

            this.snackbarText = 'Regenerated!'
            this.snackbar = true
          }
        })
      },
      modifyApp: function(app, index) {
        this.addingApp = true
        this.isModify = true
        this.appId = app.id
        this.appName = app.name
        this.appOrgnName = app.name
        this.appIndex = index
        if(!!app.boardFile) {
          this.appImg = this.defaultImgPath + app.boardFile.board_name + '/' + app.boardFile.server_name
          this.appOrgnImg = this.defaultImgPath + app.boardFile.board_name + '/' + app.boardFile.server_name
          this.appOrgnImgId = app.boardFile.id
        }
        this.appInfo = app.app
        this.appOrgn = app
        this.appKey = this.defaultPath + app.address
        this.$refs.tabItem.$el.scrollTop = 0
      },
      deleteApp: function() {
        this.snackbarTime = 2000
        let app = this.appOrgn
        delete app.visible
        this.$http.post('/api/channel/deleteApp', app).then(res => {
          if(res.status == 200) {
            this.registeredAppList.splice(this.appIndex, 1)
          }
          this.closeApp()
        })
      },
      deleteAppConfirm: function() {
        this.snackbarTime = 0
        this.snackbar = true
        this.snackbarText = `앱 ${this.appOrgn.name} 를 삭제하시겠습니까?`
      }
    }
  }
</script>
<style scoped>
  .moHeight {
    height: inherit;
    overflow: auto;
  }
  .tabColor {
    background-color: #404E67;
  }
  .settings {
    margin: 1.5em;
    height: 25em;
    overflow: hidden auto;
  }
  .settings-tab {
    height: inherit;
  }
  .buttons {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .button {
    margin: 0 0 0 1em;
  }
  .margin-bot {
    margin-bottom: 3em;
  }
  .below-title {
    margin: 1em 0.5em;
  }
  .add-app {
    border: dotted 0.1em red;
    padding: 0.4em;
  }
  .registered-app {
    width: 96%; 
    display: inline-block; 
    margin: 0; 
    padding: 0; 
    height: 2.2em;
  }
  .registered-app input {
    cursor: default;
  }
  .copy-btn {
    left: calc(96% - 56px); 
    position: absolute; 
    cursor: pointer;
  }
  @media only screen and (max-width: 600px) {
    .copy-btn {
      left: 80%;
    }
  }
</style>
