import axios from 'axios'

class CommonClass {
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  replacemsgForPreview(originContent) {
    let array = originContent.split("\n")
    let content = ''
    for (let i in array) {
      content += array[i] + ' '
    }
    return content
  }

  byteCount(s, b, i, c) {
    for (b = i = 0; c = s.charCodeAt(i++); b += c >> 11 ? 3 : c >> 4 ? (c >> 7 ? 2 : 1) : 2) ;
    return b
  }

  byteLimit(length) {
    if (length > 30000) {
      alert('최대 30000byte까지 입력이 가능합니다.')
      return false
    } else {
      return true
    }
  }

  byteCountAndLimit(msg) {
    let isAlert = false
    while(this.byteCount(msg) > 30000) {
      isAlert = true
      msg = msg.substring(0, msg.length - 1)
    }
    if(isAlert) {
      alert('최대 30000byte까지 입력이 가능합니다.')
    }
    return msg
  }

  fileDownload(file) {
    axios.get("/api/file/download/" + file.server_name, {
      responseType: 'blob'
    })
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url;
        link.setAttribute('download', file.original_name)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
      })
  }

  checkFileType(file, option = 'thumb') {
    if (file !== undefined) {
      let type = file.extension
      type = type.toLowerCase().trim()
      switch (type) {
        case ('png'):
        case ('jpg'):
        case ('jpeg'):
        case ('gif'):
          if (option == 'origin') {
            return "/api/file/download/" + file.server_name
          }
          //download뒤에 thumb인지 origianl인지 구분 api 만들기
          return "/api/file/download/thumb" + file.server_name
        case ('zip'):
        case ('7z'):
        case ('tar'):
          if (option == 'tiles') {
            return require('@/assets/images/fileIcon/zip-new.png')
          } else {
            return require('@/assets/images/fileIcon/zip_icon.png')
          }
        case 'pdf':
          if (option == 'tiles') {
            return require('@/assets/images/fileIcon/pdf-new.png')
          } else {
            return require('@/assets/images/fileIcon/pdf_icon.png')
          }

        case 'txt':
          if (option == 'tiles') {
            return require('@/assets/images/fileIcon/txt-new.png')
          } else {
            return require('@/assets/images/fileIcon/txt_icon.png')
          }
        default:
          if (option == 'tiles') {
            return require('@/assets/images/fileIcon/file-new.png')
          } else {
            return require('@/assets/images/fileIcon/file_icon.png')
          }
      }
    }
  }
}

export default new CommonClass()
