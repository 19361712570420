var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('div',{staticClass:"video-container"}),_c('v-row',{staticStyle:{"height":"90%"},attrs:{"justify":"center","align":"center","no-gutters":""}},[_c(_vm.defaultLayout,{ref:"layout",tag:"component",attrs:{"rtcm-connection":_vm.rtcmConnection,"video-list":_vm.videoList}})],1),_c('v-row',{attrs:{"justify":"end"}},[_c('div',{staticStyle:{"text-align":"center","margin":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"color":"blue-grey"},on:{"click":_vm.shareScreen}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("airplay")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.shareScreenWord))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"color":"blue-grey"},on:{"click":_vm.toggleMyVideoMute}},'v-btn',attrs,false),on),[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCam),expression:"isCam"}],staticStyle:{"width":"2rem"},attrs:{"src":require("../../assets/images/cam.png")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCam),expression:"!isCam"}],staticStyle:{"width":"2rem"},attrs:{"src":require("../../assets/images/cam-off.png")}})])]}}])},[_c('span',[_vm._v(_vm._s(_vm.myVideoMute))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"color":"blue-grey"},on:{"click":_vm.toggleMyAudioMute}},'v-btn',attrs,false),on),[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMic),expression:"isMic"}],staticStyle:{"width":"2rem"},attrs:{"src":require("../../assets/images/mic.png")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMic),expression:"!isMic"}],staticStyle:{"width":"2rem"},attrs:{"src":require("../../assets/images/mic-off.png")}})])]}}])},[_c('span',[_vm._v(_vm._s(_vm.myAudioMute))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"color":"blue-grey"},on:{"click":_vm.hideChat}},'v-btn',attrs,false),on),[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isChat),expression:"isChat"}],staticStyle:{"width":"2rem"},attrs:{"src":require("../../assets/images/chat.png")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isChat),expression:"!isChat"}],staticStyle:{"width":"2rem"},attrs:{"src":require("../../assets/images/chat-off.png")}})])]}}])},[_c('span',[_vm._v("HideChat")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-menu',{attrs:{"open-on-click":"","top":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"color":"blue-grey"}},'v-btn',attrs,false),on),[_c('img',{staticStyle:{"width":"2rem"},attrs:{"src":require(("@/assets/images/" + _vm.defaultLayout + ".png"))}})])]}}],null,true)},[_c('v-list',{attrs:{"color":"blue-grey"}},_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_c('a',{on:{"click":function($event){return _vm.changeLayout(item.kind,index)}}},[_c('img',{staticStyle:{"width":"2rem"},attrs:{"src":require(("@/assets/images/" + (item.kind) + ".png"))}})])])],1)}),1)],1)]}}])},[_c('span',[_vm._v("Layout")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"color":"red"},on:{"click":_vm.toggleVideoMode}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"my-video",staticStyle:{"margin-right":"7px"},attrs:{"right":"","black":"","large":""}},[_vm._v("exit_to_app")])],1)]}}])},[_c('span',[_vm._v("Exit")])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }