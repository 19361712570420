<template>
  <main class="mainwrapper" :style="chatHeight">
    <transition name="slide-fade">
      <div class="h-inherit" v-cloak @drop.prevent="dropFile" @dragover.prevent>
        <ul class="c-c-wrapper list-unstyled" @scroll="scrollEvt" ref="chatWindow" style="padding: 0 !important; background-color: #f6f7fb; border-radius: 0.5em 0 0 0;">
          <div v-for="msg in msgArray" :key="msg.id">
            <template v-if="msg.id == getThreadId || msg.thread == getThreadId">
              <MsgBox v-if="msg.message_type=='message'|| msg.message_type=='file' || msg.message_type == 'translate'" :msg="msg" @scrollToEnd="scrollToEnd" @imgLoad="imgLoad"></MsgBox>
              <MsgBox v-else-if="msg.message_type.includes('api-')" @scrollToEnd="scrollToEnd" :msg="msg"></MsgBox>
              <div v-else-if="msg.message_type=='action'" class="hori-align">
                <v-chip class="ma-2" style="font-weight:bold;">
                  {{ msg.content }}
                </v-chip>
              </div>
              <div v-else-if="msg.message_type=='date'" class="date-divider">
                <span class="mydate">{{ msg.content }}</span>
              </div>
            </template>

          </div>
        </ul>
        <a v-if="msgPreviewBool && !isRoot()" @click="clickMsgPreview">
          <div id="c-c-preview">
            <div class="p-wrapper">
              <div>{{ previewObj.username }} : &nbsp;</div>
              <div class="p-nowrap" v-html="previewObj.content"></div>
            </div>
          </div>
        </a>

        <v-row align="center" justify="center" class="c-i-wrapper" v-if="!isRoot()" style="background-color: #f6f7fb;">
          <div class="myflex-column myflex-grow">
            <div style="position: relative; display: flex; height: 7em;">
              <div class="mytextarea-wrapper" v-if="!isSearchMode" :style="isDelete ? 'background-color: #e9ecef' : ''">
                <b-form-textarea
                  class="mytextarea"
                  autofocus
                  id="textarea-no-resize"
                  :placeholder="currLng.common.msg.enterCtn"
                  rows="2"
                  no-resize
                  v-model="threadMessage.content"
                  @keydown.enter.exact="sendThreadMessage($event)"
                  @keyup="byteCheck"
                  :readonly="isDelete"
                ></b-form-textarea>
                <div v-if="!isDelete" style="float: right; margin-right: 5px;">
                  <v-tooltip top><template v-slot:activator="{ on, attrs }">
                    <label for="file-input-thread" style="">
                      <i class="im im-cloud-upload icon-list" v-bind="attrs" v-on="on"></i>
                    </label>
                    <input id="file-input-thread" type="file" ref="fileInputInThread" @change="attachFileInThread" hidden />
                  </template><span>{{ currLng.chat.upload }}</span></v-tooltip>
                </div>
              </div>

              <div class="verti-align">
                <v-btn class="mx-2" fab dark large color="cyan" v-if="!isSearchMode" @click="sendThreadMessage($event)">
                  <i class="im im-paperplane"></i>
                </v-btn>
              </div>
            </div>
            <div style="display: flex;flex-grow: 1;">
              <!-- 파일 업로드 progress bar -->
              <v-progress-linear v-if="isFileUpload" color="cyan darken-4" height="10" v-model="progressValue" striped></v-progress-linear>
            </div>
          </div>
        </v-row>
      </div>
    </transition>
  </main>
</template>
<script>
  import MsgBox from './MsgBox'
  import CommonClass from '../../service/common'
  import SearchInput from './SearchInput'
  import InviteInput from "../../components/InviteInput"

  export default {
    name: 'ContentWrapper',
    components: {
      InviteInput,
      MsgBox, 
      SearchInput,
    },
    data() {
      return {
        isFileUpload: false,
        progressValue: 0,
        sendMail: false,
        tempImg: '',
        previewObj: {
          content: '',
          username: ''
        },
        msgPreviewBool: false,
        selectedUserEmail: '',

        threadId: 0,
        scrollHeight: 0,
        preScrollHeight: 0,
        currScroll: 0,
        isTranslate: false,
        isSearchMode: false,
        chatWindow: '',
        maxUploadFileLength: 10,
        threadMessage: {
          content: ''
        },
        isDelete: true,
      }
    },
    props: {
      threadInfo: {
        isThread: false,
        threadId: '',
        threadContent: '',
      },
      chatHeight: '',
    },
    mounted() {
      window.addEventListener('resize', this.initHeight())
      this.$nextTick(() => {
        this.getDeleteYN()
        this.getThreadMsg()
        this.moveBottomScroll()
      })
    },
    watch: {
      msgArray: function () {
        this.initHeight()
        if(!this.isBottomScroll()) {
          let copymsg = JSON.parse(JSON.stringify(this.msgArray[this.msgArray.length - 1]))
          if(copymsg.thread == this.threadInfo.threadId) {
            this.previewObj.content = copymsg.content == null ? "첨부파일" : CommonClass.replacemsgForPreview(copymsg.content)
            this.previewObj.username = this.msgArray[this.msgArray.length - 1].user.name
            this.msgPreviewBool = true
          }
        }
        this.getThreadMsg()
        this.scrollToEnd()
      },
      getThreadId: function(val) {
        this.getDeleteYN()
        this.getThreadMsg()
        this.moveBottomScroll()
      }
    },
    computed: {
      getThreadId() {
        return this.threadInfo.threadId
      },
    },
    methods: {
      getThreadMsg: function() {
        let existMsg = this.msgArray.filter(msg => msg.thread == this.threadInfo.threadId)
        let firstMsg = this.msgArray.find(msg => msg.id == this.threadInfo.threadId)
        if(existMsg.length < 30 && !firstMsg) {
          this.selectMessageList(this.currentChannel, false, false, true)
        }
      },
      getDeleteYN: function() {
        this.isDelete = true
        this.$http.get('/api/message/getDeleteYN?id=' + this.threadInfo.threadId).then(res => {
          this.isDelete = res.data.delete_yn == 'Y' ? true : false
        })
      },
      isThread() {
        return this.threadInfo.isThread
      },
      // 바닥 체크 (10px)
      isBottomScroll() {
        return (this.preScrollHeight - 10) < this.currScroll
      },
      moveBottomScroll() {
        this.$nextTick(() => {
          this.chatWindow.scrollTop = this.scrollHeight
        })
      },
      // when change chat window size
      initHeight() {
        this.preScrollHeight = this.scrollHeight
        this.$nextTick(() => {
          this.chatWindow = this.$refs.chatWindow
          this.scrollHeight = this.chatWindow.scrollHeight - this.chatWindow.clientHeight
        })
      },
      // go to scroll end position
      scrollToEnd() {
        // 스크롤이 바닥인 경우
        if(this.isBottomScroll()) {
          this.moveBottomScroll()
        }
      },
      // scroll event
      scrollEvt(e) {
        this.initHeight()
        let element = e.target
        this.currScroll = element.scrollTop
        if (element.scrollTop <= 0 && element.scrollHeight != element.clientHeight) {
          this.selectMessageList(this.currentChannel, false, false, true)
        }
        if(this.isBottomScroll()) {
          this.msgPreviewBool = false
        }
      },
      imgLoad(e) {
        if(e.type == 'error') {
          e.target.src = '/img/file_icon.png'
        } else {
          this.$nextTick(() => {
            this.scrollToEnd()
          })
        }
      },
      // send thread chat message
      async sendThreadMessage(e, isSysMsg) {
        if (e != null) {
          e.preventDefault()
        }
        if (this.threadMessage.content == '') {
          return;
        }
        if (isSysMsg) {
          this.threadMessage.message_type = 'action'
          this.threadMessage.sender = null
        } else {
          this.threadMessage.sender = this.$store.state.currentUser.email
          this.threadMessage.user = this.$store.state.currentUser
          this.threadMessage.thread = this.getThreadId
          this.threadMessage.message_type = 'message'
        }
        if (this.isTranslate) {
          this.threadMessage.content = await this.translateMessage(this.threadMessage.content)
          this.threadMessage.message_type = 'translate'
        }

        this.threadMessage.channel_id = this.$store.state.currentChannel.id
        this.threadMessage.content = CommonClass.byteCountAndLimit(this.threadMessage.content)

        if (this.$store.state.stompClient && this.$store.state.stompClient.connected) {
          this.$store.state.stompClient.send("/pub/chat/message", JSON.stringify(this.threadMessage), () => {})
          let sendedMsg = Object.assign({}, this.threadMessage)
          this.threadMessage.content = ''
          this.moveBottomScroll()
          this.sendThreadMsg(sendedMsg)

        } else {
          this.threadMessage.content = '<p style="color:red;">메세지 전송에 실패하였습니다.</p>' + this.threadMessage.content
          let errormsg = JSON.parse(JSON.stringify(this.threadMessage))
          this.$store.commit('pushMsg', errormsg)
          this.threadMessage.content = ''
        }
      },
      byteCheck(e) {
        // v-model을 썼음에도 e.target.value를 사용하는 이유는 한글은 바로 바인딩이 안되기때문에 수동적으로 값들을 message.content에 넣기 위함이다.
        this.threadMessage.content = e.target.value
        if ((47 < e.keyCode && e.keyCode < 112 && e.ctrlKey == false) || (e.keyCode == 13 && e.shiftKey == true) || e.keyCode == 32 || e.keyCode == 229) {
          this.threadMessage.content = CommonClass.byteCountAndLimit(this.threadMessage.content)
        }
      },
      clickMsgPreview() {
        this.initHeight()
        this.moveBottomScroll()
        this.msgPreviewBool = false
      },

      // 번역 버튼
      translateToggle: function () {
        this.isTranslate = !this.isTranslate
        if (this.isTranslate) {
          this.$_alert('지금부터 보내는 메시지는 번역 내용과 같이 보내집니다.')
        }
      },
      inviteToggle: function (e) {
        // isThread = false일때 동작
        let el = document.querySelector(".menuable__content__active.inviteClass")
        if (this.$store.state.isInviteMode == false) {
          this.$store.state.isInviteMode = !this.$store.state.isInviteMode
        } else {
          if (el == null) {
            this.inviteDataInit()
          }
        }
      },
      toggleSearchMode: function () {
        this.isSearchMode = !this.isSearchMode
      },
      dropFile: function (e) {
        if(!this.isDelete) {
          this.addFile(e.dataTransfer.files)
        }
      },
      attachFileInThread: function (e) {
        this.addFile(e.target.files)
        this.$refs.fileInputInThread.value = null
      },
      addFile: function (uploadFiles) {
        const maxUploadSize = 100 * 1024 * 1024;
        let maxUploadLength = this.maxUploadFileLength;
        if(this.isThread()) {
          maxUploadLength = 1
        }

        if (uploadFiles[0] == null) {
          return;
        }
        if(uploadFiles.length > maxUploadLength) {
          this.$_alert('한번에 업로드 할 수 있는 파일의 개수는 ' + maxUploadLength + '개입니다.')
          return;
        }

        this.progressValue = 0
        let fileSize = 0;
        let formData = new FormData();
        ([...uploadFiles]).forEach(file => {
          if (file.size <= 0) {
            this.$_alert('0byte인 파일은 업로드 할 수 없습니다.')
            return
          }
          formData.append("files", file)
          fileSize += file.size
        });
        if (fileSize >= maxUploadSize) {
          this.$_alert('한번에 보낼 수 있는 파일 용량은 100MB 입니다.')
          return;
        } else if (fileSize <= 0) {
          return;
        }
        formData.append('channel_id', this.$store.state.currentChannel.id)
        formData.append('sender', this.$store.state.currentUser.email)
        formData.append('type', 'file')

        if(this.isThread()) {
          formData.append('thread_id', this.getThreadId)
        }

        let sendedMsg = {}
        sendedMsg.thread = this.getThreadId
        this.uploadFile(formData, sendedMsg)
      },
      uploadFile(formData, threadId) {
        this.isFileUpload = true
        this.$http.post('/api/file/upload', formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: event => {
              this.progressValue = Math.round((100 * event.loaded) / event.total);
            }
        }).then(res => {
          this.isFileUpload = false
          this.sendThreadMsg(threadId)
        }).catch(error => {
          this.isFileUpload = false
          this.progressValue = 0
          this.$_error('폴더는 업로드 할 수 없습니다.')
        })
      },
    },
  }
</script>

<style scoped>

  @media only screen and (max-width: 1023px) {
    .wrapper .page-wrap .main-content {
      padding-left: 0px !important;
    }
  }

  .theme--light.v-chip:hover:before {
    opacity: 0;
  }

  .v-chip.v-size--default {
    min-height: 32px;
    height: auto;
  }

  .v-chip {
    white-space: normal;
  }

  #c-c-preview {
    bottom: 16em !important;
  }

  [v-cloak] {
    display: none;
  }
</style>
