<template>
  <div>
    <v-card v-bind:class="{moHeight:  $store.state.isSmallWidth }">
      <v-card-title>{{ currLng.admin.txt.auth.title }}</v-card-title>
      <v-data-table
        :headers="headers"
        :items="authUserList"
        :items-per-page="5"
        :calculate-width="true"
        class="elevation-1">
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ currLng.admin.txt.auth.modifyInfo }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field :disabled="true" v-model="editedIndex" :label="currLng.admin.txt.auth.col.num"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="editedItem.name" :label="currLng.admin.txt.auth.col.name"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field :disabled="true" v-model="editedItem.email" :label="currLng.admin.txt.auth.col.email"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete v-model="editedItem.authority" :items="authorityList" :label="currLng.admin.txt.auth.col.authority"></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <!--          <v-spacer></v-spacer>-->
          <v-btn color="blue darken-1" text @click="close">{{ currLng.common.btn.cancel }}</v-btn>
          <v-btn color="blue darken-1" text @click="save">{{ currLng.common.btn.modify }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  export default {
    name: "AuthorityPage",
    beforeCreate() {
      this.$http.post('/api/user/admin/userList')
        .then(res => {
          this.authUserList = res.data
          this.authUserList.forEach((user, index) => {
            user.number = index + 1;
          });
        })
    },
    data() {
      return {
        authUserList: [],
        authorityList: ["ROLE_ADMIN", "ROLE_USER", "ROLE_ANON"],
        editedIndex: -1,
        editedItem: {
          number: 0,
          name: "",
          email: "",
          authority: 0,
          protein: 0
        },
        dialog: false,
        headers: [
          { text: this.$root.currentLang.admin.txt.auth.col.num, align: "start", sortable: false, value: "number" },
          { text: this.$root.currentLang.admin.txt.auth.col.name, value: 'name' },
          { text: this.$root.currentLang.admin.txt.auth.col.email, value: 'email', sortable: false },
          { text: this.$root.currentLang.admin.txt.auth.col.authority, value: 'authority' },
          { text: this.$root.currentLang.admin.txt.auth.col.modify, value: 'actions', sortable: false },
        ],
      }
    },
    methods: {
      close() {
        this.dialog = false;
      },
      save() {
        this.$http.post('/api/user/admin/auth', {
            member_email: this.editedItem.email,
            roles_authority: this.editedItem.authority
          }
        )
          .then(res => {
            this.authUserList[this.editedIndex].authority = this.editedItem.authority
            this.dialog = false
            this.$_alert("권한이 수정되었습니다.");
          }).catch(error => {
          this.$_error("권한 수정에 실패했습니다." + "\n" + error)
          this.dialog = false
        })
      },
      editItem(item) {
        this.editedIndex = this.authUserList.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
        if (this.isAdmin()) {
          this.authorityList = this.authorityList.filter(item => item != 'ROLE_ADMIN')
        }
      },
    }
  }
</script>
