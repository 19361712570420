<template>
  <div>
    <!-- 210415 api message template -->
    <li class="list-unstyled chat-message" v-if="msg.delete_yn != 'Y' && msg.message_type.includes('api-')">
      <div class="myflex" @mouseover="showMsgOption(msg.id)" @mouseleave="hideMsgOption(msg.id)">
          <div class="icon">
            <img onError="this.src='/img/default-user-picture.png'" v-if="msg.api.boardFile" :src="'/api/file/download/' + msg.api.boardFile.board_name + '/' + msg.api.boardFile.server_name" width="40" height="40" />
            <img onError="this.src='/img/default-user-picture.png'" v-else-if="msg.api.app.logo_path" :src="require('@/assets/images/' + msg.api.app.logo_path)" width="40" height="40" />
            <v-icon tile v-else>settings</v-icon>
          </div>
          <div>
            <div class="verti-align urMsg">
              <strong>{{ msg.api.name }}</strong>
            </div>
            <template v-if="msg.api.app.name == 'Whatap'">
              <div class="myflex">
                <div class="mychat-content" @contextmenu="$emit('rightClick', $event, '', 'todo')" :style="{backgroundColor: bgcolor, position: 'relative', color: '#fff'}">
                  <v-icon size="3.5em">{{ changeIcon }}</v-icon>
                  <div class="apiName">
                    <b>{{ changeName }}</b>
                  </div>
                  <v-progress-linear v-if="checkPrgsbar" :value="msg.content.metricValue" color="white" height="2em" class="apiProg">
                    <!--21-04-27 v-model="msg.content.metricValue" 로 하면 클릭 시 값이 변경됨 -->
                    <template v-slot:default="{ value }">
                      <strong>{{ value }}%</strong>
                    </template>
                  </v-progress-linear>
                  <div>
                    <div class="apiMsg">
                      <b>{{ msg.content.message }}</b> <br/>
                    </div>
                    Project : {{ msg.content.projectName }} <br/>
                    Application : {{ msg.content.oname }} <br/>
                    Date : {{ changeDate }} <br/>
                  </div>
                  <v-btn block plain outlined class="apiBtn" @click="moveLink">이동</v-btn>

                  <div v-if="msg.thread && mainThread" :class="{blink: msg.check}">
                    <div block plain outlined class="threadBtn" @click="$emit('moveThread', msg)">
                      <v-icon style="color: red; font-size: 1em; padding: 0 0.2em 0.2em 0;">trip_origin</v-icon>
                      <b style="color: #2b5be2; margin-right: 0.3em;">{{ msg.thread_count }}개의 쓰레드</b><br/><span style="margin: 0 0.2em; font-size: 0.8em; color: #505050;">최근 시간 : {{ latest }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="myflex">
                <div class="mychat-content" @contextmenu="$emit('rightClick', $event, '', 'todo')">
                 <pre>{{ msg.content.text }}</pre>
                </div>
              </div>
            </template>
          </div>
        <div style="display:flex;align-items: flex-end;">
          <span style="font-size: 10px; margin:0 0 0 3px; width:53px;">{{ msg.str_send_date }}</span>
          <a v-show="!msg.thread || mainThread" class="verti-align thread" style="visibility: hidden;" :ref="'thread' + msg.id" @click="$emit('moveThread', msg)">
            <v-icon style="font-size:16px;">question_answer</v-icon>
          </a>
          <a class="verti-align confirmMsgDel" :ref="'confirmMsgDel' + msg.id" @click="confirmDelete(msg)">
            <v-icon style="font-size:16px;">delete_outline</v-icon>
          </a>
        </div>
      </div>
    </li>

    <li class="list-unstyled chat-message" v-else-if="!isMsgByLoginUser">
      <div class="myflex" @mouseover="showMsgOption(msg.id)" @mouseleave="hideMsgOption(msg.id)">
        <div class="icon">
          <slot name="m-icon">
            <img onError="this.src='/img/default-user-picture.png'" class="icon-round" :src="msg.user.picture" width="40" height="40"/>
          </slot>
        </div>
        <!-- flex에서 벗어나기 위해 감쌈  -->
        <div>
          <div class="verti-align urMsg">
            <slot name="m-info">
              <strong>{{ msg.user.name || msg.api.name }}</strong>
            </slot>
          </div>
          <!-- 채팅메세지내용 -->
          <div class="myflex">
            <slot name="m-content">
              <div v-if="checkMsgType" class="mychat-content" @contextmenu="$emit('rightClick', $event, '', 'todo')" style="color: #fff;">
                <pre v-html="textbyFilter(msg.content)"></pre>

                <div v-if="msg.thread && mainThread" :class="{blink: msg.check}">
                  <div block plain outlined class="threadBtn" @click="$emit('moveThread', msg)">
                    <v-icon style="color: red; font-size: 1em; padding: 0 0.2em 0.2em 0;">trip_origin</v-icon>
                    <b style="color: #2b5be2; margin-right: 0.3em;">{{ msg.thread_count }}개의 쓰레드</b><br/><span style="margin: 0 0.2em; font-size: 0.8em; color: #505050;">최근 시간 : {{ latest }}</span>
                  </div>
                </div>

              </div>
              <div style="display:flex;align-items: flex-end;">
                <div v-if="checkFileType" class="mychat-content">
                  <b-row>
                    <b-col v-for="(file,index) in msg.files" :key="index">
                      <a @click="fileDownload(file)">
                        <div class="hori-align">
                          <b-img :src="selectImage(file)" @error="$emit('imgLoad', $event)" @load="$emit('imgLoad',$event)" style="max-width:100px; max-height:100px;"></b-img>
                        </div>
                        <p class="file-name" :title="file.original_name" ><b>{{file.original_name}}</b></p>
                        <p style="margin:0px;">file size : {{ (file.file_size / 1024).toLocaleString(undefined,{minimumFractionDigits:2}) }} kb</p>
                      </a>
                    </b-col>
                  </b-row>

                  <div v-if="msg.thread && mainThread" :class="{blink: msg.check}">
                    <div block plain outlined class="threadBtn" @click="$emit('moveThread', msg)">
                      <v-icon style="color: red; font-size: 1em; padding: 0 0.2em 0.2em 0;">trip_origin</v-icon>
                      <b style="color: #2b5be2; margin-right: 0.3em;">{{ msg.thread_count }}개의 쓰레드</b><br/><span style="margin: 0 0.2em; font-size: 0.8em; color: #505050;">최근 시간 : {{ latest }}</span>
                    </div>
                  </div>
                </div>
                <div v-if="msg.message_type == 'url'" class="mychat-content">
                </div>
                <span style="font-size: 10px; margin:0 3px; width:53px;">{{ msg.str_send_date }}</span>
                <a v-show="!msg.thread || mainThread" class="verti-align thread" style="visibility: hidden;" :ref="'thread' + msg.id" @click="$emit('moveThread', msg)">
                  <v-icon style="font-size:16px;">question_answer</v-icon>
                </a>
                <a class="verti-align confirmMsgDel" :ref="'confirmMsgDel' + msg.id" @click="confirmDelete(msg)">
                  <v-icon style="font-size:16px;">delete_outline</v-icon>
                </a>
              </div>
            </slot>
          </div>
          <div class="msg-og-container-l" v-if="urlList.length>0">
            <v-card class="msg-og-cardsize" @click="windowOpen()">
              <v-img :src="urlList[0].og_image" class="msg-og-imgsize"
                     style="height: 100px; width: 15vw;min-width: 150px;max-width: 350px;"/>

              <v-card-text>
                <div style="color: black;" class="txt-ellipsis">{{urlList[0].og_title}}</div>
                <div class="txt-ellipsis">{{urlList[0].og_description}}</div>
              </v-card-text>

            </v-card>
          </div>
          <!-- 채팅메시지내용끝 -->
        </div>
      </div>
    </li>

    <li class="list-unstyled chat-message msgflex-end" v-else>
      <!-- flex에서 벗어나기 위해 감쌈  -->
      <div @mouseover="showMsgOption(msg.id)" @mouseleave="hideMsgOption(msg.id)">
        <!-- 채팅메세지내용 -->
        <div class="myflex msgflex-end">
          <slot name="m-content">
            <div style="display:flex;align-items: flex-end;">
              <a class="verti-align confirmMsgDel" :ref="'confirmMsgDel' + msg.id" @click="confirmDelete(msg)">
                <v-icon style="font-size:16px;">delete_outline</v-icon>
              </a>
              <a v-show="!msg.thread || mainThread" class="verti-align thread" style="visibility: hidden;" :ref="'thread' + msg.id" @click="$emit('moveThread', msg)">
                <v-icon style="font-size:16px;">question_answer</v-icon>
              </a>
              <span style="font-size: 10px; margin:0 3px; width:53px; ">{{ msg.str_send_date }}</span>
            </div>

            <div v-if="checkMsgType" class="my-message mychat-content" @contextmenu="$emit('rightClick', $event, '', 'todo')">
              <pre v-html="textbyFilter(msg.content)"></pre>

              <div v-if="msg.thread && mainThread" :class="{blink: msg.check}">
                <div block plain outlined class="threadBtn" @click="$emit('moveThread', msg)">
                  <v-icon style="color: red; font-size: 1em; padding: 0 0.2em 0.2em 0;">trip_origin</v-icon>
                  <b style="color: #2b5be2; margin-right: 0.3em;">{{ msg.thread_count }}개의 쓰레드</b><br/><span style="margin: 0 0.2em; font-size: 0.8em; color: #505050;">최근 시간 : {{ latest }}</span>
                </div>
              </div>

            </div>
            <div v-if="checkFileType" class="my-message mychat-content">
              <b-row>
                <b-col v-for="(file,index) in msg.files" :key="index">
                  <a @click="fileDownload(file)">
                    <div class="hori-align">
                      <b-img :src="selectImage(file)" @error="$emit('imgLoad', $event)" @load="$emit('imgLoad',$event)" style="max-width:100px; max-height:100px;"></b-img>
                    </div>
                    <!-- <b-img thumbnail rounded fluid  alt="이미지를 찾을 수 없습니다."
                           style="max-width: 200px" ></b-img> -->
                    <p class="file-name"><b>{{file.original_name}}</b></p>
                    <p style="margin:0px;">{{formatBytes(file.file_size)}}</p>
                  </a>
                </b-col>
              </b-row>

              <div v-if="msg.thread && mainThread" :class="{blink: msg.check}">
                <div block plain outlined class="threadBtn" @click="$emit('moveThread', msg)">
                  <v-icon style="color: red; font-size: 1em; padding: 0 0.2em 0.2em 0;">trip_origin</v-icon>
                  <b style="color: #2b5be2; margin-right: 0.3em;">{{ msg.thread_count }}개의 쓰레드</b><br/><span style="margin: 0 0.2em; font-size: 0.8em; color: #505050;">최근 시간 : {{ latest }}</span>
                </div>
              </div>

            </div>
          </slot>
        </div>
        <div class="msg-og-container-r" v-if="getUrlList.length>0">
          <v-card class="msg-og-cardsize" @click="windowOpen()">
            <v-img :src="getUrlList[0].og_image" class="msg-og-imgsize"
                   style="height: 100px; width: 15vw;min-width: 150px;max-width: 350px;" eager
                   @load="$emit('imgLoad',$event)"/>
            <v-card-text>
              <div style="color: black;" class="txt-ellipsis">{{getUrlList[0].og_title}}</div>
              <div class="txt-ellipsis">{{getUrlList[0].og_description}}</div>
            </v-card-text>

          </v-card>
        </div>
        <!-- 채팅메시지내용끝 -->
      </div>
    </li>
  </div>
</template>
<script>
  import CommonClass from "../../service/common";

  const urlRegexp = /(http(s)?:\/\/|www.)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}([\:\/a-z0-9-%@#?&=\w+])+([\/\.a-z0-9]+(\?)*[\/a-z0-9-%@#?&=\w+]+)*/g

  export default {
    name: 'MsgBox',
    props: {
      msg: '',
      mainThread: false
    },
    data() {
      return {
        isMsgOption: false,
        urlList: [],
      }
    },
    computed: {
      getUrlList: function(){
        if (this.msg.delete_yn=='N'){
          return this.urlList
        }else{
          return []
        }
      },
      isMsgByLoginUser: function () {
        return this.msg.sender == this.currentUser.email
      },
      checkMsgType: function () {
        return this.msg.message_type == 'message' || this.msg.delete_yn == 'Y'
          || this.msg.message_type == 'translate' || this.msg.message_type == 'url'
      },
      checkFileType: function () {
        return this.msg.message_type == 'file' && this.msg.delete_yn == 'N'
      },
      bgcolor: function() {
        if(this.msg.content.level == 'Critical' || this.msg.content.metricName? this.msg.content.metricName.includes('error') : false) {
          return 'rgb(242, 80, 34)'
        } else {
          return 'rgb(255, 185, 2)'
        }
      },
      changeDate: function() {
        let dt = new Date(Number(this.msg.content.time))
        let year = dt.getFullYear()
        let month = dt.getMonth() + 1
        month = month > 9 ? month : ('0' + month)
        let day = dt.getDate()
        day = day > 9 ? day : ('0' + day)
        let hour = dt.getHours()
        hour = ('0' + hour).slice(-2)
        let min = dt.getMinutes()
        min = ('0' + min).slice(-2)
        let sec = dt.getSeconds()
        sec = ('0' + sec).slice(-2)
        return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + sec
      },
      changeIcon: function() {
        let metricName = this.msg.content.metricName
        let icon = 'warning'
        if(metricName){
          if(metricName.includes('cpu')) {
            icon = 'computer'
          } else if(metricName.includes('memory')) {
            icon = 'memory'
          } else if(metricName.includes('disk')) {
            icon = 'save'
          } else if(metricName.includes('transaction')) {
            if(metricName.includes('active')) {
              icon = 'swap_horiz'
            } else if(metricName.includes('slow')) {
              icon = 'moving'
            } else if(metricName.includes('error')) {
              icon = 'shuffle'
            }
          }
        }
        return icon
      },
      changeName: function() {
        let metricName = this.msg.content.metricName;
        let title = metricName ? metricName.replaceAll('_', ' ').toUpperCase() : this.msg.content.title
        return title
      },
      checkPrgsbar: function() {
        if(!this.msg.content.metricName){
          return false;
        }
        return this.msg.content.metricName.includes('transaction') ? false : true
      },
      latest: function() {
        if((new Date().getTime() - (24 * 60 * 60 * 1000)) > this.msg.latest_date) {
          return this.msg.str_latest_date
        } else {
          return this.$moment(this.msg.latest_date).locale('ko').format('a hh:mm')
        }
      }
    },
    created() {
      this.makeUrlThumbnail()
      if(this.msg.api && this.msg.delete_yn == 'N') {
        this.msg.content = JSON.parse(this.msg.content.replaceAll('&quot;', '"'))
      }
    },
    methods: {
      windowOpen: function () {
        window.open(this.urlList[0].og_url)
      },
      formatBytes: function (byte) {
        return CommonClass.formatBytes(byte)
      },
      showMsgOption: function (msgId) {
        if (this.msg.delete_yn === 'N' && (this.isMsgByLoginUser || this.isAdmin())) {
          this.hideMsgOption(msgId)
          this.$refs['confirmMsgDel' + msgId].style.visibility = 'visible'
        }
        if (this.msg.delete_yn === 'N') {
          this.$refs['thread' + msgId].style.visibility = 'visible'
        }
      },
      hideMsgOption: function (msgId) {
        document.querySelectorAll('.confirmMsgDel').forEach((v,i) => {
            document.querySelectorAll('.confirmMsgDel')[i].style.visibility = 'hidden'
        })
        this.$refs['confirmMsgDel' + msgId].style.visibility = 'hidden'
        this.$refs['thread' + msgId].style.visibility = 'hidden'
      },
      textbyFilter: function (content) {
        // const tagContentRegexp = new RegExp(/<p(.*?)>(.*?)<\/p>/g);
        // const htmlTagRegexp = new RegExp(/(<([^>]+)>)/ig);
        let result = '';
        if (this.$store.state.searchText == '') {
          let arr = content.match(urlRegexp)
          if (arr != null) {
            content = '<p>' + content + '</p>'
            arr = new Set(arr)
            arr.forEach(contentItem => {
              // 아래 코드 한줄은 어떤 용도인지? 에러떠서 주석
              // contentItem = contentItem.replace(htmlTagRegexp, '')
              // http:// or https:// 없는 경우 강제로 http:// 추가하고 있으나 변경 작업 필요... meta 서버 콜 & http https 체크 겸...
              if(!/http(s)?:\/\//.test(contentItem)) {
                contentItem = "http://" + contentItem
              }
              // 같은 url을 두개 넣으면 에러
              result = "<a class='msgbox-color' href='" + contentItem + "' target='_blank'>" + contentItem + "</a>"
              // let replaceItem = contentItem.replace('?', '\\?')
              // let replaceRegExp = new RegExp(replaceItem, "g")
              // content = content.replaceAll(replaceRegExp, result)
              // 210205 수정
              content = content.replaceAll(contentItem, result)
            });
            return content
          } else {
            return content
          }
        }
        return this.$options.filters.highlight(content, this.$store.state.searchText);
      },
      selectImage: function (file) {
        return CommonClass.checkFileType(file)
      },
      fileDownload: function (file) {
        this.$http.get("/api/file/download/" + file.server_name, {
          responseType: 'blob'
        })
          .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url;
            link.setAttribute('download', file.original_name)
            document.body.appendChild(link)
            link.click()
            link.remove()
            window.URL.revokeObjectURL(url)
          })
      },
      makeUrlThumbnail: function () {
        let content = this.msg.content
        if (content == null) {
          return
        }
        let arr = content.match(urlRegexp)
        if (arr != null) {
          arr.forEach(urlString => {
            this.$http.post('/api/message/url_thumb', {
              url: urlString
            }).then(res => {
              if (Object.keys(res.data).length) {
                this.urlList.push(res.data)
                // Vue.set(this.urlList, 'b', res.data)
              }
            }).catch(error =>{
               console.error(error)
            })
          })
        }
      },
      moveLink: function() {
        window.open(`${this.msg.api.app.url}v2/project/${this.msg.content.projectName}/${this.msg.content.pcode}/cube2?type=oid&oid=${this.msg.content.oid}&time=${this.msg.content.time}&level=${this.msg.content.level}`)
      },
    },
    filters: {
      highlight: function (stringToSearch, searchTerm) {
        if (searchTerm === "") return stringToSearch;
        var iQuery = new RegExp(searchTerm, "ig");
        return stringToSearch
          .toString()
          .replace(iQuery, function (matchedText, a, b) {
            return "<span class='highlight'>" + matchedText + "</span>";
          });
      }
    }
  }
</script>
<style>
.mychat-content > .row > .col> a{
  color:#212529 !important;
}
.msgbox-color{
    color:blue !important;
}
</style>
<style lang="scss" scoped>
  @import "@/assets/css/common.scss";

  .msg-og-container-r {
    @extend .myflex;
    @extend .msgflex-end;
    margin-top: 5px;
  }

  .msg-og-container-l {
    margin-top: 5px;
  }

  .msg-og-cardsize {
    width: 15vw;
    min-width: 150px;
    max-width: 350px;
  }

  .msg-og-imgsize {
    @extend .msg-og-cardsize;
    height: 100px;
  }

  .urMsg {
    margin-bottom: 0.3em;
  }

  .apiName {
    display: inline-flex;
    margin: 0 1em;
    font-size: 1.4em;
  }

  .apiProg {
    margin: 0.5em 0;
  }

  .apiMsg {
    font-size: 1.4em;
    margin-bottom: 0.2em;
  }

  .apiBtn {
    margin-top: 0.8em;
    color: white !important;
  }

  .threadBtn {
    cursor: pointer;
    margin-top: 0.8em;
    color: black !important;
  }
  .blink {
    -webkit-animation: blink 0.5s ease-in-out infinite alternate;
    -moz-animation: blink 0.5s ease-in-out infinite alternate;
    animation: blink 0.5s ease-in-out infinite alternate;
  }
  @-webkit-keyframes blink {
    0% {background: none;}
    100% {background: #cc9dff;}
  }
  @-moz-keyframes blink {
    0% {background: none;}
    100% {background: #cc9dff;}
  }
  @keyframes blink {
    0% {background: none;}
    100% {background: #cc9dff;}
  }
</style>
