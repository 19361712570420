import {mapGetters} from "vuex";
import CommonClass from '../service/common'

let messageMixin = {
  data() {
    return {
      translate: false,
      isFileUpload: false,
      progressValue: 0,
      sendMail: false,
      tempImg: '',
      stringByteLength: 0,
      previewObj: {
        content: '',
        username: ''
      },
      selectedUserEmail: '',

      threadLimit: 5,
      threadPage: 0,
    }
  },
  computed: {
    ...mapGetters({
      msgArray: 'getMsgArray',
      currentChannel: 'getCurrentChannel',
      currentUser: 'getCurrentUser',
      channelList: 'getChannelList',
      wrapperEl: 'getWrapperEl',
      cursorPoint: 'getCursorPoint',
      oldScrollHeight: 'getOldScrollHeight',
    })
  },
  methods: {
    msgCountUpdate(id, counting) {
      // commit 을 안해도 객체 내부의 내용은 변경이 되는지 확인 필요 확인 후 해당 주석 제거
      for (let i = 0; i < this.channelList.length; i++) {
        if (id == this.channelList[i].id) {
          if (counting) {
            this.msgCounting(i)
            break
          } else {
            this.msgCountReset(i)
            break
          }
        }
      }
      // 210518 not focus, change favicon + title(tab alarm) + bell sound
      if(!this.$store.getters.getIsfocus && counting) {
        document.getElementById('bell').play()


        document.getElementById('favicon').href = '/faviconN.ico'
        this.$store.commit('setAlarmCount', this.$store.getters.getAlarmCount + 1)
        document.title = `(${this.$store.getters.getAlarmCount }) HAMAA`


      }
    },
    msgCounting(i) {
      this.channelList[i].count += 1
    },
    msgCountReset(i) {
      this.channelList[i].count = 0
    },
    //채널 메시지 초기화
    initMessageList: function (channel) {
      this.$store.commit('pushChannelArr',channel.id)
      this.$store.commit('setFirstLoad',true)
      this.scrollHeight = 0
      this.message.sender = this.currentUser.email
      this.$store.state.isInviteMode = false
      this.$store.state.isSearchMode = false
      this.commit('setMsgArray', [])
      this.message.channel_id = channel.id
      this.cursorPoint.channel_id = channel.id
      this.cursorPoint.first = true
      this.cursorPoint.cursorId = 0
      this.cursorPoint.empty = false
      this.$store.commit('setIsGetMsgForImgLoad',false)
      this.$store.commit('setIsGetMsgForPreview',false)
      this.$store.commit('setScrollPosition',0)
      this.$store.commit('setIsUpScroll',false)
    },
    //채널 메시지 조회
    selectMessageList: function (channel, isInit, scrollToEnd, isThread) {
      if (isInit) {
        this.initMessageList(channel)
      }
      this.$http.post('/api/message/getmsg', JSON.stringify(this.cursorPoint), {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        if (channel.id == this.channelArr[this.channelArr.length - 1]) {
          if (res.data.length == 0) {
            this.cursorPoint.empty = true
          } else {
              if(this.cursorPoint.first){
                this.$store.commit('setIsGetMsgForImgLoad',true)
              }else{
                if(scrollToEnd){
                  this.$store.commit('setIsGetMsgForImgLoad',true)
                }else{
                  this.$store.commit('setIsGetMsgForImgLoad',false)
                }
              }
            this.cursorPoint.first = false
            this.cursorPoint.cursorId = res.data[res.data.length - 1].id
          }
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].delete_yn == 'Y') {
              res.data[i].content = '<p class="deletemsg">삭제된 메세지입니다.</p>'
            }
          }
          this.commit('setMsgArray', res.data.reverse().concat(this.msgArray))
          this.$store.commit('setIsGetMsgForPreview',true)

          if (this.wrapperEl !== undefined) {
            this.$nextTick(() => {
              //추가됨
              if (this.wrapperEl == null) {
                this.$store.commit('setWrapperEl', document.querySelector('.c-c-wrapper'))
              }
              // 210612 쓰레드 스크롤 올릴때 기존 채팅 스크롤 올라가는 버그 수정
              if(!isThread) {
                this.wrapperEl.scrollTop = this.wrapperEl.scrollHeight - this.oldScrollHeight
                this.$store.commit('setOldScrollHeight',this.wrapperEl.scrollHeight)
              }
              // while , await관련 코드로 가능하면 바꾸기
              if(this.wrapperEl.clientHeight == this.wrapperEl.scrollHeight && !this.cursorPoint.empty){
                this.$store.commit('setIsGetMsgForImgLoad',true)
                this.selectMessageList(channel,false,true)
              }
            })
          }
        }
      })
    },
    translateMessage: async function (message) {
      await this.$http.post('/api/message/translate',{
        text: !!message ? message : this.message.content,
        src_lang: 'kr',
        target_lang: 'en'
      }).then(res=>{
        let translateText = res.data
        if(!!message) {
          return message + "\n" + translateText
        } else {
          this.message.content += "\n"+translateText
        }
      }).catch(error =>{
        console.error(error)
      })
    },
    messageTypeCheck: function(){

    },
    //채널 메시지 전송
    sendMessage: async function (e, isSysMsg) {
      if (e != null) {
        e.preventDefault()
      }
      if (this.message.content == '') {
        return;
      }
      if (isSysMsg) {
        this.message.message_type = 'action'
        this.message.sender = null
      } else {
        this.message.sender = this.$store.state.currentUser.email
        this.message.user = this.$store.state.currentUser
        this.message.message_type = 'message'
      }
      if (this.translate) {
        await this.translateMessage()
        this.message.message_type = 'translate'
      }

      this.message.channel_id = this.$store.state.currentChannel.id
      if (CommonClass.byteLimit(this.stringByteLength)) {
        if (this.$store.state.stompClient && this.$store.state.stompClient.connected) {
          this.$store.state.stompClient.send("/pub/chat/message", JSON.stringify(this.message), {})
          this.message.content = ''
          this.scrollToEnd(true)

          if (this.sendMail) {
            this.$store.state.channelUsers.filter(channelUser => channelUser != this.$store.state.currentUser)
              .forEach(channelUser => {
                this.$http.post('/api/message/send/mail', {
                  channelName: this.$store.state.currentChannel.name,
                  fromUser: this.$store.state.currentUser.name,
                  toUser: channelUser.email
                })
                  .then(res => {
                    this.sendMail = false
                  })
              })
          }
        } else {
          // this.message.content = CommonClass.replaceErrorMsg(this.message.content)
          this.message.content = '<p style="color:red;">메세지 전송에 실패하였습니다.</p>' + this.message.content
          let errormsg = JSON.parse(JSON.stringify(this.message))
          this.$store.commit('pushMsg', errormsg)
          this.message.content = ''
        }
      }
    },confirmDelete: function(msg){
      if(msg.api) {
        delete msg.content
      }
      this.$_confirm("메세지를 삭제하시겠습니까?",this.deleteMessage,msg)
    },
    //채널 메시지 삭제
    deleteMessage: function (msg) {
      this.$http.post('/api/message/update/deleteyn', msg).then(res => {
        if (res) {
          this.currentChannel.send("deleteMsgFromArr|" + msg.id)
        }
      });
    },
    deleteMsgFromArr: function (id) {
      let index = this.msgArray.findIndex(message => message.id == id)
        if(index !== undefined || index !== null){
          this.msgArray[index].content = "<p class='deletemsg'>삭제된 메세지입니다.</p>"
          this.msgArray[index].delete_yn = "Y"
        }
    },
    sendThreadMsg: function(msg) {
      this.currentChannel.send("haveThreadMsgBox|" + JSON.stringify(msg))
    },
    haveThreadMsgBox: function(msg) {
      setTimeout(() => {
        let parseMsg = JSON.parse(msg)
        if(this.msgArray.length > 0) {
          let index = this.msgArray.findIndex(message => message.id == parseMsg.thread)
          if(index !== undefined || index !== null) {
            this.msgArray[index].thread = parseMsg.thread
            ++this.msgArray[index].thread_count
            this.msgArray[index].str_latest_date = this.$moment(new Date()).locale('ko').format('a hh:mm')

            // this.msgArray[index].check = true

            this.$http.post('/api/message/getThreadList', {
              channel_id: this.currentChannel.id,
              limit: this.threadLimit,
              page: this.threadPage
            }).then(res => {
              this.$store.commit('setThreadMsg', res.data)
            })
          }
        }
      }, 500)
    }
  }
};
export default messageMixin;
