<template>
  <div>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list dense>
        <template v-for="(menu, index) in setMenus">
          <v-list-item v-if="!menu.hideTask && authorityCheck(menu.auth)" @click="callFunction(menu.text)" :key="index">
            <v-list-item-title>
              <v-icon>{{ iconMatch[menu.text]}}</v-icon> {{ setMenuTitle(menu, 'exit') }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
    <v-dialog v-model="dialog" max-width="60em" hide-overlay>
      <v-row justify="end" align="end"><v-icon @click="closeDialog">close</v-icon></v-row>
      <UserInfo style="background-color: white" v-if="itemType=='user'" :relatedUser="item" :rightClickChk="true"></UserInfo>
      <ChannelInfo v-else-if="itemType=='channel'" :channel="item"></ChannelInfo>
      <TaskAddInChat v-else-if="itemType=='todo'" :msg="item" ></TaskAddInChat>
      <ChannelSettings v-else-if="itemType=='channelsettings'" :info="item" @closeDialog="closeDialog()"></ChannelSettings>
    </v-dialog>
  </div>
</template>

<script>
  import rightClickMetadata from '../../json/rightClickMenuMetadata.json'
  import UserInfo from "../user/UserInfo"
  import ChannelInfo from "../main/ChannelInfo"
  import TaskAddInChat from "../todolist/TaskAddInChat"
  import ChannelSettings from "@com/ChannelSettings";

  export default {
    name: "RightClickMenu",
    components: {
      ChannelInfo, 
      UserInfo, 
      TaskAddInChat,
      ChannelSettings,
    },
    data() {
      return {
        dialog: false,
        event: undefined,
        itemType: undefined,
        iconMatch: rightClickMetadata.iconMatch,
        menuList: {},
        item: undefined,
        showMenu: false,
        x: 0,
        y: 0
      }
    },
    created() {
    },
    mounted() {
    },
    computed: {
      setMenus: function() {
        // file인 경우 todo list버튼 숨기기
        if(!!this.item && this.item.message_type == 'file') {
          this.menuList.forEach(menu => {
            if(menu.text == 'task') {
              menu.hideTask = true
            }
          })
        }
        if(!!this.menuList.length) {
          return this.menuList.filter(menu => (menu.text != 'Exit' || (this.item && this.item.email == this.currentUser.email && menu.text == 'Exit') || this.isAdmin()))
        }
      }
    },
    methods: {
      authorityCheck: function (auth){
        if (auth =='user'){
          return this.currentUser.roles.includes('ROLE_USER') || this.isAdmin()
        }
        if (auth == 'admin'){
          return this.isAdmin()
        }
      },
      closeDialog: function (){
        let presentDialog = this.dialog
        this.dialog = false
        this.itemType = undefined
        this.item = undefined
        return new Promise((resolve, reject) => {
          resolve(presentDialog)
        })
      },
      callFunction(itemName) {
        this[this.itemType + itemName](this.item)
      },
      show: function (e, item, type) {
        e.preventDefault()
        this.event = e
        this.itemType = undefined
        this.item = undefined
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        this.$nextTick(() => {
          this.itemType = type
          this.item = item
          this.typeCheck()
          this.showMenu = true
        })
      },
      typeCheck: function () {
        switch (this.itemType) {
          case "channel":
            this.menuList = rightClickMetadata.channel
            return;
          case "user":
            this.menuList = rightClickMetadata.user
            return;
          case "todo":
            this.menuList = rightClickMetadata.todo
            return;
          default:
            return;
        }
      },
      setMenuTitle(menu, resultText) {
        if(menu.text == resultText) {
          if(this.item && this.currentUser.email == this.item.email) {
            return this.$root.currentLang.rightclick[resultText]
          } else {
            return this.$root.currentLang.rightclick['ban']
          }
        } else {
          return this.$root.currentLang.rightclick[menu.text]
        }
      },

      channeledit: function (channel) {
        // 아래의 현재 채널을 이동하는 코드는 임시코드
        // 현재 채널 정보를 업데이트하는 함수 confirmChannelExec 에서
        // currentChannel를 가지고 업데이트 함
        // 추후 가져온 channel을 confirmChannelExec에 전달한 좋은 방식이 떠올 때까지의 임시코드
        this.$store.commit('setCurrentChannel',channel)
        this.confirmChannel(this.event, 'update', channel)
      },
      channeldelete: function (channel){
        this.confirmChannel(this.event, 'delete', channel)
      },
      channelinfo: function(channel){
        this.dialog = true
      },
      channelsettings: function(channel) {
        this.itemType = 'channelsettings'
        this.dialog = true
      },

      userinfo: function (user) {
        this.dialog = true
      },
      userexit: function(user) {
        this.confirmChannelForceLeave(user)
      },
      todotask: function(msg) {
        this.dialog = true
      },
      todothread: function(msg) {
        this.$emit('openThread', msg)
      }
    }
  }
</script>

<style scoped>

</style>
