<template>
  <div>
    <aside class="right-sidebar">
      <div v-if="!this.threadInfo.isThread" class="sidebar-chat" data-plugin="chat-sidebar">
        <div class="sidebar-chat-info" style="margin: 16px 0px;display:flex;">
          <h6>{{ currLng.dashboard.channelInfo }}</h6>
          <li @click="RSidebarClose" class="list-unstyled"
              style="flex-grow: 1;display: flex;justify-content: flex-end;align-items: center;font-size: 20px;margin-bottom: 8px;">
            <i class="ik ik-x close-card" style="cursor: pointer;"></i>
          </li>
        </div>
        <div class="chat-list">
          <div class="list-group row">
            <!-- 화상 채닝 메뉴 시작 -->
            <a class="list-group-item" v-b-toggle.video-chat>
              <i class="im im-video-camera"></i>
              <span style="margin-left:20px;">{{ currLng.common.menu.video }}</span>
              <div style="display: flex; flex-grow: 1; justify-content: flex-end;">
                <i class="im im-care-down" style="font-size: 15px;"></i>
              </div>
            </a>
            <b-collapse id="video-chat">
              <div class="s-coll-style">
                <p>{{ currLng.common.txt.label.videoChatUsers }} : {{userPerRoom}}</p>
                <v-btn color="blue-grey" class="white--text" @click="toggleVideoMode()" v-if="!isVideoMode">
                  {{ currLng.common.btn.join }}
                  <v-icon right dark class="my-video">play_circle_outline</v-icon>
                </v-btn>
                <v-btn v-else color="blue-grey" class="white--text" @click="toggleVideoMode">
                  {{ currLng.common.btn.leave }}
                  <v-icon right dark class="my-video">exit_to_app</v-icon>
                </v-btn>
              </div>
            </b-collapse>

            <!-- 화상 채팅 메뉴 끝 -->
            <!-- to do list 메뉴 시작 -->
            <a class="list-group-item" @click="callComponentAndSideBarClose('todoList')">
              <i class="im im-task-o"></i>
              <span style="margin-left:20px;">{{ currLng.dashboard.todolist }}</span>
            </a>
            <!-- to do list 메뉴 끝 -->
            <!-- calender 메뉴 시작 -->
            <a class="list-group-item" @click="callComponentAndSideBarClose('calendar')">
              <i class="im im-calendar"></i>
              <span style="margin-left:20px;">{{ currLng.dashboard.calendar }}</span>
            </a>
            <a class="list-group-item" @click="callComponentAndSideBarClose('fileDrawer')"
               v-if="channelFiles.length > 0">
              <i class="im im-files-o"></i>
              <span style="margin-left:20px;">{{ currLng.common.menu.files }}</span>
            </a>
            <b-collapse id="files" visible v-if="channelFiles.length > 0">
              <!--파일이 3개보다 작을때 테스트 필요 -->
              <v-row
                style="width: 320px;padding: 0 1.07143em 1em 1.07143em; margin: 0px;"
                align="center"
                dense
              >
                <v-col
                  @click="$refs.FilePreview.show(file)"
                  v-for="file in getChannelFiles"
                  :cols="4" :key="file.id"
                  style="cursor: pointer;"
                >
                  <div style="height: 100px; background-color: #E0E0E0;" class="cetered-align" v-bind:title="file.original_name" >
                    <v-img :src="selectImage(file,'tiles')" style="cursor: pointer;"
                           :height="selectImage(file).includes('/api/file/download')? 'inherit' : 25"
                           :width="selectImage(file).includes('/api/file/download')? 'auto' : 25"
                           contain></v-img>
                    
                  </div>
                </v-col>
              </v-row>
            </b-collapse>
            <!-- calender 메뉴 끝 -->
            
            <!-- 링크 리스트 -->
            <a class="list-group-item" @click="callComponentAndSideBarClose('LinkList')">
              <i class="im im-link"/>
              <span style="margin-left:20px;">{{ currLng.common.menu.links }}</span>
            </a>
            <v-col v-for="link in getChannelLinks" :key="link.id">
              <div style="height: 1.3rem; background-color: #E0E0E0;">
                <a style="display: inline-block;height: 1.3rem; white-space: nowrap;width: -webkit-fill-available;overflow: hidden;" v-bind:href="link.content">{{ link.content }}</a>
              </div>
            </v-col>

            <!-- 쓰레드 리스트 -->
            <template v-if="getThreadList.length > 0">
              <a class="list-group-item"> 
                <v-icon>forum</v-icon>
                <span style="margin-left:20px;">{{ currLng.common.menu.thread }}</span>
              </a>
              <div style="overflow: auto; height: calc(100vh - 700px); width: 98%;">
                <v-col v-for="thread in getThreadList" :key="thread.id" @click="$emit('moveThread', thread, true)" style="padding: 0.5em 1em; cursor: pointer;">
                  <div>
                    <v-chip class="ma-2" color="#dec1bf" text-color="black" style="font-size: 12px; padding: 0.5em; height: 19.1px; margin: 0.2em !important;" v-html="makeThread(thread)"></v-chip>
                    <br/>
                    <img onError="this.src='/img/default-user-picture.png'" class="icon-round" :src="thread.user.picture" width="25" height="25"/>
                    <div style="display: inline-block; padding: 4px 0px 0px 0.5em; max-width: 75%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; height: 20px;" :title="thread.user.name">
                      <strong>{{ thread.user.name }}</strong>
                    </div>
                  </div>
                  <div v-if="!checkFileThread(thread)" class="message">
                    {{ thread.content }}
                  </div>
                  <div v-else class="message">
                    <div class="hori-align" style="margin: 5px 0 0 0;">
                      <b-img :src="selectImage(thread.files[0])" onError="this.src='/img/file_icon.png'" style="max-width:100px; max-height:100px;"></b-img>
                    </div>
                    <p class="file-name" :title="thread.files[0].original_name" style="margin: 5px 0 5px 0 !important;"><b>{{ thread.files[0].original_name }}</b></p>
                  </div>
                  <span style="font-size: 10px; margin:0 0 0 3px; width:53px;">{{ remakeSendDate(thread.send_date) }}</span>
                </v-col>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div v-else class="sidebar-chat" data-plugin="chat-sidebar">
        <div class="sidebar-chat-info" style="margin: 16px 0px;display:flex;">
          <h6>쓰레드</h6>
          <li @click="RSidebarClose" class="list-unstyled"
              style="flex-grow: 1;display: flex;justify-content: flex-end;align-items: center;font-size: 20px;margin-bottom: 8px;">
            <i class="ik ik-x close-card" style="cursor: pointer;"></i>
          </li>
        </div>
        <div class="chat-list" style="height: 100vh;">
          <ThreadChat :threadInfo="threadInfo" chatHeight="height: calc(100% - 90px);"></ThreadChat>
        </div>
      </div>
    </aside>
    <FilePreview ref="FilePreview"></FilePreview>
  </div>
</template>

<script>
  import CommonClass from "../../service/common";
  import FilePreview from "../util/FilePreview";
  import RTCMultiConnection from 'rtcmulticonnection';
  import * as io from 'socket.io-client'

  import ThreadChat from './ThreadChat'

  export default {
    components: {
      FilePreview,
      ThreadChat,
    },
    name: 'RSidebar',
    props: {
      threadInfo: {
        isThread: false,
        threadId: '',
      },
    },
    computed: {
      getChannelFiles: function () {
        return this.channelFiles.slice(0, 3)
      },
      getChannelLinks: function () {
        return this.channelLinks.slice(0, 3)
      },
      getThreadList: function() {
        return this.$store.getters.getThreadMsg
      },
    },
    watch: {
      currentChannel: function(val) {
        this.$store.commit('setThreadMsg', [])
        this.$http.post('/api/message/getThreadList', {
          channel_id: this.currentChannel.id,
          limit: this.threadLimit,
          page: this.threadPage
        }).then(res => {
          this.$store.commit('setThreadMsg', res.data)
        })
      }
    },
    data() {
      return {
        videoChatUsers: 0,
        channelUserSize: 0,
        userSelect: null,
        publicRoomIdentifier: "nineone",
        userPerRoom : 0,

        threadLimit: 5,
        threadPage: 0,
      }
    },
    mounted() {
    // 화상회의 중인 참가자 수
    //TEST
    var that = this;
      window.io = io
      var leng = 0
      var publicRoomIdentifier = this.publicRoomIdentifier;
      var connection = new RTCMultiConnection()
      connection.socketURL = 'https://91cm.nineonesoft.com:9002/'

      /// make this room public
      connection.publicRoomIdentifier = publicRoomIdentifier;
      connection.socketMessageEvent = publicRoomIdentifier;

      // keep room opened even if owner leaves
      connection.autoCloseEntireSession = true;

      connection.connectSocket(function(socket) {
          looper();

          socket.on('disconnect', function() {
              location.reload();
          });
      });

      function looper() {
          connection.socket.emit('get-public-rooms', publicRoomIdentifier, function(listOfRooms) {
              updateListOfRooms(listOfRooms);
              setTimeout(looper, 3000);
          });
          function updateListOfRooms(rooms) {
              if (rooms.length == 0) {
                that.userPerRoom = 0;
              } else {
                var room = rooms.find(function(data){return data.sessionid == that.currentChannel.id})
                if (room) {
                  that.userPerRoom = room.participants.length
                } else {
                  that.userPerRoom = 0;
                }

              }
            // rooms.forEach(function(room, idx) {
            //   if(that.currentChannel.id == room.sessionid) {
            //     that.userPerRoom = room.participants.length // 접속한 인원
            //   }
            // })
          }
      }
      
      // test end
    },
    methods: {
      selectImage: function (file, option) {
        return CommonClass.checkFileType(file, option)
      },
      toggleVideoMode: function () {
        this.$store.commit('setIsVideoMode', !this.isVideoMode)
        this.callComponentAndSideBarClose('main', true)

        if(!this.isVideoMode) {
          this.$store.commit('setShowBool', true)
          this.$store.commit('setChangeCss', true)
        }
      },
      callComponentAndSideBarClose: function (componentName, bool) {
        this.RSidebarClose()
        this.callComponent(componentName, bool)
        if (!bool) {
          this.$store.commit('setIsVideoMode', false)
        }
      },
      RSidebarClose: function () {
        this.$emit('checkRS')
        // $('.right-sidebar-toggle')[0].classList.toggle('active');
        // $('.wrapper').removeClass('right-sidebar-expand');
        // $('.main-content').removeClass('rsidebar-padding-right')
        // this.$store.state.isRActive = false
      },
      useModal: function (mode) {
        if (mode == 'edit') {
          this.$eventBus.$emit('useModal', mode)
        } else if (mode == 'delete') {
          this.$eventBus.$emit('useModal', mode)
        }
      },
      msgBox: async function (content) {
        await this.$bvModal.msgBoxConfirm(content, {
          title: '확인',
          okTitle: '확인',
          okVariant: 'danger',
          buttonSize: 'sm',
          cancelTitle: '취소'
        }).then(value => {
          this.userSelect = value
          return value
        })
      },
      checkFileThread: function(thread) {
        if(thread.message_type == 'file') {
          return true 
        } else {
          return false
        }
      },
      selectImage: function (file) {
        return CommonClass.checkFileType(file)
      },
      fileDownload: function (file) {
        this.$http.get("/api/file/download/" + file.server_name, {
          responseType: 'blob'
        }).then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url;
          link.setAttribute('download', file.original_name)
          document.body.appendChild(link)
          link.click()
          link.remove()
          window.URL.revokeObjectURL(url)
        })
      },
      remakeSendDate: function(date) {
        let today = new Date()
        let thisYear = today.getFullYear()
        let thisMonth = today.getMonth()
        let thisDate = today.getDate()
        
        let msgDay = new Date(date)
        let msgYear = msgDay.getFullYear()
        let msgMonth = msgDay.getMonth()
        let msgDate = msgDay.getDate()

        let pattern
        if(thisYear == msgYear) {
          if(thisMonth == msgMonth && thisDate == msgDate) {
            pattern = 'a hh:mm'
          } else {
            pattern = 'M월 D일 a hh:mm'
          }
        } else {
          pattern = 'YYYY년 M월 D일 a hh:mm'
        }
        return this.$moment(msgDay).locale('ko').format(pattern)
      },
      makeThread: function(thread) {
        let result
        try {
          result = JSON.parse(thread.main_thread.replaceAll('&quot;', '\"')).message
        } catch(e) {
          result = !!thread.main_thread ? thread.main_thread : '<p class="deletemsg">삭제된 메세지</p>'
        }
        return result
      }
    }
  }
</script>

<style scope>
  .wrapper .page-wrap .right-sidebar .sidebar-chat .chat-list .list-group .list-group-item {
    color: #444 !important;
  }
  .message {
    margin: 5px 0 0 0;
    display: inline-block;
    font-family: Roboto,sans-serif;
    overflow: auto;
    white-space: pre-wrap;
    background-color: #e8e8e8;
    padding: 5px 10px;
    border-radius: 10px;
  }
  .deletemsg {
    font-weight: bold;
    color: red;
    margin: 0 !important;
    font-size: 12px !important;
  }
</style>
