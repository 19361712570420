<template>
  <div>
    <div class="wrapper" :class="{'right-sidebar-expand': checkRSidebar}">
      <template v-if="connectionCheck">
        <MainHeader @checkRS="checkRSide" :hideSettings="isVideoMode"></MainHeader>
        <div class="page-wrap">
          <LSidebar @rightClick="rightClickMenu" @changeChannel="closeThread"></LSidebar>
          <div class="main-content" style="padding-bottom:0;" 
          :class="{'disactive-padding': $store.state.selectComponent=='main' || $store.state.selectComponent=='dashboard' || $store.state.selectComponent=='notice', 'rsidebar-padding-right': checkRSidebar}">
            <div :class="{'row': isVideoMode,'no-gutters':isVideoMode}">
              <div id="chatcontainer" :class="{'col': isVideoMode,'col-3': isChatView(isVideoMode,$store.getters.getShowBool)}">
                <keep-alive>
                  <component :is="whichComponent" @rightClick="rightClickMenu" @moveThread="openThread"></component>
                </keep-alive>
              </div>
              <div id="videocontainer" :class="{'col': isVideoMode,'col-9': isVideoView('col-9',isVideoMode,$store.getters.getChangeCss), 'col-12' : isVideoView('col-12',isVideoMode,$store.getters.getChangeCss)}">
                <VideoChat v-if="isVideoMode"/>
              </div>
            </div>
            <RSidebar v-if="$store.state.currentChannel!=null" @checkRS="checkRSide" :threadInfo="threadInfo" @moveThread="openThread"></RSidebar>
          </div>
          <footer class="footer">
            <div class="w-100 clearfix">
              <span class="text-center text-sm-left overline d-md-inline-block">Copyright © 2021 Nineonesoft.</span>
              <v-dialog
                        v-model="dialog"
                        hide-overlay
                        transition="dialog-bottom-transition"
                      >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon  x-small v-bind="attrs" v-on="on" >
                    <v-icon style="font-size:0.9rem;">im-info</v-icon></v-btn></template>
                      <v-card style="width: 50% !important; margin:auto; padding: 0px; margin: 0px;">
                      <v-toolbar
                            dark
                            
                          >
                        <v-btn
                              icon
                              dark
                              @click="dialog = false"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>OpenSource License{{dialog}}</v-toolbar-title>
                            <v-spacer></v-spacer>
                          </v-toolbar>
                    <CopyRight :dialog="dialog"></CopyRight>
                      </v-card>
              </v-dialog>
            </div>
          </footer>
        </div>
      </template>
      <Loading v-else/>

    </div>
    <!-- 전체 공지용 스낵바 modal -->
    <v-snackbar v-model="noticeMsgToggle" :timeout='5000' :top="true"
                style="margin-top: 8vh; font-size: medium;"
                color="#404E67">
      {{ noticeMsg }}
      <v-btn icon color="white" @click="noticeMsgToggle = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>

    <RightClickMenu ref="menu" class="top-position" @openThread="openThread"></RightClickMenu>
  </div>
</template>
<script>
import LSidebar from '../views/main/LSidebar'
import RSidebar from '../views/main/RSidebar'
import MainHeader from '../views/main/MainHeader'
import ContentWrapper from '../views/main/ContentWrapper'
import AboutChannel from '../service/aboutchannel'
import NotificationClass from '../service/notification'
import EventListener from '../service/eventlistener'
import UserInfo from "../views/user/UserInfo"
import EditProfile from "../views/user/EditProfile"
import ChannelHeader from "../views/main/ChannelHeader"
import NoChannel from '../views/main/NoChannel'
import Loading from '../views/main/Loading'
import Stomp from "webstomp-client"
import SockJS from "sockjs-client"
import TodoList from '../views/todolist/TodoList'
import Calendar from "../views/calendar/Calendar"
import AdminPage from "../views/admin/AdminPage"
import VideoChat from "./VideoChat"
import FileDrawer from "./FileDrawer"
import Dashboard from '../views/dashboard/Dashboard'
import Notice from '../views/dashboard/Notice'
import CopyRight from '../views/util/CopyRight'
import RightClickMenu from "../views/util/RightClickMenu"

export default {
  name: 'Main',
  components: {
    'MainHeader': MainHeader,
    'LSidebar': LSidebar,
    'RSidebar': RSidebar,
    'ChannelHeader': ChannelHeader,
    'ContentWrapper': ContentWrapper,
    'UserInfo': UserInfo,
    'EditProfile': EditProfile,
    'NoChannel': NoChannel,
    'Loading': Loading,
    'TodoList': TodoList,
    'Calendar': Calendar,
    'AdminPage': AdminPage,
    'VideoChat': VideoChat,
    'FileDrawer': FileDrawer,
    'Dashboard': Dashboard,
    'Notice': Notice,
    'CopyRight': CopyRight,
    'RightClickMenu': RightClickMenu,
    
  },
  data() {
    return {
      noticeMsgToggle: false,
      noticeMsg: '',
      channelTitle: '',
      isRActive: false,
      modalObj: {modalTitle: '', currentChannel: null},
      dialog: false,
      checkRSidebar: false,
      threadInfo: {
        isThread: false,
        threadId: '',
        threadContent: '',
      },
    }
  },
  computed: {
    whichComponent() {
      // 210123 whichComponent 내 변수(this.$store.state.oldComponent 등)가 변할 때마다 함수가 돌게 되면서 
      // 계속 setIsVideoMode = false 처리로 인해 화상 회의 Join을 여러번 눌러야 등장하는 문제 발생
      AboutChannel.updateLastAccessStatus(this.$store.state.oldComponent, this.$store.state.selectComponent)
      switch (this.selectComponent) {
        case 'main':
          // 문제로 인한 주석 처리
          // this.$store.commit('setIsVideoMode', false)
          return 'ContentWrapper'
        case 'user':
          return 'UserInfo'
        case 'edit':
          return 'EditProfile'
        case 'todoList':
          return 'TodoList'
        case 'calendar':
          return 'Calendar'
        case 'admin':
          return 'AdminPage'
        case 'fileDrawer':
          return 'FileDrawer'
        // case 'videoChat':
        //   return 'VideoChat'
        case 'dashboard':
          return 'Dashboard'
        case 'notice':
          return 'Notice'
        default:
          return 'ContentWrapper'
      }
    },
    connectionCheck() {
      if (this.$store.state.stompClient != null) {
        return this.$store.state.stompClient.connected
      }
    },
  },
  async created() {
    const currentChannel = this.$store.state.currentChannel
    if (currentChannel != null) {
      currentChannel.count = 0
    }
    this.connect()
    EventListener.resizeEvt()
    EventListener.beforeunloadEvt()
    EventListener.focusEvt(this)
    EventListener.blurEvt()
    NotificationClass.requestPermission()
    this.$store.commit('setSmallWidth', (window.innerWidth < 600) ? true : false)
  },
  methods: {
    connect: function () {
      // 새로고침 했을때 Main의 로직이 실행되지 않는 환경에서는 문제가 생길 수 있음
      this.$store.state.stompClient = Stomp.over(new SockJS('/endpoint/'))
      this.$store.state.stompClient.debug = () => {
      }; // stomp log 제거 코드
      this.$store.state.stompClient.connect(this.$store.state.currentUser, () => {
        this.selectChannelList(null, false)
        this.subscribe("/sub/sync/info", res => {
          if (res.headers.noticeMsg != null) {
            this.noticeMsg = res.headers.noticeMsg
            this.noticeMsgToggle = true
          }
        })
      }, (e) => {
        if (!this.$store.state.isLogout) {
          // 아직 테스트 안해봄
          this.$_confirm('서버와의 통신이 끊겼습니다. 새로고침 하시겠습니까?', () => {
            location.reload()
          })
          // window.location.href = "/"
        }
      })
    },
    rightClickMenu: function(e, item, itemType) {
      // when rightclick, close info dialog
      e.preventDefault()
      // do not open contextmenu on deleted message
      if(item['delete_yn'] === undefined || item.delete_yn == 'N') {
        this.$refs.menu.closeDialog().then((flag) => {
          if(flag) {
            setTimeout(() => {
              this.$refs.menu.show(e, item, itemType)
            }, 100)
          } else {
            this.$refs.menu.show(e, item, itemType)
          }
        })
      }
    },isChatView: function (videoMode, chatView){
      if(videoMode && chatView){
        return true;
      }else{
        return false;
      }
    },isVideoView: function (type, videoMode, chatView){
        switch (type) {
          case 'col-9':
            if(videoMode && chatView){
              return true;
            }else if(videoMode && !chatView){
              return false;
            }else{
              return false;
            }
          case 'col-12':
            if(videoMode && !chatView){
              return true;
            }else if(videoMode && chatView){
              return false;
            }else{
              return false;
            }
        }
    },
    checkRSide: function(flag) {
      if(flag === false) {
        this.checkRSidebar = false
      } else {
        this.checkRSidebar = !this.checkRSidebar

        // when close rsidebar, hide thread chat
        setTimeout(() => {
          if(!this.checkRSidebar) {
            this.threadInfo.isThread = false
            this.threadInfo.threadContent = ''
          }
        }, 100)
      }
    },
    openThread: function(msg, flag) {
      this.checkRSidebar = true
      this.threadInfo.isThread = true
      this.threadInfo.threadContent = msg.content
      if(flag) {
        this.threadInfo.threadId = msg.thread
      } else {
        this.threadInfo.threadId = msg.id
      }
    },
    closeThread: function() {
      this.threadInfo.isThread = false
      this.threadInfo.threadContent = ''
    },
  }
}
</script>
<style>
.disactive-padding {
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-left: 240px !important;
}
.top-position {
  z-index: 1041 !important;
}
</style>
