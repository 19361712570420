<template>
    <main class="myflex-column" style="max-height: 50em; overflow: auto; background-color: white">
      <div class="container-fluid">
        <div class="page-header">
          <div class="row align-items-end">
            <div class="col-lg-12">
              <div class="page-header-title">
                <div class="d-inline">
                  <h5>
                    <i class="im im-task-o"></i> Add Todo-List
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="myflex-column myflex-grow" style="padding: 1em 0;">
        <v-row no-gutters>
          <v-col>
              <div class="cetered-align" style="padding: 1em 2em;">
                <v-select v-model="tasks" :items="tasklist" label="Select TaskList" dense></v-select>
                <v-btn v-show="tasks.id" small color="blue-grey" class="ma-2 white--text" style="margin-left: 2em !important;" @click="addTask(msg)">ADD</v-btn>
              </div>
              <ol class="dd-list" name="task-list">
                <draggable :list="tasks.tasks" draggable=".item" scroll-sensitivity="200" force-fallback="true" :disabled="disableCheck" @change="taskEventHandler">
                  <li class="dd-item item" v-for="(task,index) in tasks.tasks" :key="index">
                    <div class="dd-handle">
                      <div>
                        <div style="display: flex; align-items: center;">
                          <span class="small text-muted" v-if="task.start_date">{{ getDateFormat(task.start_date) }} ~ {{ getDateFormat(task.end_date) }}</span>
                          <v-row justify="end" align="start" dense>
                            <v-icon color="green" v-if="!task.state" dense>done</v-icon>
                          </v-row>
                        </div>
                        <p v-for="text in task.content.split('\n')" id="content" style="margin:0;" :key="text.id">{{ text }}</p>
                        <footer>
                          <v-row dense>
                            <v-col cols="10">
                              <small style="display:flex; justify-content: flex-start">
                                created {{ getDateFormat(task.register_date)}}
                              </small>
                            </v-col>
                            <v-col cols="2">
                              <small style="display: flex; justify-content: flex-end">
                                by {{ channelUsers.find(user => user.email == task.member_email).name }}
                              </small>
                            </v-col>
                          </v-row>
                        </footer>
                      </div>
                      <div class="task-color" :style="{'background-color':task.color}"></div>
                    </div>
                  </li>
                </draggable>
              </ol>
          </v-col>
        </v-row>
      </div>
    </main>
</template>

<script>
  import draggable from 'vuedraggable'

  export default {
    name: 'TaskAddInChat',
    props: {
      msg: {
        default: ''
      }
    },
    components: {
      draggable,
    },
    data() {
      return {
        tasklist: [],
        tasks: [],
        task: {
          tasklist_id: '',
          content: '',
          member_email: this.$store.state.currentUser.email,
          start_date: null,
          end_date: null,
          state: true,
          color: '#A463BF'
        },
      }
    },
    filters: {
    },
    computed: {
      disableCheck: function () {
        if (this.$store.state.isSmallWidth || this.$store.state.isCreateListActive) {
          return true
        } else {
          return false
        }
      }
    },
    created() {
    },
    deactivated() {
    },
    mounted() {
      this.getTasklist()
    },
    watch: {
    },
    methods: {
      getDateFormat: function (dateData) {
        return this.$moment(dateData).format('YYYY-MM-DD')
      },
      getTasklist: function() {
        let tasks = this.tasks
        this.$http.get('/api/tasklist/get/' + this.msg.channel_id)
        .then(res => {
          res.data.forEach(element => {
            element.text = element.name
            element.value = {}
            element.value.id = element.id
            element.value.tasks = element.tasks
          })
          this.tasklist = res.data
          if(this.tasks.id) {
            let newTasks = this.tasklist.find(val => val.id == this.tasks.id)
            this.tasks.text = newTasks.name
            this.tasks.value = {}
            this.tasks.value.id = newTasks.id
            this.tasks.value.tasks = newTasks.tasks
          }
        })
      },
      taskEventHandler: function (item) {
        let added = item.added
        let moved = item.moved
        let removed = item.removed

        let updateTaskItem = {
          taskOldIndex: null,
          taskNewIndex: null,
          tasklistOldId: null,
          tasklistNewId: null,
          tasklistId: null,
          taskId: null
        }
        if (moved) {
          updateTaskItem.taskNewIndex = moved.newIndex
          updateTaskItem.taskOldIndex = moved.oldIndex
          updateTaskItem.tasklistId = moved.element.tasklist_id
          updateTaskItem.taskId = moved.element.id
          this.$http.post('/api/task/update/position', updateTaskItem)
            .then(res => {
              this.$store.state.stompClient.send('/sub/todo/' + this.currentChannel.id, {}, {typename: 'taskUpdate'})
              this.getTasklist()
            }).catch(error => {
            console.error(error)
          })
        }
      },
      addTask: function(item) {
        this.task.tasklist_id = this.tasks.id
        if(!item.content['uuid']) {
          this.task.content = item.content
        } else {
          let conts = item.content

          let dt = new Date(Number(conts.time))
          let year = dt.getFullYear()
          let month = dt.getMonth() + 1
          month = month > 9 ? month : ('0' + month)
          let day = dt.getDate()
          day = day > 9 ? day : ('0' + day)
          let hour = dt.getHours()
          hour = ('0' + hour).slice(-2)
          let min = dt.getMinutes()
          min = ('0' + min).slice(-2)
          let sec = dt.getSeconds()
          sec = ('0' + sec).slice(-2)
          let date = year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + sec

          this.task.content = `[${conts.level}]\nMessage : ${conts.message}\nProject : ${conts.projectName}\nApplication : ${conts.oname}\nDate : ${date}`
        }
        this.$http.post('/api/task/insert', this.task)
          .then(res => {
            res.data.register_date = this.$moment.now()
            this.tasks.tasks.unshift(res.data)  // unshift로 해결 this.$set(this.tasks.tasks, 0, res.data)
            this.$store.state.stompClient.send('/sub/todo/' + this.$store.state.currentChannel.id, null, {typename: 'taskUpdate'})
            this.getTasklist()
          }).catch(error => {
          console.error(error)
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.user-info-card{
    margin-bottom: 25px;
    width: 40vw;
    max-width: 350px;
    min-width: 200px;
  }
  #content {
  overflow: hidden;
  word-wrap: break-word;
}

.task-color {
  width: 5px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.dd-handle {
  overflow: visible;
}
.dd-item, .item {
  width: 95%;
}
</style>