<template>
    <div class="container-fluid" style="width: 100%; flex-direction: row; display: flex; flex-wrap: wrap; cursor: default;">
        <v-dialog v-model="dialog" width="70vw" height="70vh">
            <v-card v-if="!textMode">
                <v-card-title class="headline">{{ title }}</v-card-title>
                <v-divider></v-divider>
                <v-card-subtitle style="text-align: right; padding: 0 1vw 1vh 0;">{{ editDate | dateFormat }} {{ editor.name }}</v-card-subtitle>
                <v-card-text style="height:50vh; position: relative; overflow: auto;">
                    <pre v-html="contents"></pre>
                </v-card-text>
                <v-card-subtitle v-show="files.length > 0" style="display: grid; grid-template-columns: 10vw 55vw; padding: 1vh 0px 0px 1vw; min-height: 5vh; max-height: 10vh; overflow: auto;"> 
                    <div style="display: flex; align-items: center; justify-content: center; grid-row: span 99; background-color: #AB47BC; color: white;">첨부파일</div>
                    <div>
                        <v-btn v-for="(item) in files" :key="item.id" text plain x-small @click="fileDownload(item)" style="background: #e0e0e0; margin: 0.25vh 0 0.25vh 0.5vw; border-radius: 8px; font-size: 0.1rem;">{{ item.original_name }}</v-btn>
                    </div>
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn @click="dialog = false" class="ma-1" color="success" plain>닫기</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="editable" class="ma-1" color="primary" plain @click="noticeEdit">수정</v-btn>
                    <v-btn v-if="editable" class="ma-1" color="error" plain @click="noticeDelete">삭제</v-btn>
                </v-card-actions>
            </v-card>
            <v-card v-else>
                <v-form ref="form" v-model="valid" lazy-validation> 
                    <v-card-title class="headline">
                        <v-text-field name="title" label="Title" v-model="title" :counter="titleCnt" :rules="titleRules"></v-text-field>
                    </v-card-title>
                    <v-divider></v-divider>
                    
                    <v-row dense no-gutters style="margin: 0 3vw; height: 3vh;">
                        <v-col>
                            <v-checkbox dense label="긴급" color="info" value="E" style="margin: 0;" true-value="E" v-model="noticeType"></v-checkbox>
                        </v-col>
                        <v-col>
                            <v-checkbox dense label="관리자" color="success" value="M" style="margin: 0;" true-value="M" v-model="noticeFlag"></v-checkbox>
                        </v-col>
                    </v-row>
                    
                    <v-card-text style="height:50vh; position: relative;">
                        <v-textarea name="contents" label="Contents" no-resize v-model="contents" rows="12" height="45vh" :rules="contentsRules"></v-textarea>
                    </v-card-text>

                    <v-card-subtitle style="display: grid; grid-template-columns: 10vw 55vw; padding: 1vh 0px 0px 1vw; min-height: 5vh; max-height: 10vh; overflow: auto;"> 
                        <div style="display: flex; align-items: center; justify-content: center; grid-row: span 99; background-color: #AB47BC; color: white;">첨부파일</div>
                        <!-- v-file-input에서 ref를 통한 file 확인 불가 -->
                        <v-file-input counter multiple show-size small-chips dense truncate-length="30" @change="addNewFiles"></v-file-input>
                        <div>
                            <v-btn v-for="(item) in files" :key="item.id" text plain x-small @click="fileDelete(item)" style="background: #e0e0e0; margin: 0.25vh 0 0.25vh 0.5vw; border-radius: 8px; font-size: 0.1rem;">{{ item.original_name }}<i class="im im-x-mark-circle" style="font-size: .625rem; margin: 0.2rem 0 0 0.3rem;"></i></v-btn>
                        </div>
                    </v-card-subtitle>
                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-btn @click="dialog = false" class="ma-1" color="success" plain>닫기</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn class="ma-1" color="primary" plain @click="saveNotice" :disabled="!valid">{{ writeOfEdit }}</v-btn>
                    </v-card-actions>

                </v-form>
            </v-card>
        </v-dialog>

        <div class="col-12" style="display: inline-block;">
            <div class="page-header">
                <div class="row align-items-end">
                    <div class="page-header-title col-12">
                        <i class="im im-paperplane bg-orange"></i>
                        <div class="d-inline">
                            <h5>Notice</h5>
                        </div>
                    </div>
                </div>
            </div>
            <v-card elevation="2" style="padding: 0.8em;" :loading="loadingNotice">
                <div style="display: flex; justify-content: center; flex-direction: column;">
                    <v-simple-table :[screenHeightCheck]="true" height="55vh" style="overflow: auto;">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-center" style="width: 10%" v-if="screenWidthCheck">
                                        No
                                    </th>
                                    <th class="text-center" style="width: 60%">
                                        Title
                                    </th>
                                    <th class="text-center" style="width: 15%" v-if="screenWidthCheck">
                                        Wrtier
                                    </th>
                                    <th class="text-center" style="width: 15%">
                                        Date
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in noticeList" :key="item.id" @click="detail(item.id, index)" style="cursor: pointer;">
                                    <td class="text-center" v-if="screenWidthCheck">{{ item.total - ((page - 1) * perLength) - index }}</td>
                                    <td><i class="im im-megaphone" style="font-size: 1em; color: red; margin-right: 0.5vw;" v-show="item.notice_type == 'E'"></i><span v-html="item.title"></span> <i class="im im-lock" style="font-size: 1em;" v-show="item.notice_flag == 'M'"></i> <span :inner-html.prop="item.edit_date | newMark"></span></td>
                                    <td class="text-center" v-if="screenWidthCheck">{{ item.editor.name }}</td>
                                    <td class="text-center">{{ item.edit_date | dateFormat }}</td>
                                </tr>
                                <tr v-show="noticeList.length <= 0">
                                    <td colspan="4" style="text-align: center; padding: 20vh 0; font-size: 1rem;">{{ waitNotice }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <div v-if="screenWidthCheck" class="text-center" style="margin-top: 1vh;">
                        <v-btn v-if="writable" class="ma-1" style="position: absolute; right: 0; margin: 0 1vw !important" color="secondary" plain @click="noticeWrite()">작성</v-btn>
                        <v-pagination v-model="page" :length="totalPage" :total-visible="perPage" @previous="previous" @next="next"></v-pagination>
                    </div>
                    <div v-else-if="total > noticeList.length" class="text-center" style="margin-top: 1vh;">
                        <v-btn text block @click="more">더 보기</v-btn>
                    </div>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Notice',
    components: {
    },
    data() {
        return {
            // notice
            noticeList: [],
            loadingNotice: false,
            waitNotice: '잠시 기다려 주세요.',
            screenWidth: 0,
            screenHeight: 0,
            writeOfEdit: '수정',

            // page
            page: 1,
            perLength: 14,
            perPage: 7,
            totalPage: 1,
            total: 0,

            // detail
            dialog: false,
            noticeType: '',
            noticeFlag: '',
            title: '',
            contents: '',
            editDate: '',
            editor: '',
            editorEmail: '',
            id: '',
            index: '',
            files: [],
            newFiles: [],
            delFiles: [],
            textMode: false,

            // write
            valid: true,
            titleCnt: 100,
            titleRules: [
                v => !!v || 'Title is required',
                v => (v && v.length <= this.titleCnt) || 'Title must be less than ' + this.titleCnt + ' characters',
            ],
            contentsRules: [v => (v || '' ).length > 0 || 'Contents must be required'],
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize)
        this.commit('setCurrentChannel', '')
        this.getNoticeList(1)
        this.handleResize()
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize)
    },
    computed: {
        writable: function() {
            return this.currentUser.roles.includes('ROLE_ADMIN') || this.currentUser.roles.includes('ROLE_ROOT')
        },
        editable: function() {
            return this.currentUser.email == this.editor.email || this.currentUser.roles.includes('ROLE_ADMIN') || this.currentUser.roles.includes('ROLE_ROOT')
        },
        screenHeightCheck: function() {
            return this.screenHeight < 1000 ? 'dense' : ''
        },
        screenWidthCheck: function() {
            return this.screenWidth < 768 ? false : true
        }
    },
    watch: {
        page: function(newPage, oldPage) {
            if(this.screenWidthCheck) {
                this.getNoticeList(newPage)
            } else {
                this.getNoticeList(newPage, true)
            }
        },
    },
    filters: {
        dateFormat(date) {
            let dt = new Date(date)

            let year = dt.getFullYear()
            let month = dt.getMonth() + 1
            month = month > 9 ? month : ('0' + month)
            let day = dt.getDate()
            day = day > 9 ? day : ('0' + day)

            return year + '-' + month + '-' + day
        },

        // 7일 이내 최신 글
        newMark: function(date) {
            if(((new Date().getTime() - date) / (1000 * 60 * 60 * 24) ) < 7) {
                return '<b style="padding:0 0.3vw 0.2vh; background-color: orange; border-radius: 10px; color: white; font-size: 0.5rem;">New</b>'
            } else {
                return ''
            }
        }
    },
    methods: {
        getNoticeList(page, flag) {
            this.loadingNotice = true
            if(!flag) {
                this.noticeList = []
                this.waitNotice = '잠시 기다려 주세요.'
            }
            this.$http.get('/api/dashboard/noticeList?length=' + this.perLength + '&start=' + page).then(res=>{
                let data = res.data
                if(!flag) {
                    this.noticeList = data
                    if(res.data.length > 0) {
                        this.total = res.data[0].total
                        this.totalPage = Math.ceil(this.total / this.perLength)
                    }
                } else {
                    this.noticeList.push(...data)
                }
                this.loadingNotice = false
                this.waitNotice = '공지사항이 없습니다.'
            })
        },
        previous: function() {
            this.page = (this.page - Math.ceil(this.perPage / 2) > 0 ? this.page - Math.ceil(this.perPage / 2) : 1)
        },
        next: function() {
            this.page = (this.page + Math.ceil(this.perPage / 2) <= this.totalPage ? this.page + Math.ceil(this.perPage / 2) : this.totalPage)
        },
        more: function() {
            this.page++
        },
        detail: function(id, index) {
            this.$http.get('/api/dashboard/noticeDetail?id=' + id).then(res=>{
                let data = res.data
                this.id = id
                this.index = index
                this.noticeType = data.notice_type
                this.noticeFlag = data.notice_flag
                this.title = data.title
                this.contents = data.contents
                this.editDate = data.edit_date
                this.editor = data.editor
                this.editorEmail = data.editor.email
                this.files = data.files
                this.dialog = true
                this.textMode = false
            })
        },
        noticeWrite: function() {
            this.id = -1
            this.index = ''
            this.noticeType = ''
            this.noticeFlag = ''
            this.title = ''
            this.contents = ''
            this.editDate = ''
            this.editor = ''
            this.editorEmail = ''
            this.files = []
            this.newFiles = []
            this.delFiles = []
            this.dialog = true
            this.textMode = true
            this.writeOfEdit = '작성'
            this.$nextTick(() => {
                this.$refs.form.reset()
            })
        },
        noticeEdit: function() {
            this.newFiles = []
            this.delFiles = []
            this.textMode = true,
            this.writeOfEdit = '수정'
        },
        noticeDelete: function() {
            this.$http.post('/api/dashboard/noticeDelete',{
                  id: this.id
                , email: this.editorEmail
            }).then(res=>{
                if(Math.ceil((this.total - 1) / this.perLength) < this.page && this.page > 1) {
                    this.page -= 1
                }
                if(this.screenWidthCheck) {
                    this.getNoticeList(this.page)
                } else {
                    this.noticeList.splice(this.index, 1) // 더할 때만 this.$set(Vue.set) 필요
                    this.noticeList = []
                    this.waitNotice = '잠시 기다려 주세요.'
                    this.page = 1
                }
                this.dialog = false
            })
        },
        addNewFiles: function(file) {
            if(file) {
                this.newFiles = file
            }
        },
        saveNotice: function() {
            if(this.$refs.form.validate()) {
                let formData = new FormData()
                formData.append('id', this.id)
                formData.append('notice_type', this.noticeType)
                formData.append('notice_flag', this.noticeFlag)
                formData.append('title', this.title)
                formData.append('contents', this.contents)
                formData.append('editor.email', this.editor.email)
                if(this.delFiles.length > 0) {
                    this.delFiles.forEach((file, index) => {
                        file = file[0]
                        formData.append('files[' + index + '].id', file.id)
                        formData.append('files[' + index + '].board_name', file.board_name)
                    })
                }
                if(this.newFiles.length > 0) {
                    this.newFiles.forEach(file => {
                        formData.append('newFiles', file)
                    })
                }
                let saveFlag = this.id == -1 ? 'noticeWrite' : 'noticeEdit'
                this.$http.post("/api/dashboard/" + saveFlag, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    this.dialog = false
                    this.getNoticeList(1)
                })
            }
        },
        handleResize: function() {
            this.screenWidth = document.documentElement.clientWidth
            this.screenHeight = document.documentElement.clientHeight
        },
        fileDownload: function (file) {
            this.$http.get("/api/file/download/" + file.board_name + "/" + file.server_name, {
                responseType: 'blob'
            })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', file.original_name)
                document.body.appendChild(link)
                link.click()
                link.remove()
                window.URL.revokeObjectURL(url)
            })
        },
        fileDelete: function(file) {
            let idx = this.files.findIndex(files => files.id == file.id)
            this.delFiles.push(this.files.splice(idx, 1))
        }
    },
}
</script>

<style>
.v-dialog {
    -webkit-box-shadow: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
    -moz-box-shadow: 0 0 0 0 !important;
    -o-box-shadow: 0 0 0 0 !important;
    padding: 30px 0;
    padding-right: 15px; 
    padding-left: 15px;
    margin: 0 0 0 255px !important;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; 
    overflow: hidden !important;
}
@media only screen and (max-width: 1023px) {
    .v-dialog { 
        padding-left: 0 !important; 
        padding-right: 0;
        margin-left: 0 !important;
    } 
} 
.dash-default {
    height: 20vh;
    overflow: auto;
}
.v-divider {
    margin: 0.5em;
}
.v-input__slot {
    align-items: inherit !important;
}
</style>