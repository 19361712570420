<template>
  <main>
    <div style="padding: 15px;">
      <div style="padding: 15px; display: flex; flex-direction: column; justify-content: center;">
        <v-tabs
          v-model="tab"
          background-color="cyan lighten-2"
          class="elevation-2"
          :centered="true"
          :grow="true">
          <v-tab
            v-for="i in tabs"
            :key="i"
            :href="`#tab-${i}`"
          >
            {{i}}
          </v-tab>
          <v-tab-item v-for="i in tabs" :key="i" :value="'tab-' + i ">
            <AuthorityPage v-if="i==tabs[0]"></AuthorityPage>
            <NoticeMessage v-if="i==tabs[1]"></NoticeMessage>
            <AllMemberList v-if="i==tabs[2]"></AllMemberList>
          </v-tab-item>
        </v-tabs>
      </div>
    </div>
  </main>
</template>
<script>
  import AuthorityPage from "./AuthorityPage";
  import NoticeMessage from "./NoticeMessage";
  import AllMemberList from "./AllMemberList";

  export default {
    name: 'AdminPage',
    components: {NoticeMessage, AuthorityPage, AllMemberList},
    data() {
      return {
        tab: null,
        tabs: [ this.$root.currentLang.admin.txt.tab.authSetting, this.$root.currentLang.admin.txt.tab.sendMessageToAll, this.$root.currentLang.admin.txt.auth.title ],
        isLActive: false,
        isRActive: false,
      }
    },
    computed: {},
    methods: {}
  }
</script>
<style scoped>
  .moHeight {
    height: inherit;
    overflow: auto;
  }
  .tabColor {
    background-color: #404E67;
  }
</style>
