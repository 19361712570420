<template>
  <div id="notfound">
    <div class="notfound">
      <div class="notfound-404">
        <h3>{{ errorMsg[0] }}</h3>
        <h1><span>{{ errorCode[0] }}</span><span>{{ errorCode[1] }}</span><span>{{ errorCode[2] }}</span></h1>
      </div>
      <h2>{{ errorMsg[1] }}</h2>
      <a href="/" class="btn btn-white btn-animation-1">GO HOME</a>
    </div>
  </div>
</template>
<script>
import Stomp from "webstomp-client";
import SockJS from "sockjs-client";
import axios from "axios";

export default {
  name: 'NotFound',
  data() {
    return {
      errorCode: ['4', '0', '2'],
      errorMsg: ['Page not found', '해당 페이지를 찾을 수 없습니다.']
    }
  },
  created() {
    switch (this.$route.query.type) {
      case '502':
        this.errorCode[0] = '5'
        this.errorCode[1] = '0'
        this.errorCode[2] = '2'
        this.errorMsg[0] = 'Bad Gateway'
        this.errorMsg[1] = '서버에 연결할 수 없습니다.'
        return
      default:
        return
    }

  },
  mounted() {
    this.chekingWSServer()
  },
  methods: {
    chekingWSServer: function () {
      const _this = this;
      this.$http.get('/api/user/getsession').then(res => {
        switch (typeof res.data){
          case "object":
            console.log("object");
            _this.$router.replace("/")
            break
          case "string":
            console.log("string");
            _this.$router.replace("/")
            break
        }
      }).catch(e =>{
        console.log(e)
        setTimeout(function (){
          _this.chekingWSServer()
        },10000)
      })
    }
    //   const _this = this;
    //   setTimeout(function () {
    //     try {
    //       _this.$store.state.stompClient = Stomp.over(new SockJS('/endpoint/'))
    //       if (_this.$store.state.stompClient.connected) {
    //         _this.$route.replace("/")
    //       } else {
    //         _this.chekingWSServer()
    //       }
    //     } catch (e) {
    //       console.log(e)
    //     }
    //   }, 20000)
    // }
  }
}
</script>

<style scoped>
@import "../assets/css/error.css";
</style>
