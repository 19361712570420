<template>
  <header class="header-top" header-theme="light">
    <div class="container-fluid">
      <div class="d-flex justify-content-between">
        <div class="top-menu d-flex align-items-center" style="flex-grow: 1;">
          <button type="button" class="btn-icon mobile-nav-toggle d-lg-none"><span></span></button>
          <div v-if="$store.state.currentChannel!=null && $store.state.currentChannel!=''" style="font-weight: bold;font-size: 15px;width: 0;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;flex-grow: 1;">
            {{ $store.getters.getCurrentChannel.name }}
          </div>
        </div>
        <div class="top-menu d-flex align-items-center">
          <div class="dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="notiDropdown" role="button" data-toggle="dropdown"
               aria-haspopup="true" aria-expanded="false"><i class="ik ik-bell"></i><span class="badge bg-danger"
                                                                                          v-show="getAlarmList.length > 0">{{alarmList.length}}</span></a>
            <div class="dropdown-menu dropdown-menu-right notification-dropdown" aria-labelledby="notiDropdown">
              <h4 class="header">{{ currLng.dashboard.notifications }}</h4>
              <div class="notifications-wrap">
                <div v-if="getAlarmList.length==0" class="media nuj hori-align"><span class="heading-font-family media-heading">{{ currLng.dashboard.notificationsEmpty }}</span></div>
                <div v-for="(alarm,index) in getAlarmList" :key="index">
                  <a class="media" style="cursor: default;">
                    <span class="media-body">
                      <div class="heading-font-family media-heading" v-if="alarm.type == 'D'">
                        {{ getUserNameByEmail(alarm.sender) }} 님이 {{ alarm.channel_name }} 채널에서 당신을 추방했습니다.
                      </div>
                      <div class="heading-font-family media-heading" v-else>
                        {{ getUserNameByEmail(alarm.sender) }} 님이 {{ alarm.channel_name }}에 초대했습니다. 수락하시겠습니까?
                      </div>
                      <div class="menulist-header-icon">
                        <b-button v-show="alarm.type != 'D'" style="padding: 0 0.5rem 0 0;" size="sm" variant="nonoutline" @click="inviteAccept(alarm,index)">
                          <i class="im im-check-mark-circle" style="color: #42b983;"></i>
                        </b-button>
                        <b-button v-show="alarm.type != 'D'" style="padding: 0 0.5rem 0 0;" size="sm" variant="nonoutline" @click="inviteRefuse(alarm,index)">
                          <i class="im im-x-mark-circle" style="color: red;"></i>
                        </b-button>
                        <b-button v-show="alarm.type == 'D'" style="padding: 0 0.5rem 0 0;" size="sm" variant="nonoutline" @click="closeAlarm(index)">
                          <i class="im im-x-mark-circle" style="color: green;"></i>
                        </b-button>
                      </div>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <button v-if="checkRSidebar" type="button" @click="rightSidebarToggle"
                  class="nav-link ml-10 right-sidebar-toggle"><i class="ik ik-message-square"></i></button>
          <div class="dropdown">
            <a class="dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown"
               aria-haspopup="true" aria-expanded="false">
              <img onError="this.src='/img/default-user-picture.png'" class="avatar" :src="$store.state.currentUser.picture">
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
              <a class="dropdown-item" v-show="!hideSettings" @click="callComponent('user')"><i class="ik ik-user dropdown-icon"></i> {{ currLng.dropdown.profile }}</a>
              <a class="dropdown-item" v-if="isAdmin() && !hideSettings" @click="callComponent('admin')"><i class="ik ik-settings dropdown-icon"></i> {{ currLng.dropdown.permission }}</a>
              <!-- info 페이지가 완성되면 if 처리 제거 -->
              <a v-if="false" class="dropdown-item" @click="$router.push('/info')"><i class="ik ik-info dropdown-icon"></i> Info</a>
              <a v-if="false" class="dropdown-item" @click="$router.push('/develop')"><i class="ik ik-settings dropdown-icon"></i> Setting</a>
              <a class="dropdown-item" @click="$root.changeLocale()"><i class="im im-language dropdown-icon"></i> {{ currLng.dropdown.translations }}</a>
              <a class="dropdown-item" @click="SignOut"><i class="ik ik-power dropdown-icon"></i> {{ currLng.dropdown.logout }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
  import '../../../dist/js/theme.js'
  import AboutChannel from '../../service/aboutchannel'

  export default {
    name: 'MainHeader',
    data() {
      return {
        alarmList: [],
      }
    },
    props: {
      hideSettings: {
        default: false
      }
    },
    computed: {
      getAlarmList: function () {
        while (this.alarmList.length > 5) {
          this.alarmList.pop()
        }
        return this.alarmList
      },
      checkRSidebar: function() {
        switch (this.selectComponent) {
          case 'user':
          case 'edit':
          case 'admin':
          case 'dashboard':
          case 'notice':
            this.$emit('checkRS', false)
            // 210421 modify bug
            // if($('.right-sidebar-toggle')[0]) {
            //   $('.right-sidebar-toggle')[0].classList.toggle('active');
            //   $('.wrapper').removeClass('right-sidebar-expand');
            //   $('.main-content').removeClass('rsidebar-padding-right')
            // }
            return false
          case 'main':
          case 'todoList':
          case 'calendar':
          case 'fileDrawer':
          default:
            return true
        }
      },
    },
    created() {
      this.subscribe("/sub/alarm/" + this.$store.state.currentUser.email, (e) => {
        let invite = JSON.parse(e.body)
        this.alarmList.unshift(invite)
        // dashboar 알림창 subs
        this.$store.commit('setAlarmList', invite)
      }),
      this.$http.get('/api/invite/list').then(res => {
        this.alarmList = res.data.reverse()
        // dashboar 알림창 subs
        if(res.data.reverse().length) {
          this.$store.commit('setAlarmList', res.data.reverse())
        }
      })
      .catch(error => {
      }),
      this.$store.dispatch('userListUpdate')
    },
    mounted() {
      // echoCancellation 추가 해야함
      var self = this;
      navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(
        function(stream) {

            stream.getTracks().forEach(function(data){data.stop()});
            self.$store.commit("setCanAudio", true);
            self.$store.commit("setCanVideo", true);
            console.log('Video, Audio Use.');
          }
        ).catch(
          function(){
            navigator.mediaDevices.getUserMedia({ audio: true }).then(
            function(stream) {
              stream.getTracks().forEach(function(data){data.stop()});
              self.$store.commit("setCanAudio", true);
              console.log('Audio Use.');
            }
            ).catch(
              function(){
                console.log('Audio Not Use.');
              }
            );

          navigator.mediaDevices.getUserMedia({ video: true }).then(
            function(stream) {
              stream.getTracks().forEach(function(data){data.stop()});
              self.$store.commit("setCanVideo", true);
              console.log('Video Use.');
            }
            ).catch(
              function(){
                console.log('Video Not Use.');
              }
            );
          }
        );
    },
    methods: {
      rightSidebarToggle: function (e) {
        this.$emit('checkRS')
        // $('.right-sidebar-toggle')[0].classList.toggle('active');
        // $('.wrapper').toggleClass('right-sidebar-expand');
        // if($('.main-content').hasClass('rsidebar-padding-right')){
        //   $('.main-content').removeClass('rsidebar-padding-right')
        // }else{
        //   $('.main-content').addClass('rsidebar-padding-right')
        // }
        // return false;
      },
      callComponent: function (component) {
        this.$store.commit('getSelectComponent', component)
      }
      ,
      LSidebarToggle: function () {
        this.$store.state.isLActive = !this.$store.state.isLActive
      }
      ,
      RSidebarOpen: function () {
        this.$store.state.isRActive = true
      }
      ,
      SignOut() {
        this.$store.commit('setIsLogout', true)
        window.location.href = "/logout"
      }
      ,
      getUserNameByEmail: function (email) {
        let foundEmail = this.$store.state.userList.find(element => {return element.email == email})
        if(foundEmail!=null){
          return foundEmail.name
        }else{
          return email
        }
      },
      closeAlarm: function(index) {
        this.alarmList.splice(index, 1);
      },
    }
  }
</script>

<style>
a.dropdown-item{
  color:#212529 !important;
}

</style>

<style scoped>

  .v-application .ml-10 {
    margin-left: 10px !important;
  }
</style>
