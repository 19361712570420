<template>
  <v-container>
    <v-calendar
        ref="calendar"
        :value="today"
        type="week"
        :events="events"
      >
      </v-calendar>
    <v-card>
      <v-card-title class="indigo white--text headline">
        조직도
      </v-card-title>
      <v-row
        class="pa-4"
        justify="space-between"
      >
        <v-col cols="5">
          <v-treeview
            :active.sync="active"
            :items="users"
            :open.sync="open"
            activatable
            color="warning"
            open-on-click
            transition
          >
            <template v-slot:prepend="{ item }">
              <v-icon v-if="!item.children">
                mdi-account
              </v-icon>
            </template>
          </v-treeview>
        </v-col>

        <v-divider vertical></v-divider>

        <v-col
          class="d-flex text-center"
        >
          <v-scroll-y-transition mode="out-in">
            <div
              class="title grey--text text--lighten-1 font-weight-light"
              style="align-self: center;"
            >
              Select a User {{ active }} / {{ open }} / {{ selected }}
            </div>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
    </v-card>

    <v-btn @click="methodIndex++">METHOD UP</v-btn>
    <v-btn @click="methodIndex--">METHOD DOWN</v-btn>
    <v-btn @click="computedIndex++">COMPUTED UP</v-btn>
    <v-btn @click="computedIndex--">COMPUTED DOWN</v-btn>
    <v-btn @click="resetIndex()">COMPUTED RESET</v-btn>
    METHOD -> {{ returnMethodIndex() + '/' + methodIndex }} COMPUTED -> {{ returnComputedIndex + '/' + computedIndex }}
    {{ presentTime }}
    <!-- 210309 route 안에 children 있는 경우 parent에 router-view를 넣어야 한다!! -->
    <router-view></router-view>
  </v-container>
</template>

<script>
  export default {
    name: 'AppInfo',
    data: () => ({
      active: [],
      open: [],
      users: [
        {
          name: 'dept',
          children: [
                {
                  "id": 1111,
                  "name": "Leanne Graham",
                  "email": "Sincere@april.biz",
                  "address": {
                    "street": "Kulas Light",
                    "suite": "Apt. 556",
                    "city": "Gwenborough",
                    "zipcode": "92998-3874",
                    "geo": {
                      "lat": "-37.3159",
                      "lng": "81.1496"
                    }
                  },
                },
                {
                  "id": 1112,
                  "name": "Ervin Howell",
                  "email": "Shanna@melissa.tv",
                  "address": {
                    "street": "Victor Plains",
                    "suite": "Suite 879",
                    "city": "Wisokyburgh",
                    "zipcode": "90566-7771",
                    "geo": {
                      "lat": "-43.9509",
                      "lng": "-34.4618"
                    }
                  },
                },
                {
                  "id": 1113,
                  "name": "Clementine Bauch",
                  "email": "Nathan@yesenia.net",
                  "address": {
                    "street": "Douglas Extension",
                    "suite": "Suite 847",
                    "city": "McKenziehaven",
                    "zipcode": "59590-4157",
                    "geo": {
                      "lat": "-68.6102",
                      "lng": "-47.0653"
                    }
                  },
                }
              ]
        }
      ],
      methodIndex: 0,
      computedIndex: 0,
      presentTime: '',

      today: '2021-06-02',
      events: [],
    }),
    created() {
      // setInterval(() => {
      //   let date = new Date()
      //   this.presentTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
      // }, 1000)
    },
    updated() {
      console.log("UPDATED!!!!")
    },
    computed: {
      selected () {
        console.log(this.active)
        if (!this.active.length) return undefined

        const id = this.active[0]
        return this.users[0].children.find(user => user.id === id)
      },
      returnComputedIndex() {
        console.log("COMPUTED", this.computedIndex)
        return this.computedIndex
      }
    },
    methods: {
      returnMethodIndex() {
        console.log("METHOD", this.methodIndex)
        return this.methodIndex
      },
      resetIndex() {
        this.computedIndex = 0
        console.log("METHOD222", this.computedIndex)
      }
    },
  }
</script>

<style scoped>

</style>
