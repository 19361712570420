<template>
    <main class="mainwrapper myflex-column" style="height: calc(100vh - 150px);overflow: auto;">
      <div class="container-fluid">
        <div class="page-header">
          <div class="row align-items-end">
            <div class="col-lg-8">
              <div class="page-header-title">
                <i v-if="user" :class="{'rightClickChk-css' : rightClickChk}" class="ik ik-file-text bg-blue"></i>
                <div class="d-inline">
                  <h5>{{ currLng.profile.txt.title }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="myflex-column myflex-grow">
        <div style="flex-grow: 0.5;"></div>
        <v-row no-gutters>
          <v-col>

              <div class="cetered-align myflex-column" >
                <v-img style="border-radius: 5%;" :src="user.picture" width="200" height="200"></v-img>
                <h4 style="margin: 20px 0px 20px 0;" >
                  {{ user.name }}
                  </h4>
                <!-- <span style="color: #afb5c1;margin-bottom:20px;">부서(IT사업본부) 직급(사원) </span> -->
                <div>
                  <v-card class="user-info-card" >
                    <v-card-title class="myflex myflex-nowrap">
                      <v-icon style="display: flex;margin-right: 20px;">mail</v-icon>
                      <p class ="myflex-grow txt-ellipsis" style="margin: 0;">
                        {{ user.email }}
                      </p>
                      </v-card-title>
                  </v-card>
                  <v-card class="user-info-card">
                    <v-card-title class="myflex myflex-nowrap">
                      <v-icon style="display: flex;margin-right: 20px;">phone</v-icon>
                      <p class ="myflex-grow txt-ellipsis" style="margin: 0;">
                        {{ user.phone }}
                      </p>
                    </v-card-title>
                  </v-card>
                  <div class="hori-align" v-if="user">
                    <v-btn v-if="!rightClickChk" depressed color="primary" @click="callComponent('edit')">{{ currLng.common.btn.modify }}</v-btn>
                  </div>
                </div>

              </div>



          </v-col>

        </v-row>

      </div>
    </main>
</template>

<script>

  import {mapGetters} from "vuex";

  export default {
    name: 'UserInfo',
    props: {
      'relatedUser': {
        default: function() {
          // 210309 route에서 값 받아온 경우
          if(this.$route.params.id) {
            return this.$route.params.id
          } else {
            return ''
            // 210513 props가 computed보다 먼저 동작하게 되면서 this.getCurrentUser의 값을 받아오지 못함
            // return this.$store.getters.getCurrentUser 
          }
        }
      },
      rightClickChk: {
        default: false
      },
    },
    data() {
      return {
      }
    },
    computed: {
      ...mapGetters({
        getCurrentUser: 'getCurrentUser',
      }),
      // cache을 통한 user 즉시 변경
      user: function() {
        if(!this.relatedUser) {
          return this.getCurrentUser
        } else {
          return this.relatedUser
        }
      }
    },
    created() {
    },
    deactivated() {
    },
    methods: {}
  }
</script>

<style lang="scss" scoped>

  .user-info-card{
    margin-bottom: 25px;
    width: 40vw;
    max-width: 350px;
    min-width: 200px;
  }

  .rightClickChk-css {
    float: left;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    margin-right: 20px;
    vertical-align: middle;
    font-size: 22px;
    color: #fff;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -webkit-box-pack: center;
    justify-content: center;
    -ms-flex-pack: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-shadow: 0 2px 12px -3px rgb(0 0 0 / 50%);
    box-shadow: 0 2px 12px -3px rgb(0 0 0 / 50%);
  }

</style>
