<template>
  <v-app data-app>
    <router-view/>

  </v-app>

</template>

<script>
// import m from './json/msg.js'
import {getGlobalMsg} from './json/msg.js'
 export default {
   name:'app',
   created(){
      getGlobalMsg("channel.msg.leaveMsg","홍길동")
      getGlobalMsg("channel.msg.leaveMsg2",["홍길순","홍길동"])
      getGlobalMsg("invite.err.existingInvite",["홍길순","홍길동"])
   }
 }

</script>


<script>
(function (w, h, _a, t, a, b) {
  w = w[a] = w[a] || {
    config: {
      projectAccessKey: "x404p23ngb8v6-x20gp2setdseou-x7f55sos3mra9d",
      pcode: 153,
      sampleRate: 100,
      proxyBaseUrl: "https://whatap.nineonesoft.com/153/",
    },
  };
  a = h.createElement(_a);
  a.async = 1;
  a.src = t;
  t = h.getElementsByTagName(_a)[0];
  t.parentNode.insertBefore(a, t);
})(window, document, 'script', 'https://repo.whatap-browser-agent.io/rum/prod/v1/whatap-browser-agent.js', 'WhatapBrowserAgent', '');
</script>
<style>
/* a {
    color:#212121 !important;
  } */
</style>

<style lang="scss" scoped>

  #app {
    // font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

  #app {
    height: 100vh;
    background-color: #fafafa;
  }

  #app ul {
    padding-left: 0;
    list-style: none;
  }



  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  .col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto {
    padding-top: 0;
    padding-bottom: 0;
  }


</style>
