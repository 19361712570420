import { render, staticRenderFns } from "./MsgBox.vue?vue&type=template&id=002fa4c5&scoped=true&"
import script from "./MsgBox.vue?vue&type=script&lang=js&"
export * from "./MsgBox.vue?vue&type=script&lang=js&"
import style0 from "./MsgBox.vue?vue&type=style&index=0&lang=css&"
import style1 from "./MsgBox.vue?vue&type=style&index=1&id=002fa4c5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "002fa4c5",
  null
  
)

export default component.exports