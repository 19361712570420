<template>
  <div style="height: 100%">
    <div class="video-container" ></div>
    <v-row justify="center" align="center" no-gutters style="height: 90%;">
      <component :is="defaultLayout" :rtcm-connection="rtcmConnection" :video-list="videoList" ref="layout">
      </component>
    </v-row>
    <v-row justify="end">
      <div style="text-align: center; margin: auto;" >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="blue-grey" v-bind="attrs" v-on="on" class="white--text" @click="shareScreen">
              <v-icon>airplay</v-icon>
            </v-btn>
          </template>
          <span>{{ shareScreenWord }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="blue-grey" v-bind="attrs" v-on="on" class="white--text" @click="toggleMyVideoMute">
              <img v-show="isCam" src="../../assets/images/cam.png" style="width:2rem" />
              <img v-show="!isCam" src="../../assets/images/cam-off.png" style="width:2rem" />
            </v-btn>
          </template>
        <span>{{ myVideoMute }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="blue-grey" v-bind="attrs" v-on="on" class="white--text" @click="toggleMyAudioMute">
              <img v-show="isMic" src="../../assets/images/mic.png" style="width:2rem" />
              <img v-show="!isMic" src="../../assets/images/mic-off.png" style="width:2rem" />
            </v-btn>
          </template>
          <span>{{ myAudioMute }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="blue-grey" v-bind="attrs" v-on="on" class="white--text" @click="hideChat">
              <img v-show="isChat" src="../../assets/images/chat.png" style="width:2rem" />
              <img v-show="!isChat" src="../../assets/images/chat-off.png" style="width:2rem" />
            </v-btn>
          </template>
          <span>HideChat</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-menu
              open-on-click
              top
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="blue-grey" v-bind="attrs" v-on="on" class="white--text" >
                  <img :src="require(`@/assets/images/${defaultLayout}.png`)" style="width:2rem" />
                </v-btn>
              </template>
              <v-list color="blue-grey">
                <v-list-item
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <v-list-item-title ><a @click="changeLayout(item.kind,index)"><img :src="require(`@/assets/images/${item.kind}.png`)" style="width:2rem" /></a></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <span>Layout</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="red" v-bind="attrs" v-on="on" class="white--text" @click="toggleVideoMode">
              <v-icon right black large style="margin-right: 7px; "class="my-video">exit_to_app</v-icon>
            </v-btn>
          </template>
          <span>Exit</span>
        </v-tooltip>
      </div>
    </v-row>
  </div>
</template>

<script>
import RTCMultiConnection from 'rtcmulticonnection';
import draggable from "vuedraggable";
import default_layout from "../layouts/DefaultLayout";
import layout1 from "../layouts/SecondLayout";
import layout2 from "../layouts/ThirdLayout";


require('adapterjs');
export default {
  name: "vue-webRTC", 
  components: {
    RTCMultiConnection,
    draggable,
    default_layout,
    layout1,
    layout2

  },
  computed: {
    getVideoCols: function () {
      switch (this.videoList.length) {
        case 1:
          return 10;
        case 2:
          return 6;
        case 3:
        case 4:
          return 5;
        case 5:
        case 6:
          return 4;
        default:
          return 3;
      }
    }
  },
  data() {
    return {
      data:{
        count:0,
      },
      count :0,
      rtcmConnection: null,
      localVideo: null,
      videoList: [],
      canvas: null,
      windowSize: {
        x: 0,
        y: 0,
      },
      publicRoomIdentifier : 'nineone',
      isCam: false,
      isMic: true,
      shareScreenWord: this.$root.currentLang.videoChat.shareScreen,
      myVideoMute: this.$root.currentLang.videoChat.myVideoMute.on,
      myAudioMute: this.$root.currentLang.videoChat.myAudioMute.off,
      isChat: true,
      defaultLayout: "default_layout",
      items: [
                { kind: 'default_layout' },
                { kind: 'layout1' },
                { kind: 'layout2' },
             ],    
     };  
  },
  videoConfig: {
    "mandatory": {
      "minWidth": "120",
      "maxWidth": "320",
      "minHeight": "90",
      "maxHeight": "240",
      "minFrameRate": "10",
      "maxFrameRate": "15"
    }
  },
  props: {
    iceServer: {
      type: String,
      default: 'stun:stun.1.google.com:19302'
    },
    roomId: {
      default: 'public-room'
    },
    socketURL: {
      type: String,
      // default: 'https://rtcmulticonnection.herokuapp.com:443/'
      default: 'https://91cm.nineonesoft.com:9002/'
    },
    cameraHeight: {
      type: [Number, String],
      default: 160
    },
    autoplay: {
      type: Boolean,
      default: true
    },
    screenshotFormat: {
      type: String,
      default: 'image/jpeg'
    },
    enableAudio: {
      type: Boolean,
      default: true
    },
    enableVideo: {
      type: Boolean,
      default: true
    },
    enableLogs: {
      type: Boolean,
      default: true
    },
  },
  watch: {
    videoList: function () {
    }
  },
  mounted() {
    let that = this;
    this.rtcmConnection = new RTCMultiConnection();
    this.rtcmConnection.socketURL = this.socketURL;
    this.rtcmConnection.iceServers = [];
    this.rtcmConnection.publicRoomIdentifier = this.publicRoomIdentifier;
    // this.rtcmConnection.iceServers.push({
    //   urls: 'stun:stun4.l.google.com:19302'
    // });
    this.rtcmConnection.iceServers.push({
      url: 'turn:nat.nineonesoft.com:19301',
      credential: '91cm',
      username: '91cm'
    });

    this.rtcmConnection.publicRoomIdentifier = 'nineone'

    // this.rtcmConnection.iceProtocols = {
    //   udp: true,
    //   tcp: true
    // }

    // this.rtcmConnection.codecs = {
    //   audio: 'PCMA/U',
    //   video: 'H264'
    // };
    this.rtcmConnection.autoCreateMediaElement = false;
    this.rtcmConnection.enableLogs = this.enableLogs;

    this.rtcmConnection.session = {
      audio: this.$store.getters.getCanAudio,
      video: this.$store.getters.getCanVideo,
      oneway: !this.$store.getters.getCanAudio && !this.$store.getters.getCanVideo,
      // screen:true
    };

    this.rtcmConnection.mediaConstraints = {
      audio: this.$store.getters.getCanAudio,
      video: this.$store.getters.getCanVideo
    };

    this.rtcmConnection.sdpConstraints.mandatory = {
      OfferToReceiveAudio: true,
      OfferToReceiveVideo: true
    };

    this.rtcmConnection.onstream = function (stream) {
      //console.log("streammmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm");
      //console.log(stream);
       
      // stream.stream.getTracks().forEach(function(data){console.log(data)});
      // stream.stream.onaddtrack = function(data){
      //   console.log(data)
      //   }
      // if(stream.type == 'local')
      // {
      //   if(stream.stream.getTracks().find(function(data){return data.kind == "video"}))
      //   {
      //     var temptrack = stream.stream.getTracks().find(function(data){return data.kind == "video"});
      //     stream.stream.removeTrack(temptrack);
      //     setTimeout(() => {
      //       stream.stream.addTrack(temptrack);
      //     }, 1000);
      //   }

      // }
      // if(stream.stream.isVideo && stream.stream.getTracks().find(function(data){return data.kind == "video"}) == undefined) {
        // setTimeout(() => {
          // console.log('retry');
          
          // _this.rtcmConnection.addStream({audio: true, video:true})
          // _this.rtcmConnection.getRemoteStreams()[0].onaddtrack = function(data){console.log(data); console.log(this)}
          // _this.rtcmConnection.onstream(stream);
        // }, 1000);
      // }else {

      // console.log(_this.rtcmConnection.streamEvents);
      let found = that.videoList.find(video => {
        return video.id === stream.streamid
      })
      if (found === undefined) {
        let video = {
          id: stream.streamid,
          muted: stream.type === 'local',
          user: stream
        };
        that.videoList.push(video);

        if (stream.type === 'local') {
          that.localVideo = video;
        }
      }

      setTimeout(function () {
        let videos = that.$refs.layout.$refs.videos;
        for (var i = 0, len = videos.length; i < len; i++) {
          if (videos[i].id === stream.streamid) {
            videos[i].srcObject = stream.stream;
            break;
          }
        }
      }, 2000);
      that.$emit('joined-room', stream.streamid);

      // 비디오 초기에 뮤트한 상태로 시작
      if (stream.type ==='local') {
        stream.mediaElement.isVideoMuted = true;
        // stream.stream.mute('video');
        setTimeout(() => {
          that.rtcmConnection.addStream({audio: true, video:true});
          var localStreamID = that.rtcmConnection.attachStreams[0].streamid;
          var localStream = that.rtcmConnection.streamEvents[localStreamID].stream;
          that.rtcmConnection.streamEvents[localStreamID].extra.isVideoMuted = true
          that.rtcmConnection.streamEvents[localStreamID].extra.isAudioMuted = false
          that.rtcmConnection.updateExtraData()
          localStream.mute('video');
          localStream.unmute('audio');
          that.myVideoMute = that.$root.currentLang.videoChat.myVideoMute.on
          that.myAudioMute = that.$root.currentLang.videoChat.myAudioMute.off
        }, 1000);
      }

      // 초기 리모트 뮤트 상태 확인
      if(stream.type != 'local') {
        let remoteVideo = setInterval(() => {
          if(document.getElementById(stream.streamid + '-mute-image')) {
            clearInterval(remoteVideo)
            if(that.rtcmConnection.streamEvents[stream.streamid].extra.isVideoMuted && !stream.stream.isScreen) {
              document.getElementById(stream.streamid + '-mute-image').style.zIndex = 2
            } else {
              document.getElementById(stream.streamid + '-mute-image').style.zIndex = -1
            }
          }
        }, 500)
      }
    };
    this.rtcmConnection.onstreamended = function (stream) {
        var newList = []
        that.videoList.forEach(function (item,idx) {
          if (item.id !== stream.streamid) {
            newList.push(item);
          }
        })
        that.videoList = newList
      // that.videoList.splice(that.videoList.findIndex(item => {
      //   if(item.id == stream.streamid) {
      //     return true
      //   }
      // }), 1)
      // that.$emit('left-room', stream.streamid);
    }
    this.rtcmConnection.onUserStatusChanged = function (event) {
      // console.log(event)
      }
    this.rtcmConnection.onPeerStateChanged = function (event) {
      // console.log(event)
    }
    
    this.rtcmConnection.onExtraDataUpdated = function(event) {
      let updateUser = that.videoList.find((item) => {
        if (item.user.userid == event.userid) {
          return true
        }
      });
      if(updateUser) {
        that.$set(updateUser.user.extra, 'isVideoMuted', event.extra.isVideoMuted)
        that.$set(updateUser.user.extra, 'isAudioMuted', event.extra.isAudioMuted)
      }
    };

    this.rtcmConnection.onleave = function(event) {
      var newList = [];
      that.videoList.forEach(function (item) {
        if (item.user.userid !== event.userid) {
          newList.push(item);
        }
      });
      that.videoList = newList;
    };

    this.rtcmConnection.onmute = function(e) {
      if(e.muteType == 'video') {
        document.getElementById(e.streamid + '-mute-image').style.zIndex = 2
        that.rtcmConnection.streamEvents[e.streamid].extra.isVideoMuted = true
      } else if(e.muteType == 'audio') {
        that.rtcmConnection.streamEvents[e.streamid].extra.isAudioMuted = true
      }
      that.rtcmConnection.updateExtraData()
    };

    this.rtcmConnection.onunmute = function(e) {
      if(e.unmuteType == 'video') {
        document.getElementById(e.streamid + '-mute-image').style.zIndex = -1
        that.rtcmConnection.streamEvents[e.streamid].extra.isVideoMuted = false
      } else if(e.unmuteType == 'audio') {
        that.rtcmConnection.streamEvents[e.streamid].extra.isAudioMuted = false
      }
      that.rtcmConnection.updateExtraData()
    };

    // this.rtcmConnection.socketOptions = {
    //     // 'force new connection': true, // For SocketIO version < 1.0
    //     'forceNew': true, // For SocketIO version >= 1.0
    //     'transport': ['websocket'] // fixing transport:unknown issues
    // };
    this.rtcmConnection.socketOptions.transport = 'websocket';
    // this.rtcmConnection.checkPresence(this.roomId, function (isRoomExist, roomid, error) {
    //   console.log("checkPresence : ",isRoomExist, roomid, error)
    //   if (isRoomExist === true){
    //     that.join()
    //   }else{
    //     that.join()
    //   }
    // });
  },
  methods: {
    checkNum(val){
      alert(val);
    },
    increase() {
              this.data.count = this.data.count + 1;
          },
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },
    join() {
      var that = this;

      // 210406 주혁씨가 추가한 것으로 보이는 오디오만 있는 유저 화상회의 참가 가능?
      // navigator.mediaDevices.enumerateDevices().then(device =>{
      //   console.log(device)
      //   device.forEach(d =>{
      //     if(d.kind == 'audioinput'){
      //       console.log('init audio')
      //       that.rtcmConnection.session.audio = true
      //       that.rtcmConnection.mediaConstraints.audio = true
      //     }else if(d.kind == 'videoinput'){
      //       console.log('init video')
      //       that.rtcmConnection.session.video = true
      //       that.rtcmConnection.mediaConstraints.video = true
      //     }
      //   })
      // }).catch(e =>{
      //     console.log(e)
      // });
      // this.rtcmConnection.session.video = false
      // this.rtcmConnection.mediaConstraints.video = false

      this.rtcmConnection.bandwidth = {
          // audio	audio bitrates. Minimum 6 kbps and maximum 510 kbps
          // video	video framerates. Minimum 100 kbps; maximum 2000 kbps
          // screen	screen framerates. Minimum 300 kbps; maximum 4000 kbps
          audio: 6,
          video: 150,
          screen: 300
      };
      // 코덱 설정
      this.rtcmConnection.codecs.video = 'H263';
      this.rtcmConnection.codecs.audio = 'Opus';
      // 밴드위드 적용소스
      this.rtcmConnection.processSdp = function(sdp) {
          sdp = BandwidthHandler.setApplicationSpecificBandwidth(sdp, that.rtcmConnection.bandwidth, !!that.rtcmConnection.session.screen);
          sdp = BandwidthHandler.setVideoBitrates(sdp, {
              min: that.rtcmConnection.bandwidth.video,
              max: that.rtcmConnection.bandwidth.video
          });
          sdp = BandwidthHandler.setOpusAttributes(sdp);
          sdp = BandwidthHandler.setOpusAttributes(sdp, {
              'maxaveragebitrate': that.rtcmConnection.bandwidth.audio * 1000 * 8,
              'maxplaybackrate': that.rtcmConnection.bandwidth.audio * 1000 * 8,
          });
          return sdp;
      };


      this.rtcmConnection.openOrJoin(this.roomId, function (isRoomExist, roomid) {
        if (isRoomExist === false && that.rtcmConnection.isInitiator === true) {
          that.$emit('opened-room', this.rtcmConnection.publicRoomIdentifier, roomid);
          // console.log(that.rtcmConnection.attachStreams[0]);
        }

        that.rtcmConnection.socket.on('disconnect', function (message) {
          that.join()
        })
      });

      this.rtcmConnection.extra.username = this.$store.state.currentUser.name;
      // this.rtcmConnection.updateExtraData();
      this.rtcmConnection.slider = this.rtcmConnection.id;
    },
    leave() {
      this.rtcmConnection.attachStreams.forEach(function (localStream) {
        localStream.stop();
      });
      this.videoList = [];
      this.rtcmConnection.closeSocket();
    },
    shareScreen() {
      var that = this;
      if (navigator.getDisplayMedia || navigator.mediaDevices.getDisplayMedia) {
        function onGettingSteam(stream) {
          stream.isScreen = true
          that.rtcmConnection.addStream(stream)

          var streamEndedEvent = 'ended'
          if ('oninactive' in stream) {
            streamEndedEvent = 'inactive'
          }
          stream.addEventListener(streamEndedEvent, function () {
            var streamToRemove = null
            var newArray =  []
            that.rtcmConnection.attachStreams.forEach(function(_stream) {
              if(_stream.id === stream.streamid) {
                  streamToRemove = _stream;
              } else {
                newArray.push(_stream);
              } 
            })
            that.rtcmConnection.attachStreams = newArray
            that.rtcmConnection.getAllParticipants().forEach(function(participantId) {
                var peer = that.rtcmConnection.peers[participantId].peer
                
                // it works only in Chrome
                peer.removeStream(streamToRemove)
            });
            // sync above action across all users
            that.rtcmConnection.renegotiate()
          })
        }

        function getDisplayMediaError(error) {
          // console.log('Media error: ' + JSON.stringify(error));
        }

        if (navigator.mediaDevices.getDisplayMedia) {
          navigator.mediaDevices.getDisplayMedia({video:this.videoConfig, audio:false}).then(stream => {
            onGettingSteam(stream);
          }, getDisplayMediaError).catch(getDisplayMediaError);
        } else if (navigator.getDisplayMedia) {
          navigator.getDisplayMedia({video: true}).then(stream => {
            onGettingSteam(stream);
          }, getDisplayMediaError).catch(getDisplayMediaError);
        }
      }
    },
    toggleThisUserMute(stream_id) {
      var thisStream = this.rtcmConnection.streamEvents[stream_id].stream;
      if(this.rtcmConnection.streamEvents[stream_id].mediaElement.isAudioMuted) {
        this.rtcmConnection.streamEvents[stream_id].mediaElement.isAudioMuted = false;
          thisStream.unmute('Audio');
      } else {
        this.rtcmConnection.streamEvents[stream_id].mediaElement.isAudioMuted = true;
          thisStream.mute('Audio');
      }
    },
    toggleMyVideoMute(event) {
      var localStreamID = this.rtcmConnection.attachStreams[0].streamid;
      var localStream = this.rtcmConnection.streamEvents[localStreamID].stream;
      if (this.rtcmConnection.streamEvents[localStreamID].mediaElement.isVideoMuted) {
        this.rtcmConnection.streamEvents[localStreamID].mediaElement.isVideoMuted = false
        this.isCam = true
        localStream.unmute('video')
        this.myVideoMute = this.$root.currentLang.videoChat.myVideoMute.off
      } else {
        this.rtcmConnection.streamEvents[localStreamID].mediaElement.isVideoMuted = true
        this.isCam = false
        localStream.mute('video')
        this.myVideoMute = this.$root.currentLang.videoChat.myVideoMute.on
      }
    },
    toggleMyAudioMute(event) {
      var localStreamID = this.rtcmConnection.attachStreams[0].streamid;
      var localStream = this.rtcmConnection.streamEvents[localStreamID].stream;
      if (this.rtcmConnection.streamEvents[localStreamID].mediaElement.isAudioMuted) {
        this.rtcmConnection.streamEvents[localStreamID].mediaElement.isAudioMuted = false;
        this.isMic = true
        localStream.unmute('audio');
        this.myAudioMute = this.$root.currentLang.videoChat.myAudioMute.off
      } else {
        this.rtcmConnection.streamEvents[localStreamID].mediaElement.isAudioMuted = true;
        this.isMic = false
        localStream.mute('audio');
        this.myAudioMute = this.$root.currentLang.videoChat.myAudioMute.on
      }
    },
    hideChat(event){
      this.isChat = !this.isChat;
      this.$store.commit('setShowBool', !this.$store.getters.getShowBool);
      if(!this.$store.getters.getShowBool){
        setTimeout(() => {
          this.$store.commit('setChangeCss', !this.$store.getters.getChangeCss);
        }, 700);
      }else{
        this.$store.commit('setChangeCss', !this.$store.getters.getChangeCss);
      }
    },changeLayout(name,index){
      this.defaultLayout = name;
      this.items.splice(index,1);
      this.items.push({"kind":name});

//      this.setStream()
    },
    // 비디오 플레이어 커스텀 볼륨 조절
    changeVolume(stream_id, vol) {
      let video = document.getElementById(stream_id)
      if(vol == 0) {
        video.muted = true
      } else {
        video.muted = false
      }
      video.volume = vol / 100
    },
    // 비디오 플레이어 커스텀 음소거
    muteVolume(item) {
      let video = document.getElementById(item.user.streamid)
      if(!video.muted) {
        this.$set(item, 'volume', 0)
        video.muted = true
        event.target.classList.replace('im-volume', 'im-volume-off')
      } else {
        this.$set(item, 'volume', 100)
        video.muted = false
        event.target.classList.replace('im-volume-off', 'im-volume')
      }
    },
    showControlPan() {
      event.currentTarget.getElementsByClassName('eachcontrol')[0].style.opacity = 0.7
    },
    unshowControlPan() {
      event.currentTarget.getElementsByClassName('eachcontrol')[0].style.opacity = 0
    },
    // 비디오 플레이어 커스텀 풀스크린
    toggleFullscreen(streamUser) {
      let elem = document.documentElement
      let user = document.getElementById(streamUser.streamid).parentNode

      if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement ) {
        if (elem.requestFullscreen) { /* chrome */
          user.requestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
          user.msRequestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          user.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          user.webkitRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    },
    toggleVideoMode: function () {
      this.$store.commit('setIsVideoMode', !this.isVideoMode)

      if(!this.isVideoMode) {
        this.$store.commit('setShowBool', true)
        this.$store.commit('setChangeCss', true)
      }
    },
  }
};

</script>

<style scoped>
.video-container {
  position: relative;
}
.video-container video {
  position: relative;
  z-index: 0;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}
.overlay-mute {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
}
.mute-image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: black;
  z-index: -1;
}
.ctrlr {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
}

</style>