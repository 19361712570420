import 'babel-polyfill'
import 'expose-loader?$!expose-loader?jQuery!jquery'
import '../plugins/icon-kit/dist/css/iconkit.min.css'
import '../plugins/ionicons/dist/css/ionicons.min.css'
import '../plugins/bootstrap/dist/js/bootstrap.min.js'
import Vue from 'vue'
import '@mdi/font/css/materialdesignicons.min.css'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import Vuetify from 'vuetify'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vuetify/dist/vuetify.min.css'
import axios from 'axios'
import VueSession from 'vue-session'
import AlertModal from "./plugins/AlertModal";
import './assets/font/iconmonstr/css/iconmonstr-iconic-font.min.css';
import 'animate.css'
import './assets/css/main.css'
import '../dist/css/theme.css'
import channelMixin from './mixins/channelMixin'
import commonMixin from './mixins/commonMixin'
import messageMixin from './mixins/messageMixin'
import moment from "moment";
import ko from './json/msg_ko.json';
import en from './json/msg_en.json';

Vue.mixin({
  mixins: [channelMixin, commonMixin, messageMixin]
})





Vue.use(BootstrapVue)
Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}
Vue.use(moment)
Vue.use(AlertModal)
Vue.use(Vuetify)
Vue.use(VueSession, {persist: true})
Vue.prototype.$eventBus = new Vue();
Vue.prototype.$http = axios
Vue.prototype.$moment = moment
Vue.config.productionTip = false

new Vue({
  vuetify: new Vuetify({
    icons: {
      iconfont: "mdi"
    }
  }),
  data() {
    return {
      currentLang: en,
      localeKo: ko,
      localeEn: en,
    }
  },
  created() {
    // window.showToast = (message) =>{
    //   const userAgent = navigator.userAgent.toLowerCase();
    //   alert("test")
    //   if (userAgent.indexOf('android') !== -1){
    //     return Android.showToast(message);
    //   }
    // };
    // 210429 초기 설정 한글
    let initLocale = 'ko'
    let localeCookie
    let langCookie = document.cookie.match('(^|;|.)lang=([A-z0-9\-]*?)($|;)')
    if(!!langCookie) {
      localeCookie = langCookie[2]
    } else {
      let expire = new Date()
      expire.setDate(expire.getDate() + 7)
      let makeCookie = 'lang=' + initLocale + ';path=/;expires=' + expire.toGMTString() + ';'
      document.cookie = makeCookie
      localeCookie = initLocale
    }
    this.currentLang = (localeCookie == 'ko' ? this.localeKo : this.localeEn)
  },
  methods: {
    changeLocale: function(lang) {
      let localeCookie
      if(lang) {
        this.currentLang = lang
      } else if(this.currentLang == this.localeKo) {
        this.currentLang = this.localeEn
        localeCookie = 'en'
      } else if(this.currentLang == this.localeEn) {
        this.currentLang = this.localeKo
        localeCookie = 'ko'
      }
      // setting cookie
      let expire = new Date()
      expire.setDate(expire.getDate() + 7)
      let makeCookie = 'lang=' + localeCookie + ';path=/;expires=' + expire.toGMTString() + ';'
      document.cookie = makeCookie
    }
  },
  router,
  store,
  watch: {
    channelList: function (newChannelList, oldChannelList) {
      let newChannelListCnt = newChannelList.length
      let oldChannelListCnt = oldChannelList.length
      //최초 진입 후 구독
      if (oldChannelListCnt == 0 && newChannelListCnt > 0) {
        $.each(newChannelList, function (index, channel) {
          channel.subscribe()
        })
      }
      //채널 삭제 후 구독 취소
      else if (newChannelListCnt < oldChannelListCnt) {
        let _this = this;
        $.each(oldChannelList, function (index, oldChannel) {
          let isEquals = newChannelList.find(newChannel => {
            return newChannel.id == oldChannel.id
          })
          if (isEquals === undefined) {
            oldChannel.unsubscribe()
            //_this.$store.state.stompClient.unsubscribe(oldChannel.id)
          }
        })
      }
    },
    currentChannel: function (newCurrentChannel, oldCurrentChannel) {
      if (oldCurrentChannel !== undefined) {
        if (oldCurrentChannel.id !== undefined) {
          oldCurrentChannel = this.getChannel(oldCurrentChannel)
          oldCurrentChannel.access()
        }
      }
    }
  },
  render: h => h(App)
}).$mount('#app')

//test
