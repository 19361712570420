import {mapGetters} from "vuex";

let commonMixin = {
  computed: {
    ...mapGetters({
      channelUsers: 'getChannelUsers',
      isVideoMode: 'getIsVideoMode',
      currentUser: 'getCurrentUser',
      channelList: 'getChannelList',
      msgArray: 'getMsgArray',
      currentChannel: 'getCurrentChannel',
      wrapperEl: 'getWrapperEl',
      cursorPoint: 'getCursorPoint',
      oldScrollHeight: 'getOldScrollHeight',
      selectComponent: 'getSelectComponent',
      subscribeList:'getSubscribeList',
      channelArr:'getChannelArr',
      firstLoad: 'getFirstLoad',
      message: 'getMessage',
      channelFiles: 'getChannelFiles',
      channelLinks: 'getChannelLinks',
      isGetMsgForImgLoad: 'getIsGetMsgForImgLoad',
      isGetMsgForPreview: 'getIsGetMsgForPreview',
      scrollPosition: 'getScrollPosition',
      isUpScroll: 'getIsUpScroll',
      fileCursorPoint: 'getFileCursorPoint',
    }),
    // 210429 언어에 따른 즉시 변화를 위해 캐싱 기능 있는 computed 사용 / data() 보다 늦게 이루어 지는 것 같음 data에서 this.currLng 동작 안함
    currLng: function() {
      return this.$root.currentLang
    }
  },
  methods: {
    commit: function (key, value) {
      this.$store.commit(key, value)
    },
    post: function (url, params, callback) {
      this.$http.post(url, params)
        .then(res => {
          if (callback !== undefined) {
            callback(res)
          }
        })
    },
    isAdmin: function () {
      return this.currentUser.roles.includes('ROLE_ADMIN') || this.isRoot()
    },
    isRoot: function () {
      return this.currentUser.roles.includes('ROLE_ROOT')
    },
    subscribe: function (url, func) {
      if (!this.subscribeList.includes(url)) {
        this.subscribeList.push(url)
        this.commit('setSubscribeList', this.subscribeList)

        return this.$store.state.stompClient.subscribe(url, func)
      }
      return null
    },
    unsubscribe: function (id) {
      this.$store.state.stompClient.unsubscribe(id)
    },
    send: function (url, message) {
      this.$store.state.stompClient.send(url, JSON.stringify({
        'message': message,
        'error': "null"
      }))
    },
    callComponent: function (component) {
      this.$store.commit('getSelectComponent', component)
    },
    checkOnline: function(user) {
      let result = false
      let onlineUsers = this.$store.getters.getOnlineUsers
      for(let i in onlineUsers) {
        if(onlineUsers[i] == user.email) {
          result = true
          break
        }
      }
      user.status = ''
      return result
    }
  }
};
export default commonMixin;
