<template>
  <v-container @wheel="test">
    <v-row align="center" justify="center" style="max-height: 100%" ref="row">
      <v-col cols="12">
        <v-card>
          <v-btn @click=""></v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import {mapGetters} from "vuex";

  export default {
    name: 'About',
    data() {
      return {
        value: 1,
      };
    },
    // 컴포넌트 생성 되기 전
    beforeCreate() {
    },
    // 컴포넌트 생성 후
    created() {
    },
    // 컴포넌트가 생성 되서 페이지에 붙여지기 전
    beforeMount() {
    },
    // 컴포넌트가 생성 되서 페이지에 붙여지기 후
    mounted() {
    },
    // 컴포넌트의 데이터가 업데이트 되기 전
    beforeUpdate() {
    },
    // 컴포넌트의 데이터가 업데이트 되기 후
    updated() {
    },
    // 해당 컴포넌트가 페이지에서 삭제되거나 페이지 이동 하기 전
    beforeDestroy() {
    },
    // 해당 컴포넌트가 페이지에서 삭제되거나 페이지 이동 하기 후
    destroyed() {
    },

    watch:{
      //
    },
    computed: {
      ...mapGetters({

      })
    },


    methods: {
      test:function (e){
        return null
      },
    },
  }

</script>
<style scoped>

</style>
