import AboutChannel from './aboutchannel'
import store from '../store'

class EventListener {
  beforeunloadEvt() {
    store.commit('setIsLogout', false)
    //로그아웃함수가 실행되어 페이지이동시에 발생하지 않도록 조건문 처리
    window.addEventListener('beforeunload', function (event) {
      if (store.state.selectComponent === 'main' && store.state.isfocus) {
        AboutChannel.updateLastAccessDate(store.state.currentChannel.id)
      }
    })
  }

  focusEvt(instance) {
    window.addEventListener('focus', async function () {
      if (!store.state.isLogout) {
        await store.dispatch('setFocus', true)
        if (store.state.currentChannel != null && store.state.selectComponent == 'main') {
          instance.currentChannel.access()
        }
        // move position for fix alarm bug
        instance.msgCountUpdate(store.state.currentChannel.id, false)
        
        // 210518 not focus, revert favicon + title(tab alarm)
        document.getElementById('favicon').href = '/favicon.ico'
        document.title = 'HAMAA'
        store.commit('setAlarmCount', 0)
      }
    })
  }

  blurEvt() {
    window.addEventListener('blur', function () {
      if (!store.state.isLogout) {
        store.commit('setFocus', false)
      }
    })
  }

  resizeEvt() {
    window.addEventListener('resize', function () {
      store.commit('setSmallWidth', (window.innerWidth < 600) ? true : false)
    })
  }

}

export default new EventListener()
