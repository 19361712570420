<template>
    <draggable v-model="videoList" style="display: flex; flex-wrap: wrap; justify-content: center; z-index: 0;">
        <v-col :cols="getVideoCols" v-for="(item, index) in videoList" :key="item.id"
                style="margin-top: 0px; padding: 5px; width: 15vw;">
            <v-card class="d-inline" flat tile>
            <v-card
                v-bind:video="item"
                v-bind:key="item.id"
                class="video-item">
                <div class="video-container" @mouseover="showControlPan" @mouseout="unshowControlPan" style=" height:20vh;" item item.id>
                <video autoplay playsinline ref="videos" :muted="item.muted" :id="item.id" style="width:100%; height: 100%;" @contextmenu.prevent></video>
                
                <div class="mute-image" :id="item.id + '-mute-image'">
                    <i class="im im-eye-off" style="font-size: 10rem; display: flex; justify-content: center; align-items: center; height: 100%; color: rgba(255,255,255,0.2);"></i>
                </div>
                <div class="overlay">
                    <p style="border-radius: 10px; background-color: lightgray; margin: 0.5rem; padding: 0.5rem; font-weight: bold; opacity: 55%;">{{ item.user.extra.username }}</p>
                    <p hidden>{{ item.user }}</p>
                </div>
                <div v-show="item.user.extra.isAudioMuted && !item.user.stream.isScreen" class="overlay-mute">
                    <p style="border-radius: 10px; background-color: red; margin: 0.5rem; padding: 0.5rem; opacity: 55%;"><img src="../../assets/images/mic-off.png" style="width:2rem" /></p>
                </div>
                <div class="ctrlr">
                    <div class="eachcontrol" style="border-radius: 10px;background-color: lightcoral;margin: 0.5rem;padding: 0.5rem;font-weight: bold; opacity: 0%; height: 3rem;">
                    <v-icon style="float: left; margin: 0.25rem;" @click="toggleFullscreen(item.user)">im im-fullscreen</v-icon>
                    <v-icon v-show="!item.muted && !item.user.extra.isAudioMuted" style="float: left; margin: 0.25rem;" @click="muteVolume(item)">im im-volume</v-icon>
                    <v-slider v-show="!item.muted && !item.user.extra.isAudioMuted" raising style="float:left; width:7rem;" :value="item.volume != undefined ? item.volume : 100" min="0" max="100" @change="changeVolume(item.user.streamid, $event)"></v-slider>
                    </div>
                </div>
                </div>
                    <!-- <div>{{item}}</div> -->
                    <!-- <div>{{item.user.mediaElement.isVideoMuted}}</div> -->
                    
            </v-card>
            </v-card>
        </v-col>
    </draggable>
</template>

<script>
import RTCMultiConnection from 'rtcmulticonnection';
import draggable from "vuedraggable";

require('adapterjs');
export default {
  name: "DefaultLayout", 
  components: {
    RTCMultiConnection,
    draggable,
    

  },data() {
    return {
      
     };  
  },
  watch: {
    
  },
  props:{
    videoList:{
      type: Array,
        default(){
          return [];
        },
    },
    rtcmConnection:{
      type: Object,
      
    },
  },
  computed: {
    getVideoCols: function () {
      switch (this.videoList.length) {
        case 1:
          return 10;
        case 2:
          return 6;
        case 3:
        case 4:
          return 5;
        case 5:
        case 6:
          return 4;
        default:
          return 3;
      }
    }
  },
  destroyed(){
  },
  mounted() {
    //this.$parent.setStream();
    let videos = this.$refs.videos;
    let videoLists = this.videoList;
    for (var i = 0, len = videoLists.length; i < len; i++) {
      console.log(i+" len ::: ", videoLists[i].user.stream.isScreen);
      if (videos[i].id === videoLists[i].id) {
        videos[i].srcObject = videoLists[i].user.stream;
        //mute 여부
        if(this.rtcmConnection.streamEvents[videoLists[i].id].extra.isVideoMuted && !videoLists[i].user.stream.isScreen) {
          document.getElementById(videoLists[i].id + '-mute-image').style.zIndex = 2
        } else {
          document.getElementById(videoLists[i].id + '-mute-image').style.zIndex = -1
        }
      }
    }
      
    
  },
  methods: {
    // 비디오 플레이어 커스텀 볼륨 조절
    changeVolume(stream_id, vol) {
      let video = document.getElementById(stream_id)
      if(vol == 0) {
        video.muted = true
      } else {
        video.muted = false
      }
      video.volume = vol / 100
    },
    // 비디오 플레이어 커스텀 음소거
    muteVolume(item) {
      let video = document.getElementById(item.user.streamid)
      if(!video.muted) {
        this.$set(item, 'volume', 0)
        video.muted = true
        event.target.classList.replace('im-volume', 'im-volume-off')
      } else {
        this.$set(item, 'volume', 100)
        video.muted = false
        event.target.classList.replace('im-volume-off', 'im-volume')
      }
    },
    showControlPan() {
      event.currentTarget.getElementsByClassName('eachcontrol')[0].style.opacity = 0.7
    },
    unshowControlPan() {
      event.currentTarget.getElementsByClassName('eachcontrol')[0].style.opacity = 0
    },
    // 비디오 플레이어 커스텀 풀스크린
    toggleFullscreen(streamUser) {
      let elem = document.documentElement
      let user = document.getElementById(streamUser.streamid).parentNode

      if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement ) {
        if (elem.requestFullscreen) { /* chrome */
          user.requestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
          user.msRequestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          user.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          user.webkitRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    },
  }
};
</script>

<style scoped>
.video-container {
  position: relative;
}
.video-container video {
  position: relative;
  z-index: 0;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}
.overlay-mute {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
}
.mute-image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: black;
  z-index: -1;
}
.ctrlr {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
}

</style>