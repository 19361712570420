<template>
  <div>
    <v-card v-bind:class="{moHeight:  $store.state.isSmallWidth }">
      <v-card-title>{{ currLng.admin.txt.auth.title }}</v-card-title>
      <v-data-table
        :headers="headers"
        :items="userList"
        :items-per-page="10"
        :calculate-width="true"
        class="elevation-1">
        <template #[`item.status`]="{ item }">
          <i class="im" :class="checkOnline(item) ? 'im-smiley-o online' : 'im-frown-o offline'"></i>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>

  export default {
    name: "AllMemberList",
    beforeCreate() {
      this.$http.get('/api/user/list')
        .then(res => {
          this.userList = res.data
          this.userList.forEach((user, index) => {
            user.number = index + 1
            user.status = ''
          });
        })
    },
    data() {
      return {
        userList: [],
        headers: [
          { text: this.$root.currentLang.admin.txt.auth.col.num, align: "start", sortable: false, value: "number" },
          { text: this.$root.currentLang.admin.txt.auth.col.name, value: 'name' },
          { text: this.$root.currentLang.admin.txt.auth.col.email, value: 'email', sortable: false },
          { text: this.$root.currentLang.admin.txt.auth.col.status, value: 'status' },
        ],
      }
    },
    computed: {
    },
    methods: {
    }
  }
</script>
<style scoped>
.online {
  color: #ed143d;
}
.offline {
  color: #a9a9a9;
}
</style>